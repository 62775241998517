/* eslint-disable import/extensions */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import RefreshIcon from '../../assets/images/refresh.png';
import LogoutIcon from '../../assets/nav-icons/LogoutIcon.svg';
import expired from '../../assets/pngs/expired.png';
import MyBasket from '../../assets/pngs/mybasket_logo.png';
import listEmptyTicketImage from '../../assets/svgs/archive.svg';
import MailIcon from '../../assets/svgs/mailbox.svg';
import { Accordion, Button, Loader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { LoginForm } from '../../forms';
import './ExternalTicketsPage.css';
import { casesDashboardService } from '../../services';
import { getCookie } from '../../utils/tools';

const ExternalTicketsPage = ({ location }) => {
  const data = location.hash.split('/');
  const areMyEvents = data[0] === '#events';
  const history = useHistory();
  const [filter, setFilter] = useState(0);
  const filterOptions2 = [{ name: 'Refresh inbox', value: 1 }];
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accountInfo, logOut, showSnackbar, setMenuCollapse } = useContext(AccountContext);
  if (areMyEvents) {
    setMenuCollapse(false);
    window.top.postMessage({ isEvent: true }, '*');
  }
  const {
    cases,
    setCases,
    assigned,
    unassigned,
    selectedTicket,
    setSelectedTicket,
    handleFilterCase,
    handleCaseById,
    groupsRetrieved,
    casesByUser,
    magic,
    isExpired,
    teamTicket,
  } = useContext(TicketsContext);
  const { userData } = accountInfo || {};
  const { role } = userData || [];

  const defaultCaseState = {
    cases: [],
    assigned: [],
    unassigned: [],
    selected: {},
    loading: true,
  };

  const validAdminUser = role === 'ADMIN';

  const [caseFilter, setCaseFilter] = useState('');
  const [disableClear, setDisableClear] = useState(false);
  const [filteredCases, setFilteredCases] = useState([]);

  const HeaderForm = ({ title }) => {
    return <div className="activity-header">{title}</div>;
  };

  const getCases = async (id) => {
    const { success, data: casesInfo } = await casesDashboardService.getCase(id);
    const { state, status } = casesInfo;
    if (success && state === 'Denegada' && status === 'COMPLETED') {
      showSnackbar('error', '', 'The form has been denied!', 6000);
      logOut();
      setSelectedTicket(null);
      history?.push({ pathname: '/', goBack: true });
    }
    return success && state === 'Denegada' && status === 'COMPLETED';
  };

  useEffect(async () => {
    if (selectedTicket && selectedTicket.status === 'assigned') {
      const tmep = await getCases(selectedTicket.operation);
      if (!tmep) {
        const result = handleCaseById(magic);
        if (result) {
          const lenCases = cases?.cases?.filter((c) => c.status === 'assigned').length;
          lenCases === 1 && history.push(`/tickets/${selectedTicket.id}`);
        } else {
          // logOut();
          // setSelectedTicket(null);
          // history?.push({ pathname: '/', goBack: true });
        }
      }
    }
  }, [selectedTicket]);

  const handleDistribution2 = [
    {
      id: 1,
      type: 'ticket',
      title: 'Assigned to me',
      titleMobile: 'Assigned to me',
      amount: assigned?.length || 0,
      content: assigned,
      legend: 'On progress!',
    },
  ];

  const handleDistribution = [
    {
      id: 1,
      type: 'ticket',
      title: 'Assigned to me',
      titleMobile: 'Assigned to me',
      amount: assigned?.length || 0,
      content: assigned,
      legend: 'On progress!',
    },
    {
      id: 2,
      type: 'ticket',
      title: 'Pending',
      titleMobile: 'Pending',
      amount: unassigned?.length || 0,
      content: unassigned,
      legend: 'Waiting for you!',
    },
  ];

  const handleFilterDistribution = [
    {
      id: 1,
      title: 'Results',
      titleMobile: 'Results',
      type: 'ticket',
      amount: filteredCases?.length || 0,
      content: filteredCases,
      legend: 'Filter results!',
    },
  ];
  const handleReset = () => {
    setSelectedCasesGroups([]);
    setCaseFilter('');
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchTicket-input');
    input?.focus();
  };

  const apiResponse = async () => {
    const response = await casesByUser();
    setIsLoading(!response);
    setTimeout(() => {
      handleFocusInput();
    }, 2500);
  };

  useEffect(() => {
    apiResponse();
    return () => {
      setCases(defaultCaseState);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      selectedTicket && casesByUser();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedTicket]);

  useEffect(async () => {
    setSelectedTicket(null);
    if (caseFilter !== '' || groupsRetrieved?.length > 0) {
      setFilteredCases(await handleFilterCase(caseFilter, selectedCasesGroups));
    } else {
      setFilteredCases([]);
    }
    setTimeout(() => {
      if (selectedCasesGroups?.length || caseFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [caseFilter, selectedCasesGroups]);

  const show =
    !teamTicket?.toLowerCase().includes('mybasket') ||
    !teamTicket?.toLowerCase().includes('my-basket');

  const HIListing = () => (
    <div className="HI-listing">
      <div className="search-container">
        <div className="HI-listing-container">
          <Accordion
            items={
              caseFilter !== '' || selectedCasesGroups?.length > 0
                ? handleFilterDistribution
                : !show
                ? handleDistribution
                : handleDistribution2
            }
          />
        </div>
      </div>
    </div>
  );

  const handleShowSupport = () => {
    const ticketForm = <LoginForm onSubmit={<></>} />;
    showModal(ticketForm, <HeaderForm title="Enter your credentials" iconName="pi-user" />);
  };

  const handleAction = () => {
    if (!getCookie('credentials')) {
      // handleShowSupport();
    } else {
      logOut();
      setSelectedTicket(null);
      history?.push({ pathname: '/', goBack: true });
    }
  };

  const ListEmptyPersonalState = () => {
    return (
      <div className="list-empty-wrapper">
        <div className="detail-container bounce">
          <img className="detail-img" src={MailIcon} alt="Search a ticket" />
          <div className="detail-wrapper">
            <p className="detail-message bounce">There are no more tickets in your inbox.</p>
            <div className="detail-actions">
              {validAdminUser ? (
                <Button
                  title="Refresh tickets"
                  className="loggin-btn2"
                  onClick={() => {
                    showSnackbar('success', '', 'Ticket refreshed!', 3000);
                    casesByUser();
                    handleReset();
                  }}
                >
                  <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
                  <p>Refresh inbox</p>
                </Button>
              ) : null}
              {/* <Button onClick={handleAction} className="loggin-btn2">
                <img src={LogoutIcon} className="nav-icon-out" alt="Bye" />
                <p>Logout</p>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ExpiredTicket = () => {
    return (
      <>
        <img className="expired-img" src={expired} alt="Ticket Expired" />
        <h1 className="expired-header">
          Lo siento, parece que el formulario
          <strong> ha expirado o ha sido enviado previamente.</strong>
        </h1>
      </>
    );
  };

  // const push = async () => {
  //   const result2 = await batchesService.updateBatchLine('a24758842d', '65dfe75edcc88072828eb9b2', {
  //     state: 'Revisión de aprobación',
  //     caseId: '5b1efd971b',
  //     status: 'Aprobación',
  //     retrived_formulario1_typeIdUser: 'Persona Física Nacional',
  //     retrived_formulario1_docUser: '01-1657-0091',
  //     retrived_formulario1_telUser1: '3123-1231',
  //     retrived_formulario1_telUser2: '2131-2312',
  //     retrived_formulario1_montoUser: '₡ 312312312',
  //     retrived_formulario1_emailUser: 'sebastian.salas+t09@houndsoftware.net',
  //     retrived_formulario1_currencyType: 'Colones',
  //     retrived_formulario1_provinciaUser: 'San José',
  //     retrived_formulario1_cantonUser: 'Escazú',
  //     retrived_formulario1_distritoUser: 'San Antonio',
  //     retrived_formulario1_dirUser: '3123123',
  //     retrived_formulario1_profesionUser: '3',
  //     retrived_formulario1_segmentoUser: 'Asalariado Privado',
  //   });
  //   console.log('result2', result2);
  // };

  const HeaderMyBasket = () => {
    return (
      <header className="header-cls">
        <div className="header-container">
          <img src={MyBasket} className="logo-img" alt="Bye" />
          {/* <Button title="Logout" onClick={handleAction} className="loggin-btn">
            <img src={LogoutIcon} className="nav-icon-out" alt="Bye" />
          </Button> */}
          {/* <Button title="Sebas" onClick={push} className="loggin-btn">
            Push update
          </Button> */}
        </div>
      </header>
    );
  };

  if (isLoading) {
    return (
      <div className="loader-wrapper">
        <Loader fit />
      </div>
    );
  }

  return (
    <div className="HI-wrapper">
      {cases?.length ? (
        <>
          {isExpired ? (
            <div className="HI-container">
              <HeaderMyBasket />
              <HIListing />
            </div>
          ) : (
            <div className="HI-expired">
              <ExpiredTicket />
            </div>
          )}
        </>
      ) : (
        <ListEmptyPersonalState />
      )}
    </div>
  );
};

export default ExternalTicketsPage;
