const DEFAULT_STATUS = [
  { name: 'CREATED', code: 1 },
  { name: 'STARTED', code: 2 },
  { name: 'PROGRESS', code: 3 },
  { name: 'COMPLETED', code: 4 },
];

const DEFAULT_CATEGORIES = [
  { name: 'AD', code: 1 },
  { name: 'A', code: 2 },
  { name: 'B', code: 3 },
  { name: 'C', code: 4 },
  { name: 'D', code: 5 },
];

const DEFAULT_FILTERS = {
  'operation.id': { value: null, matchMode: 'contains' },
  status: { value: null, matchMode: 'in' },
};

export { DEFAULT_STATUS, DEFAULT_CATEGORIES, DEFAULT_FILTERS };
