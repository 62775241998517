import React from 'react';

import PropTypes from 'prop-types';

import './CounterDisplay.css';

const DASH = '-';

const CounterDisplay = ({ tag = DASH, value = DASH }) => {
  return (
    <div className="counter-display-wrapper">
      <p className="counter-display-value">{value || DASH}</p>
      <h3 className="counter-display-tag">{tag?.toUpperCase()}</h3>
    </div>
  );
};

CounterDisplay.propTypes = {
  tag: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CounterDisplay;
