/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { GoBackComponent } from '../../components';
import { EditAppForm } from '../../forms';
import './EditAppPage.css';

const EditApp = () => {
  return (
    <div className="edit-app-wrapper">
      <GoBackComponent />
      <div className="edit-app-container">
        <EditAppForm />
      </div>
    </div>
  );
};

export default EditApp;
