/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import ApexCharts from 'apexcharts';
import Moment from 'moment';

import listEmptyDashboardImage from '../../assets/svgs/arts/undraw_dashboard.svg';
import { Loader, Button } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import useWindowSize from '../../hooks/useWindowSize';
import { casesDashboardService } from '../../services';
import DashboardFilters from './Filters/DashboardFilters';
import PinList from './Filters/PinList/PinList';
import CaseActivity from './Sections/CaseActivity/CaseActivity';
import Details from './Sections/Details/Details';
import LineCharts from './Sections/LineChats';
import Treemap from './Sections/Treemap';
import './MainDashboard.css';

const MainDashboard = ({ location }) => {
  const [firstAttemp, setFirstAttemp] = useState(0);
  const info = useWindowSize();
  const [selectedType, setSelectedType] = useState(0);
  const [rangeDates, setRangeDates] = useState([]);
  const [typesInfo, setTypesInfo] = useState([]);
  const [caseSelectedInfo, setCaseSelectedInfo] = useState(null);
  const [caseSelectedId, setCaseSelectedId] = useState('ALL');
  const [loading, setLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [casesStates, setCasesStates] = useState([]);
  const [pins, setPins] = useState([]);
  const { menuCollapse, showSystemEmptyStates, showSnackbar, handleTask } = useContext(
    AccountContext
  );
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const [filter, setFilter] = useState(3);
  const [disable, setDisable] = useState(false);
  const [logs, setLogs] = useState([]);
  const [instances, setInstances] = useState([]);
  const [caseStatus, setCaseStatus] = useState(null);
  const [caseCategory, setCaseCategory] = useState(null);
  const [caseOperationId, setCaseOperationId] = useState(null);

  const [createdApiData, setCreatedApiData] = useState(null);
  const [activeApiData, setActiveApiData] = useState(null);
  const [closedApiData, setClosedApiData] = useState(null);
  const [resolutionApiData, setResolutionApiData] = useState(null);
  const [isLoadingChartCreated, setIsLoadingChartCreated] = useState(true);
  const [isLoadingChartActive, setIsLoadingChartActive] = useState(true);
  const [isLoadingChartClosed, setIsLoadingChartClosed] = useState(true);
  const [isLoadingChartResolution, setIsLoadingChartResolution] = useState(true);
  const [secondLoad, setSecondLoad] = useState(true);

  const getCases = async () => {
    const { success, data: CasesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      setTypesInfo(CasesInfo);
    }
  };

  const getCase = async () => {
    const typeSelected = selectedType === 0 ? caseSelectedId : typesInfo[selectedType - 1].id;
    const { success, data: caseData } = await casesDashboardService.getCaseType(typeSelected);
    if (success) {
      setCaseSelectedInfo(caseData);
      return caseData;
    }
    showSnackbar(
      'error',
      'Error!',
      'Something went wrong retrieving the case! Please try again later'
    );
  };

  const getCasesPinList = () => {
    const list = typesInfo?.map((d) => {
      return { id: d.id };
    });
    setPins([{ id: 'ALL' }, ...list]);
  };

  const DashBoardEmptyState = () => {
    return (
      <div className="table-modeler-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyDashboardImage} alt="No data available!" />
          <p className="detail-message">No data available!</p>
        </div>
      </div>
    );
  };

  const setFullCases = (index) => {
    return [{ x: typesInfo[index].id, y: typesInfo[index].activeCases || -1 }];
  };

  const buildFullCase = () => {
    const case2 = typesInfo.map((c, i) => ({
      name: c?.id,
      data: setFullCases(i),
    }));
    return case2;
  };

  const buildACase = () => {
    const infos = casesStates?.map((s) => {
      return {
        x: s.id,
        y: s.activeCases || -1,
      };
    });
    return [{ data: infos }];
  };

  const handleBuildTreemap = () => {
    if (selectedType > 0) {
      const builderACase = buildACase();
      return builderACase;
    }
    const builderFullCase = buildFullCase();
    return builderFullCase;
  };

  const assignColorCase = () => {
    if (selectedType > 0) {
      return [['#0098ff', '#5c5c5c', '#725ca4', '#8ac7bb', '#5ace39', '#b1d3d4'][selectedType - 1]];
    }
    return ['#0098ff', '#5c5c5c', '#725ca4', '#8ac7bb', '#5ace39', '#b1d3d4'];
  };

  const buildTreeMapCases = async () => {
    const options = {
      series: handleBuildTreemap(),
      legend: {
        show: false,
      },
      chart: {
        type: 'treemap',
        height: '100%',
        width: '100%',
        events: {
          click: async (event, chartContext, config) => {
            if (selectedType === 0) {
              setSelectedType(config.seriesIndex + 1);
            }
          },
        },
      },
      colors: assignColorCase(),
    };
    return options;
  };

  const fetchChartsStats = async () => {
    const typeSelected = selectedType === 0 ? caseSelectedId : typesInfo[selectedType - 1].id;
    const { success, data: listData } = await casesDashboardService.listCasesStates(typeSelected);
    if (success) {
      setCasesStates(listData);
    } else {
      setSelectedType(0);
      showSnackbar(
        'error',
        'Error!',
        'Something went wrong retrieving the stages! Going back to the overall.'
      );
    }
  };

  const getMinDate = () => {
    switch (filter) {
      case 1: // Today
        return Moment().format('YYYY-MM-DD');
      case 2: // This week
        return Moment().subtract(7, 'days').format('YYYY-MM-DD');
      case 3: // This month
        return Moment().subtract(30, 'days').format('YYYY-MM-DD');
      case 4: // Last 90 days
        return Moment().subtract(90, 'days').format('YYYY-MM-DD');
      default:
        // Range
        return rangeDates[0] || '';
    }
  };

  const getMaxDate = () => {
    switch (filter) {
      case 1: // Today
        return Moment().format('YYYY-MM-DD');
      case 2: // This week
        return Moment().format('YYYY-MM-DD');
      case 3: // This month
        return Moment().format('YYYY-MM-DD');
      case 4: // Last 90 days
        return Moment().format('YYYY-MM-DD');
      default:
        // Range
        return rangeDates[1] || '';
    }
  };

  const fetchCases = async () => {
    const start = getMinDate();
    const end = getMaxDate();
    const { success, data: casesResult } = await casesDashboardService.listCases(
      start,
      end,
      caseStatus,
      caseCategory,
      caseOperationId,
      caseSelectedId
    );
    if (success && casesResult.length > 0) {
      setInstances(casesResult);
    }
  };

  const fetchLogs = async () => {
    const { success, data: casesResult } = await casesDashboardService.calculateStatusLogsTime(
      caseSelectedId
    );
    if (success && casesResult.length > 0) {
      setLogs(casesResult);
    }
  };

  const renderData = async () => {
    const dataTreeChart = await buildTreeMapCases();
    if (dataTreeChart) {
      const chart = new ApexCharts(document?.querySelector('#chartCasesTypes'), dataTreeChart);
      if (chart) {
        chart ? chart.render() : null;
      }
    }
  };

  const resolutionChartFetch = async (activeTab = '') => {
    setIsLoadingChartResolution(true);
    const {
      success: isResolutionApiToday,
      data: resolutionTodayResponse,
    } = await casesDashboardService.listResolutionTimes('TODAY', caseSelectedId || activeTab);
    const {
      success: isResolutionApiMonth,
      data: resolutionMonthResponse,
    } = await casesDashboardService.listResolutionTimes('MONTH', caseSelectedId || activeTab);

    if (isResolutionApiMonth && isResolutionApiToday) {
      const resolutionWeekResponse = {
        data: resolutionMonthResponse.data.slice(-7),
        labels: resolutionMonthResponse.labels.slice(-7),
      };

      const resolutionQuaterResponse = {
        data: [
          ...resolutionMonthResponse.data,
          ...resolutionMonthResponse.data,
          ...resolutionMonthResponse.data,
        ],
        labels: [
          ...resolutionMonthResponse.labels,
          ...resolutionMonthResponse.labels,
          ...resolutionMonthResponse.labels,
        ],
      };

      setResolutionApiData([
        resolutionTodayResponse, // Daily
        resolutionWeekResponse, // Week
        resolutionMonthResponse, // Month
        resolutionQuaterResponse, // Quater
        null, // Search range
      ]);
      setIsLoadingChartResolution(false);
      setFirstAttemp((p) => p + 1);
    }
  };

  const createChartFetch = async (activeTab = '') => {
    setIsLoadingChartCreated(true);
    const {
      success: isCreatedApiToday,
      data: createdTodayResponse,
    } = await casesDashboardService.listCasesStats('CREATED', 'TODAY', caseSelectedId || activeTab);
    const {
      success: isCreatedApiMonth,
      data: createdMonthResponse,
    } = await casesDashboardService.listCasesStats('CREATED', 'MONTH', caseSelectedId || activeTab);
    setTimeout(() => {
      resolutionChartFetch(activeTab);
    }, 500);
    if (isCreatedApiToday && isCreatedApiMonth) {
      const createdWeekResponse = {
        data: createdMonthResponse.data.slice(-7),
        labels: createdMonthResponse.labels.slice(-7),
      };
      const createdQuaterResponse = {
        data: [
          ...createdMonthResponse.data,
          ...createdMonthResponse.data,
          ...createdMonthResponse.data,
        ],
        labels: [
          ...createdMonthResponse.labels,
          ...createdMonthResponse.labels,
          ...createdMonthResponse.labels,
        ],
      };
      setCreatedApiData([
        createdTodayResponse, // Daily
        createdWeekResponse, // Week
        createdMonthResponse, // Month
        createdQuaterResponse, // Quater
        null, // Search range
      ]);
      setIsLoadingChartCreated(false);
    }
  };

  const closedChartFetch = async (activeTab = '') => {
    setIsLoadingChartClosed(true);
    const {
      success: isClosedApiToday,
      data: closedTodayResponse,
    } = await casesDashboardService.listCasesStats('CLOSED', 'TODAY', caseSelectedId || activeTab);
    // const { success: isClosedApiToday, data: closedTodayResponse } = closedTodayResponseT;
    const {
      success: isClosedApiMonth,
      data: closedMonthResponse,
    } = await casesDashboardService.listCasesStats('CLOSED', 'MONTH', caseSelectedId || activeTab);
    // const { success: isClosedApiMonth, data: closedMonthResponse } = closedMonthResponseT;
    setTimeout(() => {
      createChartFetch(activeTab);
    }, 500);
    if (isClosedApiToday && isClosedApiMonth) {
      const closedWeekResponse = {
        data: closedMonthResponse.data.slice(-7),
        labels: closedMonthResponse.labels.slice(-7),
      };
      const closedQuaterResponse = {
        data: [
          ...closedMonthResponse.data,
          ...closedMonthResponse.data,
          ...closedMonthResponse.data,
        ],
        labels: [
          ...closedMonthResponse.labels,
          ...closedMonthResponse.labels,
          ...closedMonthResponse.labels,
        ],
      };
      setClosedApiData([
        closedTodayResponse, // Daily
        closedWeekResponse, // Week
        closedMonthResponse, // Month
        closedQuaterResponse, // Quater
        null, // Search range
      ]);
      setIsLoadingChartClosed(false);
    }
  };

  const activeChartFetch = async (activeTab = '') => {
    setIsLoadingChartActive(true);
    const {
      success: isActiveApiToday,
      data: activeTodayResponse,
    } = await casesDashboardService.listCasesStats('ACTIVE', 'TODAY', caseSelectedId || activeTab);
    // const { success: isActiveApiToday, data: activeTodayResponse } = activeTodayResponseT;
    const {
      success: isActiveApiMonth,
      data: activeMonthResponse,
    } = await casesDashboardService.listCasesStats('ACTIVE', 'MONTH', caseSelectedId || activeTab);
    setTimeout(() => {
      closedChartFetch(activeTab);
    }, 500);
    // const { success: isActiveApiMonth, data: activeMonthResponse } = activeMonthResponseT;
    if (isActiveApiToday && isActiveApiMonth) {
      const activeWeekResponse = {
        data: activeMonthResponse.data.slice(-7),
        labels: activeMonthResponse.labels.slice(-7),
      };
      const activeQuaterResponse = {
        data: [
          ...activeMonthResponse.data,
          ...activeMonthResponse.data,
          ...activeMonthResponse.data,
        ],
        labels: [
          ...activeMonthResponse.labels,
          ...activeMonthResponse.labels,
          ...activeMonthResponse.labels,
        ],
      };
      setActiveApiData([
        activeTodayResponse, // Daily
        activeWeekResponse, // Week
        activeMonthResponse, // Month
        activeQuaterResponse, // Quater
        null, // Search range
      ]);
      setIsLoadingChartActive(false);
    }
  };

  useEffect(async () => {
    if (typesInfo.length > 0) {
      setLoading(true);
      const cases = typesInfo.map((c) => c.id);
      const fullCases = ['ALL', ...cases];
      const activeParams = location.hash;
      const hashCase = activeParams.slice(1, activeParams.length);
      const activeTab = selectedType === 0 ? 'ALL' : typesInfo[selectedType - 1].id;
      if (!fullCases.some((c) => c === hashCase)) {
        window.location.hash = 'ALL';
        setSelectedType(0); // ALL cases
      } else {
        window.location.hash = activeTab;
      }
      setCaseSelectedId(pins[selectedType]?.id);
      fetchChartsStats();
      getCase();
      getCasesPinList();
      if (firstAttemp === 1 && secondLoad) {
        setIsLoadingChartCreated(true);
        setIsLoadingChartActive(true);
        setIsLoadingChartClosed(true);
        setIsLoadingChartResolution(true);
        secondLoad && activeChartFetch(activeTab);
        setSecondLoad(false);
        fetchCases();
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [typesInfo, selectedType]);

  useEffect(() => {
    try {
      if (casesStates.length > 0) {
        document?.getElementById('globalInfoContainer')?.scrollTo(0, 0);
        setTimeout(() => {
          setLoading(false);
          renderData();
        }, 1600);
      }
    } catch (error) {
      console.log('TODO: fix - render destruction when unmount the component');
    }
  }, [casesStates]);

  useEffect(() => {
    getCases();
    activeChartFetch();
    handleTask();
  }, []);

  const handleHistory = () => {
    document.getElementById('globalInfoContainer')?.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setShowHistory((pre) => !pre);
    }, 800);
    setDisable(true);
  };

  useEffect(() => {
    if (disable) {
      setTimeout(() => {
        setDisable(false);
      }, 1000);
    }
  }, [disable]);

  useEffect(() => {
    if (showHistory) {
      document.getElementById('activity')?.scrollIntoView({ behavior: 'smooth' }, true);
    } else {
      document.getElementById('globalInfoContainer')?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [showHistory]);

  useEffect(() => {
    if (caseSelectedId && caseSelectedId !== 'ALL') {
      fetchLogs();
      fetchCases();
      setFirstAttemp(1);
      setSecondLoad(true);
    }
  }, [caseSelectedId]);

  useEffect(() => {
    return () => {
      document?.querySelector('#chartCasesTypes')?.remove();
    };
  }, []);

  return !loading ? (
    !showSystemEmptyStates ? (
      <div className="main-dashboard-wrapper">
        <div className="main-dashboard-container">
          <div className="info-space">
            <PinList activePinId={selectedType} setActivePinId={setSelectedType} pinList={pins} />
            <div
              id="globalInfoContainer"
              className="info-space-B"
              style={{
                overflow: !showHistory ? '' : 'hidden',
                width: isMobile
                  ? isTablet
                    ? menuCollapse
                      ? `${info.width - 120}px`
                      : `${info.width - 40}px`
                    : menuCollapse
                    ? `${info.width - 250}px`
                    : `${info.width - 140}px`
                  : menuCollapse
                  ? `${info.width - 150}px`
                  : `${info.width - 50}px`,
              }}
            >
              <div
                className="box-up"
                style={{
                  height: !isMobile && !isTablet && `${info.height * 0.55}px`,
                }}
              >
                <div className="box box1">
                  <Details
                    typesInfo={typesInfo}
                    caseSelectedInfo={caseSelectedInfo || {}}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    casesStates={casesStates}
                    activeFilter={filter}
                    logs={logs}
                  />
                </div>
                <div className="box box2">
                  <Treemap selectedType={selectedType} />
                </div>
              </div>
              <div
                className="box-down"
                style={{
                  minHeight: !isMobile && !isTablet && `${info.height * 0.4}px`,
                }}
              >
                <div className="box box4">
                  <LineCharts
                    type="ACTIVE_CASE"
                    dataSets={activeApiData}
                    activeFilter={filter}
                    loading={!isLoadingChartActive && !!activeApiData}
                  />
                </div>
                <div className="box box5">
                  <LineCharts
                    type="CLOSED_CASE"
                    dataSets={closedApiData}
                    activeFilter={filter}
                    loading={!isLoadingChartClosed && !!closedApiData}
                  />
                </div>
                <div className="box box3">
                  <LineCharts
                    type="CREATED_CASE"
                    dataSets={createdApiData}
                    activeFilter={filter}
                    loading={!isLoadingChartCreated && !!createdApiData}
                  />
                </div>
                <div className="box box6">
                  <LineCharts
                    type="RESOLUTION_TIME"
                    dataSets={resolutionApiData}
                    activeFilter={filter}
                    loading={!isLoadingChartResolution && !!resolutionApiData}
                  />
                </div>
              </div>

              {showHistory ? (
                <div
                  id="activity"
                  className="box box7"
                  style={{
                    marginLeft: '10px',
                    minHeight:
                      isTablet || isMobile ? `${info.height * 0.92}px` : `${info.height * 0.92}px`,
                  }}
                >
                  <CaseActivity
                    instances={instances}
                    setCaseStatus={setCaseStatus}
                    setCaseCategory={setCaseCategory}
                    setCaseOperationId={setCaseOperationId}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="info-space-A">
              <DashboardFilters
                instances={instances}
                activeCase={caseSelectedId}
                selectedType={selectedType}
                filter={filter}
                setFilter={setFilter}
                rangeDates={rangeDates}
                setRangeDates={setRangeDates}
                showHistory={handleHistory}
                disable={disable}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <DashBoardEmptyState />
    )
  ) : (
    <Loader fit />
  );
};

export default MainDashboard;
