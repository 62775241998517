/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';

import listEmptyTeams from '../../../assets/svgs/arts/undraw_teams.svg';
import { Accordion, Button } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import useWindowSize from '../../../hooks/useWindowSize';
import { workSpaceService, humansService } from '../../../services';
import SettingDetail from '../SettingDetail/SettingDetail';
// import CaseDetail from './CaseDetail/CaseDetail';

import './Settings.css';

function Settings() {
  const info = useWindowSize();
  const [nameFilter, setNameFilter] = useState('');
  const [disableClear, setDisableClear] = useState(false);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filteredOperators, setFilteredOperators] = useState([]);
  const [usersRetrieved, setUsersRetrieved] = useState([]);
  const [loading, setLoading] = useState(false);
  const { menuCollapse, accountInfo } = useContext(AccountContext);
  const { workspaceData } = accountInfo;
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const [isReadyToUpdate, setIsReadyToUpdate] = useState(false);
  const [availabledList, setAvailableList] = useState([]);
  const {
    casesByUser,
    groupsRetrieved,
    overall,
    teamSelected,
    operatorSelected,
    setTeamSelected,
    setOperatorSelected,
    fetchOverAllCases,
  } = useContext(TicketsContext);

  const loadAvailableUsers = async () => {
    const { success, data: availableUserData } = await workSpaceService.getAllUsersByWorkspace(
      workspaceData?.data?.id
    );

    if (success) {
      setAvailableList(availableUserData.data);
    } else {
      setAvailableList([]);
    }
  };

  const retriveUsers = (groupList) => {
    const listUsers = groupList
      .filter((g) => !!g.operator.name)
      .map((g) => g.operator.name)
      .flat();
    setUsersRetrieved([...new Set(listUsers)]);
  };

  useEffect(async () => {
    await loadAvailableUsers();
    if (overall?.length === 0) {
      fetchOverAllCases();
    }
  }, []);

  useEffect(() => {
    if (overall.length > 0) {
      retriveUsers(overall);
    }
  }, [overall]);

  useEffect(() => {
    if (operatorSelected.length) {
      setTeamSelected({});
    }
  }, [operatorSelected]);

  useEffect(() => {
    if (Object.keys(teamSelected).length) {
      setOperatorSelected({});
    }
  }, [teamSelected]);

  const handleFilterDistribution = [
    {
      id: 1,
      type: 'teams',
      title: 'Teams',
      amount: filteredTeams?.length || 0,
      content: filteredTeams,
      legend: 'A group of operators!',
    },
    {
      id: 2,
      type: 'operators',
      title: 'Members',
      amount: filteredOperators?.length || 0,
      content: filteredOperators,
      legend: 'In charge of the tickets!',
    },
  ];

  const handleDistribution = [
    {
      id: 1,
      type: 'teams',
      title: 'Teams',
      amount: groupsRetrieved?.length || 0,
      content: groupsRetrieved,
      legend: 'A group of members!',
    },
    {
      id: 2,
      type: 'operators',
      title: 'Members',
      amount: usersRetrieved?.length || 0,
      content: usersRetrieved,
      legend: 'In charge of the tickets!',
    },
  ];

  // eslint-disable-next-line react/prop-types
  const DetailEmptyState = ({ message }) => {
    return (
      <div className="detail-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyTeams} alt="Search a case" />
          <p className="detail-message">{message}</p>
        </div>
      </div>
    );
  };

  const handleDetailMessage = () => {
    return 'Select a team or member for more details';
  };

  const handleReset = () => {
    setNameFilter('');
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchSettings-input');
    input?.focus();
  };

  useEffect(() => {
    setTeamSelected({});
    setOperatorSelected({});
    setTimeout(() => {
      handleFocusInput();
    }, 1000);
  }, []);

  const handleFiltering = () => {
    const filterInLower = nameFilter.toLowerCase();
    const teamsReady = groupsRetrieved.filter(
      (g) => g.name.toLowerCase().indexOf(filterInLower) >= 0
    );
    const operatorsReady = usersRetrieved.filter(
      (u) => u.toLowerCase().indexOf(filterInLower) >= 0
    );
    return { team: teamsReady, operator: operatorsReady };
  };

  useEffect(() => {
    setTeamSelected({});
    setOperatorSelected({});
    if (nameFilter !== '') {
      const { team, operator } = handleFiltering();
      setFilteredTeams(team);
      setFilteredOperators(operator);
    } else {
      setFilteredTeams([]);
      setFilteredOperators([]);
    }
    setTimeout(() => {
      if (nameFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [nameFilter]);

  useEffect(() => {
    if (filteredOperators.length > 0 || filteredTeams.length > 0) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, [filteredOperators, filteredTeams]);

  const HIListing = () => (
    <div className="HI-listing" style={{ marginLeft: !isMobile && !isTablet ? '15px' : '0px' }}>
      <div className="search-wrapper">
        <DebounceInput
          id="searchSettings-input"
          className="buttonFilter"
          value={nameFilter}
          debounceTimeout={700}
          onChange={(e) => setNameFilter(e.target.value)}
          placeholder="Search"
          autoComplete="off"
        />
        <Button
          disabled={disableClear}
          title="Clear search"
          icon="pi pi-filter"
          className="my-ticket-clear"
          onClick={handleReset}
        />
      </div>
      {!loading ? (
        <div className="HI-listing-container">
          <Accordion items={nameFilter ? handleFilterDistribution : handleDistribution} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const HIDetail = () => (
    <div className="HI-details">
      {Object.keys(teamSelected).length !== 0 || Object.keys(operatorSelected).length !== 0 ? (
        <SettingDetail
          type={Object.keys(teamSelected).length !== 0 ? 'team' : 'operator'}
          data={Object.keys(teamSelected).length !== 0 ? teamSelected : operatorSelected}
          availabledUser={availabledList}
        />
      ) : (
        <DetailEmptyState message={handleDetailMessage()} />
      )}
    </div>
  );

  const ListEmptyPersonalState = () => {
    return (
      <div className="list-empty-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyTeams} alt="Search a case" />
          <p className="detail-message">No teams found!</p>
        </div>
      </div>
    );
  };

  return (
    <div className="HI-wrapper">
      {casesByUser ? (
        <div
          className="settings-tickets-container"
          style={{
            width: isTablet
              ? isMobile
                ? !menuCollapse
                  ? '92vw'
                  : `${info.width - 115}px`
                : !menuCollapse
                ? '95vw'
                : `${info.width - 115}px`
              : '',
          }}
        >
          {!isMobile && !isTablet ? (
            <>
              <HIListing />
              <HIDetail />
            </>
          ) : (
            <>{Object.keys(teamSelected).length === 0 ? <HIListing /> : <HIDetail />}</>
          )}
        </div>
      ) : (
        <ListEmptyPersonalState />
      )}
    </div>
  );
}

Settings.propTypes = {};

export default Settings;
