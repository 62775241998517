import React from 'react';

import { AccountContextProvider } from './contexts/AccountContext';
import { ResponsiveContextProvider } from './contexts/ResponsiveContext';
import { TicketsContextProvider } from './contexts/TicketsContext';
import { ToolsContextProvider } from './contexts/ToolsContext';
import Routes from './routes';

function App() {
  return (
    <ResponsiveContextProvider>
      <AccountContextProvider>
        <TicketsContextProvider>
          <ToolsContextProvider>
            <Routes />
          </ToolsContextProvider>
        </TicketsContextProvider>
      </AccountContextProvider>
    </ResponsiveContextProvider>
  );
}

export default App;
