/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';

import listEmptyTicketImage from '../../assets/svgs/archive.svg';
import listEmptyPersonalImage from '../../assets/svgs/arts/undraw_personal.svg';
import searchCaseImage from '../../assets/svgs/arts/undraw_process.svg';
import { Accordion, Button, Loader, MultiSelect } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import useWindowSize from '../../hooks/useWindowSize';
import CaseDetail from './CaseDetail/CaseDetail';

import './HumanInterface.css';

const MyTickets = () => {
  const history = useHistory();
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([]);
  const [isCameraOpen, setIsCameraOpen] = useState(true);
  const { accountInfo, menuCollapse } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const info = useWindowSize();
  const [caseFilter, setCaseFilter] = useState('');
  const [disableClear, setDisableClear] = useState(false);
  const [filteredCases, setFilteredCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const handleRemoveImg = () => {
    setImgSrc(null);
  };

  const handleSaveImage = () => {
    setIsCameraOpen(false);
  };

  const WebcamComponent = () => (
    <div className="camera">
      {!imgSrc ? (
        <div className="camera-container">
          <Loader fit />
          <Webcam height={isMobile ? 200 : 300} ref={webcamRef} screenshotFormat="image/jpeg" />
          <Button style={{ minHeight: '40px', maxWidth: '140px' }} onClick={capture}>
            Capture photo
          </Button>
        </div>
      ) : (
        <div className="camera-container">
          <img src={imgSrc} />
          <div className="flex">
            <Button style={{ minHeight: '40px', maxWidth: '40px' }} onClick={handleRemoveImg}>
              X
            </Button>
            <Button style={{ minHeight: '40px', maxWidth: '140px' }} onClick={handleSaveImage}>
              Save photo
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  const {
    casesByUser,
    cases,
    assigned,
    unassigned,
    selectedTicket,
    setSelectedTicket,
    handleFilterCase,
    styleGuideByGroup,
    groupsRetrieved,
  } = useContext(TicketsContext);
  const { userData } = accountInfo || {};
  const { enabledModules } = userData || [];

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    cases?.length && setIsLoading(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 1700);
  }, []);

  const handleDistribution = [
    {
      id: 1,
      type: 'ticket',
      title: 'Assigned to me',
      amount: assigned?.length || 0,
      content: assigned,
      legend: 'On progress!',
    },
    {
      id: 2,
      type: 'ticket',
      title: 'Pending',
      amount: unassigned?.length || 0,
      content: unassigned,
      legend: 'Waiting for you!',
    },
  ];

  const handleFilterDistribution = [
    {
      id: 1,
      title: 'Results',
      type: 'ticket',
      amount: filteredCases?.length || 0,
      content: filteredCases,
      legend: 'Filter results!',
    },
  ];

  // eslint-disable-next-line react/prop-types
  const DetailEmptyState = ({ message }) => {
    return (
      <div className="detail-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={searchCaseImage} alt="Search a case" />
          <p className="detail-message">{message}</p>
        </div>
      </div>
    );
  };

  const handleDetailMessage = () => {
    return cases?.length === 0 ? 'No tickets found!' : 'Select a ticket for more details';
  };

  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]}`}
      >
        <p className="groups">{c.name}</p>
      </div>
    );
  };

  const handleReset = () => {
    setSelectedCasesGroups([]);
    setCaseFilter('');
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchTicket-input');
    input?.focus();
  };

  useEffect(() => {
    const handleUserValidation = () => {
      const validUser = enabledModules?.some((data) => data.id === 'HUMAN INTERFACE');
      if (!validUser) {
        history.push('/401');
      }
    };
    handleUserValidation();
    casesByUser();
    setTimeout(() => {
      handleFocusInput();
    }, 2500);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      selectedTicket && casesByUser();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedTicket]);

  useEffect(async () => {
    setSelectedTicket(null);
    if (caseFilter !== '' || groupsRetrieved?.length > 0) {
      setFilteredCases(await handleFilterCase(caseFilter, selectedCasesGroups));
    } else {
      setFilteredCases([]);
    }
    setTimeout(() => {
      if (selectedCasesGroups?.length || caseFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [caseFilter, selectedCasesGroups]);

  const HIListing = () => (
    <div className="HI-listing">
      {assigned?.length && unassigned?.length ? (
        <div>
          <div className="search-wrapper">
            <DebounceInput
              id="searchTicket-input"
              className="buttonFilter"
              value={caseFilter}
              debounceTimeout={700}
              onChange={(e) => setCaseFilter(e.target.value)}
              placeholder="Search by Ticket ID"
              autoComplete="off"
            />
            <div className="group-filter-wrapper">
              {groupsRetrieved.length > 0 ? (
                <MultiSelect
                  placeholder={!isMobile ? 'Teams' : ''}
                  className="history-A-dropdown"
                  value={selectedCasesGroups}
                  options={groupsRetrieved || []}
                  onChange={(e) => setSelectedCasesGroups(e.value)}
                  itemTemplate={groupTemplate}
                  fixedPlaceholder
                />
              ) : (
                <></>
              )}
            </div>
            <Button
              disabled={disableClear}
              title="Clear search"
              icon="pi pi-filter"
              className="my-ticket-clear"
              onClick={handleReset}
            />
          </div>
          <div className="HI-listing-container">
            <Accordion
              items={
                caseFilter !== '' || selectedCasesGroups?.length > 0
                  ? handleFilterDistribution
                  : handleDistribution
              }
            />
          </div>
        </div>
      ) : (
        <div className="empty-listing">
          <img className="empty-basket" src={listEmptyTicketImage} />
          There are no more tickets in your inbox.
        </div>
      )}
    </div>
  );

  const HIDetail = () => (
    <div className="HI-details">
      {selectedTicket && Object.keys(selectedTicket).length !== 0 ? (
        <CaseDetail setCaseFilter={setCaseFilter} />
      ) : (
        <DetailEmptyState message={handleDetailMessage()} />
      )}
    </div>
  );

  const ListEmptyPersonalState = () => {
    return (
      <div className="list-empty-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyPersonalImage} alt="Search a case" />
          <p className="detail-message">No personal cases found!</p>
        </div>
      </div>
    );
  };

  return (
    <div className="HI-wrapper">
      {casesByUser ? (
        <div
          className="HI-container"
          style={{
            width: isTablet
              ? isMobile
                ? !menuCollapse
                  ? '100%'
                  : `${info.width - 110}px`
                : !menuCollapse
                ? '100%'
                : `${info.width - 115}px`
              : '',
          }}
        >
          {!isLoading ? (
            <>
              {!isMobile && !isTablet ? (
                <>
                  <HIListing />
                  <HIDetail />
                </>
              ) : (
                <>{Object.keys(selectedTicket).length === 0 ? <HIListing /> : <HIDetail />}</>
              )}
            </>
          ) : (
            <Loader fit />
          )}
        </div>
      ) : (
        <ListEmptyPersonalState />
      )}
    </div>
  );
};

export default MyTickets;
