/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';

// import profileImg1 from '../../../assets/svgs/arts/undraw_profile_1.svg';
// import profileImg2 from '../../../assets/svgs/arts/undraw_profile_2.svg';
import { Loader, UsersAndEngineDrag } from '../../../components';
import { Button } from '../../../components/BasicComponents';
import { AccountContext } from '../../../contexts/AccountContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import { humansService } from '../../../services';
import './SettingDetail.css';

const SettingDetail = ({ type, data, availabledUser }) => {
  const { showSnackbar } = useContext(AccountContext);
  const [assignedList, setAssignedList] = useState([]);
  const [availabledList, setAvailableList] = useState(availabledUser);
  const [loading, setLoading] = useState(false);
  const [isReadyToUpdate, setIsReadyToUpdate] = useState(false);
  const [team, setTeam] = useState({});
  const {
    teamSelected,
    operatorSelected,
    setOperatorSelected,
    setTeamSelected,
    styleGuideByGroupHex,
  } = useContext(TicketsContext);
  const [selectedTeamColor, setSelectedTeamColor] = useState(
    styleGuideByGroupHex[teamSelected.id?.slice(-1)] || styleGuideByGroupHex[0]
  );

  const newDataGroups = {
    groups: assignedList,
  };

  const newDataOperators = {
    operator: assignedList,
  };

  const handleReset = () => {
    setOperatorSelected('');
    setTeamSelected({});
  };

  const handleChangeColor = (value) => {
    setSelectedTeamColor('red');
  };

  const newData = {
    id: data?.id,
    users: assignedList,
  };

  const handleAssignedClick = (id) => {
    const index = assignedList.findIndex((item) => item.id === id);

    if (index !== -1) {
      assignedList[index].selected = !assignedList[index].selected;
      setAssignedList([...assignedList]);
    }
  };

  const handleAvailableClick = (id) => {
    const index = availabledList?.findIndex((item) => item.id === id);
    if (index !== -1) {
      availabledList[index].selected = !availabledList[index]?.selected;
      setAvailableList([...availabledList]);
    }
  };

  const onAssigned = (e) => {
    e.preventDefault();

    availabledList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        assignedList?.push(obj);
      }
    });
    const newAvailabled = availabledList?.filter((item) => !item.selected);
    setAvailableList([...newAvailabled]);
    setAssignedList([...assignedList]);
    setIsReadyToUpdate(true);
  };

  const onAvailable = (e) => {
    e.preventDefault();
    assignedList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        setAvailableList((p) => (p.length !== 0 ? [...p, obj] : [obj]));
      }
    });
    const newAssigned = assignedList?.filter((item) => !item.selected);
    // eslint-disable-next-line no-unused-expressions
    availabledList?.length > 0 && setAvailableList([...availabledList]);
    setAssignedList([...newAssigned]);
    setIsReadyToUpdate(true);
  };

  const loadAssignedUsers = async () => {
    const { success, data: teamData } = await humansService.getHumanGroup(data.id);
    if (success) {
      setTeam(teamData.data);
      setAssignedList(teamData.data.humans);
    }
    setLoading(false);
  };

  const handleUpdate = () => {
    showSnackbar('info', 'Attention!', 'API CALL in progresss !!');
    setIsReadyToUpdate(false);
  };

  useEffect(async () => {
    setLoading(true);
    await loadAssignedUsers();
  }, []);

  return (
    <div className="setting-details">
      <div className="setting-details-close">
        <Button
          title="Exit Ticket"
          className="p-button-rounded close2"
          onClick={() => handleReset()}
        />
      </div>
      {type === 'team' ? (
        <div className="setting-details-content">
          <div className="setting-details-dragger">
            {!loading ? (
              <UsersAndEngineDrag
                assignedList={assignedList}
                availabledList={availabledList}
                formType="UsersToTeamsForm"
                handleAssignedClick={handleAssignedClick}
                handleAvailableClick={handleAvailableClick}
                onAssigned={onAssigned}
                onAvailable={onAvailable}
              />
            ) : (
              <Loader fit type="feeder" />
            )}
          </div>
          <div
            className="wrapper-selected"
            style={{
              background:
                styleGuideByGroupHex[teamSelected.id?.slice(-1)] || styleGuideByGroupHex[0],
            }}
          >
            <div className="setting-details-picker">
              <p className="setting-details-color-txt">Color team</p>
              <input
                type="color"
                id="favcolor"
                name="favcolor"
                value={selectedTeamColor}
                onChange={(e) => handleChangeColor(e.value)}
              />
            </div>
            <div className="team-wrapper">
              {isReadyToUpdate ? (
                <Button
                  title="Save changes"
                  className="p-button-rounded p-button-outlined ui-button-flat button-case-action saveData save-btn"
                  icon="pi pi-save"
                  onClick={() => handleUpdate()}
                />
              ) : (
                <></>
              )}
              <h3 className="team-title">{teamSelected.name}</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="setting-details-content">
          <div className="setting-details-profile-wrapper">
            {/* <img
              className="setting-details-profile"
              src={Boolean(+Date.now() % 2) ? profileImg2 : profileImg1}
              alt="User image"
            /> */}
          </div>
          <div className="wrapper-selected wrapper-end blue-gradient">
            <h3 className="team-title">{operatorSelected}</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingDetail;
