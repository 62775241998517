import React, { useEffect } from 'react';

import './RefreshedTickets.css';
import CancelTicket from '../../assets/svgs/arts/cancel.svg';
import { deleteCookie } from '../../utils/tools';

function RefreshedTickets() {
  useEffect(() => {
    deleteCookie('credentials');
    return () => {};
  }, []);

  return (
    <div className="not-found-main">
      <div className="not-found-card bounce">
        <img src={CancelTicket} className="not-found-card-img" alt="Ticket not found!" />
        <h2 className="not-found-header bounce">
          We regret to inform you that you have been disconnected from the system due to a page
          refresh/logout. Please access the form again using the link sent to you.
        </h2>
        <h2 className="not-found-header bounce">
          Lamentamos informarle que se ha desconectado del formulario debido a que la página se
          refrescó o cierre de sesión. Vuelva a acceder al formulario utilizando el enlace que se le
          envió.
        </h2>
      </div>
    </div>
  );
}

export default RefreshedTickets;
