/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import './Auth.css';

const Auth = ({ children }) => {
  return <div className="authentication-route">{children}</div>;
};

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
