/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import listEmptyImage from '../../assets/svgs/arts/undraw_apps.svg';
import { AppCard, Button, Loader, SelectButton } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { CreateAppForm } from '../../forms';
import { appDashboardService, flowsService } from '../../services';
import utils from '../../utils/utils';

import './MyAppsPage.css';

const MyAppsPage = ({ location }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    accountInfo,
    tools,
    setTools,
    showSnackbar,
    apps,
    setApps,
    setSelectedApp,
    setSelectedFlow,
    menuCollapse,
  } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { userData, workspaceData } = accountInfo;
  const { id: userId, enabledModules } = userData;
  const [filter, setFilter] = useState(0);
  const [globalFilter, setGlobalFilter] = useState(null);

  const filterOptions = [
    { name: 'New App', value: 1 },
    { name: 'Search', value: 2 },
  ];

  const onAppEdit = (bot) => {
    setSelectedApp(bot);
    history.push({ pathname: '/apps/edit' });
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchApp-input');
    input?.focus();
  };

  const onAppHistorySelect = (app) => {
    setSelectedApp(app);
    history.push({ pathname: '/apps/history' });
  };

  const onFlowSelected = async (app) => {
    const workflows = (await flowsService.getAllFlows('1')).data;
    const appWorkflow = workflows.flows.find((w) => w.id === app.workflow.id);
    setSelectedFlow(appWorkflow);
    history.push(`/flows/flow?canEditFlow=${true}&&isNewFlow=false`);
  };

  const handleSortingByDescription = (info) => {
    const withDescription = info.filter((d) => d.description);
    const withoutDescription = info.filter((d) => !d.description);
    return [...withDescription, ...withoutDescription];
  };

  const fetchAllApps = async (loader = true) => {
    loader && setLoading(true);
    if (userId) {
      const { success, data: allApps } = await appDashboardService.getAllApps(userId);
      if (success) {
        setApps(handleSortingByDescription(allApps));
        setTimeout(() => {
          loader && setLoading(false);
        }, 1700);
      } else {
        showSnackbar('error', 'Error!', 'Apps could not be loaded');
        loader && setLoading(false);
      }
    } else {
      showSnackbar('error', 'Error!', 'Apps could not be loaded');
      loader && setLoading(false);
    }
  };

  const onChangeIsActive = async (appId, value) => {
    const { success } = await appDashboardService.updateApp(appId, {
      isActive: value,
    });
    if (success) {
      showSnackbar('success', 'Attention!', 'App updated succesfully');
      fetchAllApps(false);
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong updating the app! Please try again');
    }
  };

  const onDeleteApp = async (appId) => {
    const { success } = await appDashboardService.deleteApp(appId);
    if (success) {
      showSnackbar('success', 'Attention!', 'App deleted succesfully');
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong updating the app! Please try again');
    }
  };

  const handleCreateApp = async (e, info) => {
    e.preventDefault();
    const { success } = await appDashboardService.createApp(
      {
        id: userId,
      },
      {
        name: info.name,
      }
    );
    if (success) {
      showSnackbar('success', 'Atention!', 'App created succesfully');
      fetchAllApps(false);
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong updating the app! Please try again');
    }
    setTools({ ...tools, showModal: false });
  };

  const filterTemplate = (option) => {
    if (option.value === 1) {
      return (
        <Button
          className="button create-button"
          label={option.name}
          icon="pi pi-plus"
          onClick={() => {
            setFilter(0);
            setTools({
              ...tools,
              manageModal: {
                header: <>New App</>,
                content: (
                  <CreateAppForm
                    data={{
                      workspace: workspaceData.data.id,
                    }}
                    handleSubmit={handleCreateApp}
                  />
                ),
              },
              showModal: true,
            });
          }}
        />
      );
    }
    return <p className="chipFilter">{option.name}</p>;
  };

  useEffect(() => {
    if (!location?.goBack && apps?.length === 0) {
      const handleUserValidation = () => {
        const validUser = enabledModules?.some((data) => data.id === 'APPS');
        if (!validUser) {
          history.push('/401');
        } else {
          fetchAllApps();
        }
      };
      handleUserValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ListEmptyState = () => {
    return (
      <div className="list-empty-apps-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyImage} alt="No apps found!" />
          <p className="list-empty-apps-message">No apps found!</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (filter === 2) {
      document.getElementById('messages').scrollTo(1000, 0);
      handleFocusInput();
    }
  }, [filter]);

  const handleFiltering = () => {
    const result = apps?.filter((app) => {
      const appNameLower = app?.name.toLowerCase();
      const globalFilterLower = globalFilter?.toLowerCase();
      return appNameLower.indexOf(globalFilterLower) >= 0;
    });
    return globalFilter ? result : apps;
  };

  const appsFilteredByName = handleFiltering();

  return !loading ? (
    <div className="my-apps-wrapper">
      <div className="my-apps-container">
        <div className="info-space-A">
          <div className="wrapper-filters">
            <div
              style={{
                width: isMobile
                  ? isTablet
                    ? menuCollapse
                      ? '74vw'
                      : '93vw'
                    : menuCollapse
                    ? '74vw'
                    : '95vw'
                  : menuCollapse
                  ? '65vw'
                  : '93vw',
              }}
            >
              <div id="messages">
                <SelectButton
                  value={filter}
                  options={filterOptions}
                  onChange={(e) => setFilter(e.value === null ? 0 : e.value)}
                  optionLabel="name"
                  className="filters-modeler"
                  itemTemplate={filterTemplate}
                />
                {filter === 2 && apps ? (
                  <DebounceInput
                    id="searchApp-input"
                    className="buttonFilter"
                    value={globalFilter}
                    debounceTimeout={700}
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search app"
                    autoComplete="off"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {appsFilteredByName?.length > 0 ? (
          <div className="my-apps-list">
            {appsFilteredByName?.map((app, i) => (
              <article className="my-apps-list-item" key={`App-${app.id + i}`}>
                <AppCard
                  app={app}
                  onAppEdit={onAppEdit}
                  backgroundColor={utils.getColor(i, utils.appDashboardColors)}
                  onHistorySelect={onAppHistorySelect}
                  onFlowSelected={onFlowSelected}
                  onChangeIsActive={onChangeIsActive}
                  onDelete={onDeleteApp}
                />
              </article>
            ))}
          </div>
        ) : (
          <ListEmptyState />
        )}
      </div>
    </div>
  ) : (
    <Loader fit />
  );
};

export default MyAppsPage;
