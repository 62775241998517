/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';

import Moment from 'moment';
import PropTypes from 'prop-types';

import { TabView, TabPanel, DataTable, Column } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import useWindowSize from '../../hooks/useWindowSize';
import { casesDashboardService } from '../../services';
import './ViewCaseInstancesForm.css';

const ViewCaseInstancesForm = ({ data }) => {
  const info = useWindowSize();
  const { showSnackbar } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const [instances, setInstances] = useState([]);

  const totalTime = data.closingDate
    ? Moment.utc(Moment(data.closingDate).diff(Moment(data.creationDate))).format(
        'DD [days] HH [hours] mm [minutes]'
      )
    : Moment.utc(Moment().diff(Moment(data.creationDate))).format(
        'DD [days] HH [hours] mm [minutes]'
      );

  const fetchInstances = async () => {
    const instancesResult = await casesDashboardService.listInstancesOfCase(data.id);
    let i = 0;
    const results = [];

    if (instancesResult.success) {
      for (i = 0; i < instancesResult.data.length; i += 1) {
        results[i] = {};
        results[i].key = i + 1;
        results[i].data = instancesResult.data[i];
      }
    } else {
      showSnackbar('error', 'Error to fetch instances', '');
    }

    for (i = 0; i < results.length - 1; i += 1) {
      const duration = Moment.duration(
        Moment(results[i + 1].data.date).diff(Moment(results[i].data.date))
      ).humanize();
      results[i].data.timeSpent = duration;
    }
    setInstances(results);
  };

  useEffect(async () => {
    fetchInstances();
  }, []);

  return (
    <div className="view-case-tab-view">
      <TabView>
        <TabPanel header="General">
          <section className="tabs" style={{ marginTop: isMobile || isTablet ? '30px' : '45px' }}>
            <div className="flex app-formGroup">
              <label className="view-case-title">Case Id</label>
              <p className="view-case-value">{data.id}</p>
            </div>
            <div className="flex app-formGroup">
              <label className="view-case-title">Operation Id</label>
              <p className="view-case-value">{data.operation.id}</p>
            </div>
            <div className="flex app-formGroup">
              <label className="view-case-title">Total Time</label>
              <p className="view-case-value">{totalTime}</p>
            </div>
          </section>
        </TabPanel>
        <TabPanel header="Activity">
          <div className="tabs">
            <DataTable
              value={instances}
              emptyMessage="No instances found."
              fontSize="10pt"
              rows={30}
              resizableColumns
              paginator
              scrollablescrollable
              scrollHeight={
                isMobile || isTablet ? `${info.height - 155}px` : `${info.height * 0.2}px`
              }
            >
              <Column
                field="id"
                header="Instance Id"
                headerStyle={{
                  width: '7rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              />
              <Column
                field="app.id"
                header="App Id"
                headerStyle={{
                  width: '5rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              />
              <Column
                field="app.name"
                header="App Name"
                headerStyle={{
                  width: '10rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              />
              <Column
                field="startDate"
                header="Start Date"
                headerStyle={{
                  width: '9rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              />
              <Column
                field="endDate"
                header="End Date"
                headerStyle={{
                  width: '9rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              />
              <Column
                field="status"
                header="Status"
                headerStyle={{
                  width: '6rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'center',
                }}
              />
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

ViewCaseInstancesForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ViewCaseInstancesForm;
