/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import Moment from 'moment';
import { useHistory } from 'react-router-dom';

import listEmptyImage from '../../assets/svgs/arts/undraw_apps.svg';
import {
  Button,
  Column,
  GoBackComponent,
  Calendar,
  DataTable,
  Loader,
  MultiSelect,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { ViewStageForm } from '../../forms';
import useWindowSize from '../../hooks/useWindowSize';
import { appDashboardService } from '../../services';

import './AppsHistory.css';

const DEFAULT_STATUS = [
  { name: 'RUNNING', code: 1 },
  { name: 'ERROR', code: 2 },
  { name: 'HOLD', code: 3 },
  { name: 'DONE', code: 4 },
];

const DEFAULT_FILTERS = {
  startDate: { value: null, matchMode: 'contains' },
  endDate: { value: null, matchMode: 'contains' },
  status: { value: null, matchMode: 'in' },
};

const AppsHistory = (prop) => {
  const info = useWindowSize();
  const history = useHistory();
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { tools, setTools, selectedApp, handleTask, menuCollapse } = useContext(AccountContext);
  const [apps, setApps] = useState([]);
  const [rangeDates, setRangeDates] = useState('');
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [refreshTable, setRefreshTable] = useState(true);

  const handleReset = () => {
    setSelectedCasesStatus(null);
    setRangeDates('');
  };

  const handleGetStatusCode = () => {
    return selectedCasesStatus?.map((c) => c.name);
  };

  const getMinDate = () => {
    return '';
  };

  const getMaxDate = () => {
    return '';
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyImage} alt="Search a case" />
          <p className="detail-message">No instances found!</p>
        </div>
      </div>
    );
  };

  const handleGoBack = (preLoad) => {
    history.push({ pathname: '/apps', goBack: preLoad });
  };

  const fetchAppInstances = async () => {
    setLoading(true);
    const startRange = getMinDate();
    const endRange = getMaxDate();
    const start = startRange ? Moment(startRange).format('YYYY-MM-DDTHH:mm:ss') : null;
    const end = endRange ? Moment(endRange).format('YYYY-MM-DDTHH:mm:ss') : null;
    const { success, data: appsInstancesResult } = await appDashboardService.listInstancesOfApp(
      selectedApp.id,
      start,
      end,
      selectedCasesStatus
    );
    if (success && appsInstancesResult.length > 0) {
      setApps(appsInstancesResult);
    } else {
      handleGoBack(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (filters) {
      setRefreshTable(true);
      setTimeout(() => {
        setRefreshTable(false);
      }, 200);
    }
  }, [filters]);

  useEffect(() => {
    setFilters({
      startDate: { value: getMinDate(), matchMode: 'contains' },
      endDate: { value: getMaxDate(), matchMode: 'contains' },
      status: { value: handleGetStatusCode(), matchMode: 'in' },
    });
    setTimeout(() => {
      if (selectedCasesStatus?.length || rangeDates?.length) {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesStatus, rangeDates]);

  const showStageDetail = async (stageId) => {
    const { success, data } = await appDashboardService.getStageOfInstanceOfApp(
      selectedApp.id,
      selectedApp.id,
      stageId
    );

    if (success) {
      setTools({
        ...tools,
        manageModal: {
          content: (
            <ViewStageForm
              data={data}
              title="Stage Detail"
              handleSubmit={() => {
                handleTask();
              }}
            />
          ),
        },
        showModal: true,
      });
    }
  };

  const statusData = [
    <p className="chip">Running</p>,
    <p className="chip">Error</p>,
    <p className="chip">Hold</p>,
    <p className="chip">Done</p>,
  ];

  const actionTemplate = (node) => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-eye"
          className="p-button-success action-btn"
          onClick={() => {
            showStageDetail(node.id);
            // handleTask();
          }}
        />
      </div>
    );
  };

  const statusTemplate = (option) => {
    return <div>{statusData[option.code - 1]}</div>;
  };

  useEffect(() => {
    fetchAppInstances();
  }, []);

  const handleDates = (value) => {
    if (value.length === 2) {
      setRangeDates(value);
    }
  };

  return (
    <div className="apps-history-wrapper">
      <GoBackComponent callback={() => handleGoBack(false)} />
      {!loading ? (
        <div>
          {apps ? (
            <div>
              <div
                className="flex product-overview-apps"
                style={{
                  width:
                    isMobile || isTablet
                      ? menuCollapse
                        ? `${info.width - 115}px`
                        : `${info.width - 30}px`
                      : menuCollapse
                      ? `${info.width - 150}px`
                      : `${info.width - 155}px`,
                }}
              >
                <div className="overview-apps-wrapper">
                  <div className="app-overview">
                    <Calendar
                      placeholder="Select a range"
                      selectionMode="range"
                      className="range-picker"
                      readOnlyInput
                      hourFormat="24"
                      value={rangeDates}
                      onChange={(e) => handleDates(e.value)}
                      dateFormat="yy-mm-dd"
                    />
                    <MultiSelect
                      optionLabel="name"
                      placeholder="Status"
                      className="history-A-dropdown"
                      value={selectedCasesStatus}
                      options={DEFAULT_STATUS}
                      itemTemplate={statusTemplate}
                      onChange={(e) => setSelectedCasesStatus(e.value)}
                      maxSelectedLabels={1}
                      showClear
                      fixedPlaceholder
                    />
                    <Button
                      disabled={disableClear}
                      title="Clear filters"
                      icon="pi pi-filter"
                      className="buttonFilter history-A-clear"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
              <div>
                {!refreshTable ? (
                  <DataTable
                    value={apps}
                    resizableColumns
                    emptyMessage="No instances found."
                    rows={30}
                    paginator
                    globalFilterFields={['startDate', 'endDate', 'status']}
                    filters={filters}
                    scrollable
                    style={{
                      width:
                        isMobile || isTablet
                          ? menuCollapse
                            ? `${info.width - 115}px`
                            : `${info.width - 30}px`
                          : menuCollapse
                          ? `${info.width - 140}px`
                          : `${info.width - 65}px`,
                    }}
                    scrollHeight={
                      isMobile || isTablet ? `${info.height - 370}px` : `${info.height - 200}px`
                    }
                  >
                    <Column
                      field="id"
                      header="Instance Id"
                      headerStyle={{
                        textAlign: 'left',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '230px',
                      }}
                    />
                    <Column
                      field="name"
                      header="Name"
                      headerStyle={{
                        textAlign: 'left',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '160px',
                      }}
                    />
                    <Column
                      field="startDate"
                      filterField="startDate"
                      header="Start Date"
                      headerStyle={{
                        textAlign: 'left',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '230px',
                      }}
                    />
                    <Column
                      field="endDate"
                      filterField="endDate"
                      header="End Date"
                      headerStyle={{
                        textAlign: 'left',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '230px',
                      }}
                    />
                    <Column
                      field="status"
                      filterField="status"
                      header="Status"
                      sortable
                      headerStyle={{
                        textAlign: 'left',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '100px',
                      }}
                    />
                    <Column
                      field="statistics.executedPoints"
                      header="Executed Points"
                      sortable
                      headerStyle={{
                        textAlign: 'center',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '170px',
                      }}
                      style={{ textAlign: 'center' }}
                    />
                    <Column
                      header="Actions"
                      body={actionTemplate}
                      headerStyle={{
                        textAlign: 'center',
                        backgroundColor: '#22384D',
                        color: 'white',
                        width: '170px',
                      }}
                      style={{ textAlign: 'center' }}
                    />
                  </DataTable>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <ListEmptyState />
          )}
        </div>
      ) : (
        <Loader fit />
      )}
    </div>
  );
};

export default AppsHistory;
