/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { TabView, TabPanel } from '../../components';
import './HumanInterface.css';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import useWindowSize from '../../hooks/useWindowSize';
import GroupCases from './GroupCases/GroupCases';
import MyTickets from './MyTickets';
import Settings from './Settings/Settings';

const HumanInterface = ({ location }) => {
  const info = useWindowSize();
  const { accountInfo, menuCollapse, setMenuCollapse } = useContext(AccountContext);
  const { handleCaseById, cases, getGroups, fetchOverAllCases } = useContext(TicketsContext);
  const { isMobile } = useContext(ResponsiveContext);
  const { role } = accountInfo?.userData || {};
  const allowMyTeamCases = role === 'ADMIN';
  const data = location.hash.split('/');
  const areMyCases = data[0] === '#my-tickets';
  const areMyTeamCases = data[0] === '#team-tickets';
  const areMySetting = data[0] === '#settings';
  const history = useHistory();
  const isValidTab = areMyCases || areMyTeamCases || areMySetting;
  if (!isValidTab) {
    history.push('/404');
  }
  const tab = data[0] === '#my-tickets' ? 0 : data[0] === '#team-tickets' ? 1 : 2;
  const [activeIndex, setActiveIndex] = useState(tab);

  const handleChangeTab = (index) => {
    setActiveIndex(index);
    window.location.hash =
      index === 0 ? '#my-tickets' : index === 1 ? '#team-tickets' : '#settings';
  };

  const handleCleanHasgAction = () => {
    const currentRoute = window.location.hash.split('/');
    const [id] = currentRoute[1].split('#');
    if (currentRoute.length === 2 || currentRoute.length === 1) {
      window.location.hash = `${currentRoute[0]}/${id}`;
    }
  };

  useEffect(() => {
    if (info.width > 570) {
      setMenuCollapse(true);
    } else if (!data[1]) {
      setMenuCollapse(true);
    } else {
      setMenuCollapse(false);
    }
  }, [info]);

  useEffect(() => {
    if (cases?.length) {
      if (data[1] && data[0] !== '#team-tickets') {
        let result;
        if (data[1].includes('#')) {
          const [id, actions] = data[1].split('#');
          result = handleCaseById(id, actions);
          handleCleanHasgAction();
        } else {
          result = handleCaseById(data[1]);
        }
        if (!result) {
          const base = data[0];
          window.location.hash = base;
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (!data[1]) {
      setMenuCollapse(true);
    } else {
      setMenuCollapse(false);
    }
    fetchOverAllCases();
    getGroups();
  }, []);

  return !isMobile ? (
    <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)}>
      <TabPanel header="Personal">
        <MyTickets />
      </TabPanel>
      <TabPanel disabled={!allowMyTeamCases} header="Teams">
        <GroupCases />
      </TabPanel>
      <TabPanel disabled={!allowMyTeamCases} header="Settings">
        <Settings />
      </TabPanel>
    </TabView>
  ) : (
    <div
      style={{
        marginLeft: menuCollapse ? '15px' : '0px',
        marginTop: menuCollapse ? '15px' : '0px',
        height: isMobile ? (menuCollapse ? '96%' : '100%') : '100%',
      }}
    >
      <MyTickets />
    </div>
  );
};

export default HumanInterface;
