/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import { Button } from '../../../../components/BasicComponents';
import { AccountContext } from '../../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';

import './PinList.css';

const PinList = ({ activePinId = 0, setActivePinId, pinList }) => {
  const [collapse, setCollapse] = useState(true);
  const { menuCollapse } = useContext(AccountContext);
  const { isMobile, isTablet, isLittleDesktop } = useContext(ResponsiveContext);

  const handleToggle = () => {
    setCollapse((pre) => !pre);
  };

  const handleActionToggle = (index) => {
    setActivePinId(index || 0);
    setCollapse((pre) => !pre);
  };

  return pinList !== [] ? (
    <div
      className="pin"
      style={{
        marginTop: isMobile ? (isTablet ? '30px' : '40px') : '6vh',
        marginLeft: isLittleDesktop
          ? isTablet
            ? isMobile
              ? menuCollapse
                ? '12px'
                : '12px'
              : menuCollapse
              ? '19px'
              : '19px'
            : menuCollapse
            ? '20px'
            : '20px'
          : '14px',
      }}
    >
      <div
        className={`list-wrapper ${collapse ? 'list-expand' : ''}`}
        onMouseLeave={handleToggle}
        onMouseEnter={handleToggle}
        onClick={handleToggle}
      >
        {pinList?.map((data, index) => {
          return (
            <ul key={data.id} className={`list-ul ${activePinId === index ? 'activeTab' : ''}`}>
              <li className="list-li">
                {!collapse ? (
                  <Button
                    onClick={() => handleActionToggle(index)}
                    className={`list-pin ${activePinId === index ? 'list-pin-active' : ''}`}
                  >
                    <span className="pin-text">{!collapse ? data.id : ''}</span>
                  </Button>
                ) : (
                  <div className="pinEmpty" />
                )}
              </li>
            </ul>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

PinList.propTypes = {
  activePinId: PropTypes.number.isRequired,
  setActivePinId: PropTypes.func.isRequired,
  pinList: PropTypes.array.isRequired,
};

export default PinList;
