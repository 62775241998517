/* eslint-disable react/no-array-index-key */
import React from 'react';

import PropTypes from 'prop-types';

import AccordionItem from './AccordionItem';
import './Accordion.css';

const Accordion = ({ items }) => {
  return (
    <div className="accordion-wrapper">
      {items.map((item, index) => (
        <AccordionItem
          index={index}
          id={item?.id}
          key={`${item?.title}-${item?.id}-${index}`}
          title={item?.title}
          titleMobile={item?.titleMobile}
          amount={item?.amount}
          legend={item?.legend}
          content={item?.content}
          size={items?.length}
          type={item?.type}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Accordion;
