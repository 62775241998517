import { httpRequest, wrap } from './tools';

const listCases = async (start, end, caseStatus, categoryId, operationId, type) => {
  const fn = async () => {
    let url = `/cases?a=a`;
    // url += start ? `&startCreationDate=${start}` : '';
    // url += end ? `&endCreationDate=${end}` : '';
    url += caseStatus && caseStatus !== 'ALL' ? `&status=${caseStatus}` : '';
    url += categoryId && categoryId !== 'ALL' ? `&categoryId=${categoryId}` : '';
    url += operationId ? `&operationId=${operationId}` : '';
    url += type && type !== 'ALL' ? `&type=${type}` : '';
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const listInstancesOfCase = async (caseId) => {
  const fn = async () => {
    const url = `/cases/${caseId}/instances`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getInstance = async (instanceId) => {
  const fn = async () => {
    const url = `/instances/${instanceId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const listCasesStats = async (status, period, type) => {
  const fn = async () => {
    let url = `/cases-stats?a=a`;
    url += status && status !== 'ALL' ? `&status=${status}` : '';
    url += period ? `&period=${period}` : ''; // TODAY, WEEK, MONTH
    url += type && type !== 'ALL' ? `&type=${type}` : ''; // TODAY, WEEK, MONTH
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getCase = async (caseId) => {
  const fn = async () => {
    const url = `/cases/${caseId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const countCases = async (status, date) => {
  const fn = async () => {
    const url = `/countCases`;
    const data = {};
    data.status = status ? `${status}` : ''; // OPENED, CREATED, COMPLETED
    data.date = date ? `${date}` : '';
    const response = await httpRequest(url, 'POST', data, true);
    return response.data;
  };

  return wrap(fn);
};

const listCasesLogs = async (caseId, field = null) => {
  const fn = async () => {
    let url = `/cases/${caseId}/logs?a=a`;
    url += field ? `&field=${field}` : '';
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const calculateStatusLogsTime = async (type) => {
  const fn = async () => {
    let url = `/cases/calculateStatusLogsTime?a=a`;
    url += type && type !== 'ALL' ? `&type=${type}` : '';
    const response = await httpRequest(url, 'POST', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const listResolutionTimes = async (period = 'WEEK', type) => {
  const fn = async () => {
    let url = `/cases/resolution-times?a=a`;
    url += type && type !== 'ALL' ? `&type=${type}` : '';
    url += period ? `&period=${period}` : ''; // TODAY, WEEK, MONTH
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const listCasesTypes = async () => {
  const fn = async () => {
    const url = `/cases-types`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getCaseType = async (typeId) => {
  const fn = async () => {
    const url = `/cases-types/${typeId}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const listCasesStates = async (type) => {
  const fn = async () => {
    let url = `/cases-states?a=a`;
    url += type && type !== 'ALL' ? `&type=${type}` : '';
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getCaseState = async (stateId) => {
  const fn = async () => {
    const url = `/cases-states/${stateId}/`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getCaseNotes = async (caseId) => {
  const fn = async () => {
    const url = `/cases-notes/${caseId}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getCaseStates = async (caseType) => {
  const fn = async () => {
    const url = `/cases-types/${caseType}/states`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const putCaseNotes = async (caseId, payload) => {
  const fn = async () => {
    const url = `/cases-notes/${caseId}`;
    const response = await httpRequest(url, 'PUT', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const createCase = async (payload) => {
  const fn = async () => {
    const url = `/general-cases`;
    const response = await httpRequest(url, 'POST', payload, false);
    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export {
  listCases,
  listInstancesOfCase,
  getInstance,
  listCasesStats,
  getCase,
  countCases,
  listCasesLogs,
  calculateStatusLogsTime,
  listResolutionTimes,
  listCasesTypes,
  getCaseType,
  listCasesStates,
  getCaseState,
  getCaseNotes,
  putCaseNotes,
  getCaseStates,
  createCase,
};
