/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';

import Moment from 'moment';
import PropTypes from 'prop-types';

import { TreeTable, Column } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import useWindowSize from '../../hooks/useWindowSize';
import { casesDashboardService } from '../../services';
import './ViewCaseStatusHistoryForm.css';

const ViewCaseForm = ({ data }) => {
  const info = useWindowSize();
  const { showSnackbar } = useContext(AccountContext);
  const [logs, setLogs] = useState([]);
  const tab = '\u00A0';
  const totalTime = data.closingDate
    ? Moment.utc(Moment(data.closingDate).diff(Moment(data.creationDate))).format(
        'DD [days] HH [hours] mm [minutes]'
      )
    : Moment.utc(Moment().diff(Moment(data.creationDate))).format(
        'DD [days] HH [hours] mm [minutes]'
      );

  const fetchLogs = async () => {
    const logsResult = await casesDashboardService.listCasesLogs(data.id, 'state');
    let i = 0;
    const results = [];

    if (logsResult.success) {
      for (i = 0; i < logsResult.data.length; i += 1) {
        results[i] = {};
        results[i].key = i + 1;
        results[i].data = logsResult.data[i];
      }
    } else {
      showSnackbar('error', 'Error to fetch instances', '');
    }

    for (i = 0; i < results.length - 1; i += 1) {
      const duration = Moment.duration(
        Moment(results[i + 1].data.date).diff(Moment(results[i].data.date))
      ).humanize();
      results[i].data.timeSpent = duration;
    }
    setLogs(results);
  };

  useEffect(async () => {
    fetchLogs();
  }, []);

  return (
    <form>
      <section>
        <div className="flex app-formGroup">
          <label>Case Id</label>
          {data.id}
        </div>
        <div className="flex app-formGroup">
          <label>Operation Id</label>
          {data.operation.id}
        </div>
        <div className="flex app-formGroup">
          <label>Total Time</label>
          {tab}
          {totalTime}
        </div>
      </section>
      <section className="app-form-section">
        <div className="card">
          <TreeTable
            value={logs}
            resizableColumns
            emptyMessage="No logs found."
            rows={30}
            fontSize="10pt"
            paginator
            scrollHeight={`${info.height * 0.2}px`}
          >
            <Column
              field="newValue"
              header="State"
              headerStyle={{
                width: '10rem',
                backgroundColor: '#22384D',
                color: 'white',
                textAlign: 'center',
              }}
              style={{
                fontSize: '13px',
                textAlign: 'center',
              }}
            />
            <Column
              field="date"
              header="Start Date"
              headerStyle={{
                width: '9rem',
                backgroundColor: '#22384D',
                color: 'white',
                textAlign: 'center',
              }}
              style={{
                fontSize: '13px',
                textAlign: 'center',
              }}
            />
            <Column
              field="timeSpent"
              header="Time Spent"
              headerStyle={{
                width: '10rem',
                backgroundColor: '#22384D',
                color: 'white',
                textAlign: 'center',
              }}
              style={{
                fontSize: '13px',
                textAlign: 'center',
              }}
            />
          </TreeTable>
        </div>
      </section>
    </form>
  );
};

ViewCaseForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ViewCaseForm;
