/* eslint-disable import/named */
import React from 'react';

import PropTypes from 'prop-types';

import './ExternalLayout.css';

const ExternalLayout = ({ children }) => {
  return (
    <div className="root">
      <div className="app-container">
        <div className="app-content">
          <div className="body">
            <div className="app-wrapper">
              <section className="page-container">{children}</section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExternalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalLayout;
