/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Button,
  // ChartWrapper,
  SubscribersInfo,
  StaffOverview,
  CounterDisplay,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
// import utils from '../../utils/utils';

import './AccountBilling.css';

const AccountBilling = () => {
  const history = useHistory();
  const { accountInfo, handleTask } = useContext(AccountContext);
  const { userData, workspaceData } = accountInfo;
  // eslint-disable-next-line no-unused-vars
  // const { dataSets } = utils;
  // // eslint-disable-next-line no-unused-vars
  // const [dashboardCharts, setDashboardCharts] = useState(utils.dashboardCharts);

  useEffect(() => {
    const handleUserValidation = () => {
      const validUser = userData?.role === 'ADMIN';
      if (!validUser) {
        history.push('/401');
      }
    };
    handleUserValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="account-billing-wrapper">
      <div className="account-billing-container">
        <div className="account-billing-general">
          <div className="account-billing-counters">
            <CounterDisplay
              tag="Accounting Plan"
              value={workspaceData?.data?.plan?.name.toString()}
            />
            <CounterDisplay
              tag="Avaliable Points"
              value={workspaceData?.data?.points_balance?.toString()}
            />
            <CounterDisplay
              tag="Next Billing Cycle"
              value={workspaceData?.data?.nextBillingCycle.toString()}
            />
          </div>
          <div className="account-billing-actions">
            <Button className="button actions" label="Add points" onClick={handleTask} />
            <Button className="button actions" label="Upgrade" onClick={handleTask} />
          </div>
        </div>
        <div className="in-progress">IN PROGRESS</div>
        {/* <div className="account-billing-accounts">
          <div className="account-billing-subscribers">
            <SubscribersInfo />
          </div>
          <div className="account-billing-staff">
            <StaffOverview />
          </div>
        </div> */}
        {/* <div className="account-billing-charts">
<ChartWrapper chartList={[dashboardCharts[0], dashboardCharts[1]]} dataSets={dataSets} />
        </div> */}
      </div>
    </div>
  );
};

export default AccountBilling;
