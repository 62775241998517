/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import { Button } from '../../../components';
import './SignaturePad.css';

const SignaturePad = ({ events, handleClose }) => {
  const sigCanvas = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const [isSigned, setIsSigned] = useState(false);
  const [stylingCls, setstylingCls] = useState(events.data.styling);

  const clear = () => {
    console.log('Empty', sigCanvas?.current?.isEmpty());
    setImageURL(null);
    sigCanvas?.current?.clear();
  };

  const check = () => {
    console.log('sigCanvas?.current?.isEmpty()', sigCanvas?.current?.isEmpty());
    if (sigCanvas.current.isEmpty()) {
      console.log('Por favor, firme antes de guardar.');
      return;
    }
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setImageURL(dataUrl);
  };

  const save = () => {
    handleClose();
    console.log('Wipp', events);
    events?.source.window.postMessage({ isSignature: true, file: imageURL }, '*');
  };
  return (
    <div className="signature-container" style={{ background: stylingCls.bg }}>
      {!imageURL && (
        <>
          <p className="text-intro-sign">{stylingCls.intro}</p>
          <SignatureCanvas
            ref={sigCanvas}
            penColor={stylingCls.penColor}
            canvasProps={{
              className: stylingCls.cls,
            }}
            style={{ borderColor: stylingCls.padColor }}
            onEnd={() => {
              setIsSigned(true);
            }}
          />
        </>
      )}
      {imageURL && (
        <div className="image-preview">
          <p className="text-intro-sign">Confirmo la firma:</p>
          <img className="image-sign" src={imageURL} alt="Confirmo la firma" />
        </div>
      )}
      <div className="buttons">
        <Button
          className="secondary-btn"
          onClick={clear}
          style={{ background: stylingCls.secondaryColor, borderColor: stylingCls.padColor }}
        >
          Clear
        </Button>
        {!imageURL && (
          <Button
            disabled={!isSigned}
            className="primary-btn"
            style={{ background: stylingCls.primaryColor }}
            onClick={check}
          >
            Check
          </Button>
        )}
        {imageURL && (
          <Button
            className="primary-btn"
            style={{ background: `${stylingCls.primaryColor} !important;` }}
            onClick={save}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default SignaturePad;
