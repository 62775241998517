/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import engineIcon from '../../assets/images/engine.png';
import {
  Button,
  InputSwitch,
  InputText,
  InputTextarea,
  UsersAndEngineDrag,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { workSpaceService } from '../../services';
import regexs from '../../utils/regexs';
import isEmpty from '../../utils/validators';
import './EngineForm.css';

const EngineForm = ({ data, handleSubmit }) => {
  const { accountInfo } = useContext(AccountContext);
  const { workspaceData } = accountInfo;
  const [dirty, setDirty] = useState(false);
  const [assignedList, setAssignedList] = useState([]);
  const [availabledList, setAvailableList] = useState([]);
  const [currentUsers, setCurrentUsers] = useState(
    data.users ? data.users.map((item) => item.id) : []
  );

  const nameInput = useInput('name', data.name || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const descriptionInput = useInput('description', data.description || '', [
    {
      validatorName: 'description-required',
      regex: regexs.required,
    },
  ]);

  const isActiveInput = useInput('isActive', data.isActive || true, [
    {
      validatorName: 'isActive-required',
      regex: regexs.required,
    },
  ]);

  const newData = {
    id: data.id || '',
    name: nameInput.value,
    description: descriptionInput.value,
    isActive: isActiveInput.value,
    users: assignedList,
    workspace: {
      id: workspaceData?.data?.id,
    },
  };

  const inputs = [nameInput, descriptionInput];

  const handleAssignedClick = (id) => {
    const index = assignedList.findIndex((item) => item.id === id);

    if (index !== -1) {
      assignedList[index].selected = !assignedList[index].selected;
      setAssignedList([...assignedList]);
    }
  };

  const handleAvailableClick = (id) => {
    const index = availabledList.findIndex((item) => item.id === id);

    if (index !== -1) {
      availabledList[index].selected = !availabledList[index].selected;
      setAvailableList([...availabledList]);
    }
  };

  const onAssigned = (e) => {
    e.preventDefault();

    availabledList.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        assignedList?.push(obj);
      }
    });
    const newAvailabled = availabledList.filter((item) => !item.selected);
    setAvailableList([...newAvailabled]);
    setAssignedList([...assignedList]);
  };

  const onAvailable = (e) => {
    e.preventDefault();

    assignedList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        availabledList?.push(obj);
      }
    });
    const newAssigned = assignedList.filter((item) => !item.selected);
    setAvailableList([...availabledList]);
    setAssignedList([...newAssigned]);
  };

  const loadAvailables = async () => {
    const {
      success,
      data: allUsersByWorkspaceData,
    } = await workSpaceService.getAllUsersByWorkspace(workspaceData?.data?.id);
    if (success) {
      setAvailableList(allUsersByWorkspaceData?.data);
    } else {
      setAvailableList([]);
    }
  };

  useEffect(async () => {
    if (data && data.users) setAssignedList(data.users);
    loadAvailables();
  }, []);

  useEffect(() => {
    let isDirty = true;
    let i = 0;
    if (isEmpty(data)) {
      isDirty = !nameInput.isValid;
    } else {
      let valueChanged = false;
      let item;

      if (isActiveInput.value !== data.isActive || isEmpty(data)) valueChanged = true;

      inputs.map((input) => {
        input.setIsEnabled(true);
        if (input.value !== data[input.inputName]) {
          if (input.value || data[input.inputName]) valueChanged = true;
        }
        return input;
      });

      const newUsers = [];
      for (i = assignedList.length - 1; i >= 0; i -= 1) {
        if (assignedList[i].id) {
          item = assignedList[i].id;
          if (newUsers.indexOf(item) === -1) {
            newUsers.push(item);
          }
        }
      }

      // Compare currentUsers and newUsers arrays
      if (
        currentUsers.length === newUsers.length &&
        currentUsers.every((v) => newUsers.indexOf(v) >= 0)
      ) {
        valueChanged = false;
      } else {
        valueChanged = true;
      }

      isDirty = !(
        (valueChanged && nameInput.isValid) ||
        (descriptionInput.isValid && isActiveInput.isValid)
      );
    }
    setDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nameInput.value,
    nameInput.isValid,
    descriptionInput.value,
    descriptionInput.isValid,
    isActiveInput.value,
    isActiveInput.isValid,
    assignedList,
  ]);

  return (
    <form onSubmit={(e) => handleSubmit(e, newData)}>
      <header className="flex-row engine-header">
        <img src={engineIcon} className="engine-header-img" alt="Engine Icon" />
      </header>
      <section className="engine-inputs-section">
        <div className="engine-formgroup">
          <label>Name:</label>
          <InputText value={nameInput.value} {...nameInput.bind} className="engine-inputform" />
          <InputSwitch
            checked={isActiveInput.value}
            onChange={(e) => isActiveInput.setValue(e.value)}
          />
        </div>
        <div className="engine-formgroup">
          <label>Description:</label>
          <InputTextarea
            value={descriptionInput.value}
            {...descriptionInput.bind}
            className="engine-inputform"
          />
        </div>
        <UsersAndEngineDrag
          assignedList={assignedList}
          availabledList={availabledList}
          handleAssignedClick={handleAssignedClick}
          handleAvailableClick={handleAvailableClick}
          onAssigned={onAssigned}
          onAvailable={onAvailable}
          formType="engineForm"
        />
        <div className="engine-form-button-section">
          <Button
            label={data && data.id ? 'Update' : 'Create'}
            className="engine-form-button"
            disabled={dirty}
          />
        </div>
      </section>
    </form>
  );
};

EngineForm.defaultProps = {
  data: {},
};

EngineForm.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

export default EngineForm;
