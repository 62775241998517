/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { MobilePDFReader } from 'reactjs-pdf-reader';

import BackIcon from '../../../assets/pngs/arrow-left.png';
import DownloadIcon from '../../../assets/pngs/download-icon.png';
// eslint-disable-next-line import/order
import { Button } from '../../../components';

import './FileDisplayer.css';

import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';

const FileDisplayer = ({ event, handleCloseDisplayerFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { isMobile } = useContext(ResponsiveContext);
  const [scaleNumber, setScaleNumber] = useState(isMobile ? 0.45 : 0.8);
  const { showSnackbar } = useContext(AccountContext);
  const handleDownloadFile = () => {
    try {
      const url = URL.createObjectURL(event?.file);
      const link = document.createElement('a');
      link.href = url;
      link.download = event.file.name;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      showSnackbar('error', '', 'Archivo con problemas.');
    }
  };

  const onDocumentLoadSuccess = (data) => {
    setNumPages(data);
  };

  const ShowContent = () => {
    try {
      const url = URL.createObjectURL(event?.file);
      switch (event?.file?.type || event?.file?.name.split('.')[1]) {
        case 'application/pdf':
        case 'pdf':
          return (
            <MobilePDFReader
              onDocumentComplete={onDocumentLoadSuccess}
              isShowHeader={false}
              isShowFooter
              page={pageNumber}
              scale={scaleNumber}
              url={url}
            />
          );
        case 'application/zip':
          return (
            <p className="txt-body">
              No podemos mostrar el archivo momentaneamente.
              <strong> Descargalo para poder verlo.</strong>
            </p>
          );
        case 'image/svg+xml':
        case 'png':
        case 'jpeg':
          return <img className="img-class" src={url} alt="content" />;

        default:
          return (
            <p className="txt-body">
              No podemos mostrar el archivo momentaneamente.
              <b> Descargalo para poder verlo.</b>
            </p>
          );
      }
    } catch (error) {
      return (
        <p className="txt-body">
          No podemos mostrar el archivo momentaneamente.
          <strong> Descargalo para poder verlo.</strong>
        </p>
      );
    }
  };

  return (
    <div className="file-wapper">
      <div className="headerFile">
        <Button
          title="Previous page"
          className="white-btn"
          onClick={() => handleCloseDisplayerFile()}
        >
          <img className="back-img" src={BackIcon} alt="back to the form" />
        </Button>
        <p className="titleFile">{event?.file?.name}</p>
        <div className="actions-file">
          {event?.isDownloadable && event?.file ? (
            <Button
              title="Download the file"
              className="white-btn"
              onClick={() => handleDownloadFile()}
            >
              <img className="download-img" src={DownloadIcon} alt="download file" />
            </Button>
          ) : (
            <span />
          )}
        </div>
      </div>
      <div className="contentFile">
        {event?.file ? (
          <div className={event?.stylescls || 'auto-class'}>
            <ShowContent />
          </div>
        ) : (
          'No resource'
        )}
      </div>
    </div>
  );
};

FileDisplayer.propTypes = {
  event: PropTypes.any.isRequired,
  handleCloseDisplayerFile: PropTypes.func.isRequired,
};

export default FileDisplayer;
