/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import workflowIcon from '../../assets/images/Workflow_4803.png';
import { EditAppCard, EventsTable, Dropdown, Button, TabView, TabPanel } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { flowsService, appDashboardService } from '../../services';

import './EditAppForm.css';

const EditAppForm = () => {
  const { showSnackbar, selectedApp, setSelectedFlow } = useContext(AccountContext);
  const { workflow, calendar: appCalendar, id } = selectedApp;
  const history = useHistory();
  const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState({});

  const loadFlows = async () => {
    const flowsResult = await flowsService.getAllFlows('1');

    if (flowsResult.success) {
      const appWorkflow = flowsResult.data.flows.find((w) => w.id === workflow?.id);
      if (appWorkflow) setSelectedWorkflow(appWorkflow);
    }

    setWorkflows(flowsResult.data.flows);
  };

  const loadCalendar = async () => {
    const calendarResult = await appDashboardService.getCalendars({ id: '1' });
    setCalendar(calendarResult.data.calendars);

    if (appCalendar) {
      const calendarSelected = calendarResult.data.calendars.find(
        (item) => item.id === appCalendar.id
      );
      setSelectedCalendar(calendarSelected || {});
    }
  };

  const updateApp = async (e, type) => {
    const app = {};
    if (type === 'workflow') {
      app.workflow = { id: e.value.id };
    } else if (type === 'calendar') {
      app.calendar = { id: e.value.id };
    } else {
      return;
    }
    const { success } = await appDashboardService.updateApp(id, app);

    if (success) {
      showSnackbar('success', 'Attention!', 'App updated succesfully');
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong updating the app! Please try again');
    }
  };

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
    updateApp(e, 'workflow');
  };

  const onFlowSelected = async () => {
    history.push({
      pathname: `/flows/flow?canEditFlow=${true}&&isNewFlow=false`,
      state: selectedWorkflow,
    });
    setSelectedFlow(selectedWorkflow);
    history.push(`/flows/flow?canEditFlow=${true}&&isNewFlow=false`);
  };

  const onCalendarChange = (e) => {
    setSelectedCalendar(e.value);
    updateApp(e, 'calendar');
  };

  useEffect(async () => {
    await loadFlows();
    await loadCalendar();
  }, []);

  return (
    <div className="edit-wrapper">
      <EditAppCard />
      <EventsTable />
      <section className="flex-column" style={{ gap: '10px' }}>
        <label>Workflow</label>
        <div className="flex-row" style={{ height: '35px', gap: '10px' }}>
          <Dropdown
            className="edit-dropdown"
            value={selectedWorkflow}
            options={workflows}
            onChange={onWorkflowChange}
            optionLabel="name"
            placeholder="Select one"
          />
          <Button className="events-action" onClick={onFlowSelected}>
            <img src={workflowIcon} className="events-img" alt="Workflow icon" />
          </Button>
        </div>
        <label>Calendar</label>
        <div className="flex-column" style={{ width: '170px' }}>
          <Dropdown
            value={selectedCalendar}
            options={calendar}
            onChange={onCalendarChange}
            optionLabel="name"
            placeholder="Select one"
          />
        </div>
      </section>
      {/* <section className="flex-row edit-app-formgroup">
        <div className="flex-column">
          <label>History</label>
          <Button className="events-action">
            <img src={historyIcon} className="events-img" alt="Workflow icon" />
          </Button>
        </div>
        <div className="flex-column">
          <label>Analytics</label>
          <Button className="events-action">
            <img src={statsIcon} className="events-img" alt="Workflow icon" />
          </Button>
        </div>
      </section> */}
    </div>
  );
};

export default EditAppForm;
