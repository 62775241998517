/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */

/* eslint-disable no-undef */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { Button } from '../BasicComponents';

import './GoBackComponent.css';

const GoBackComponent = ({ callback = '', title = '' }) => {
  const history = useHistory();
  const { setMenuCollapse } = useContext(AccountContext);
  const { isMobile } = useContext(ResponsiveContext);

  const handleBackAction = () => {
    setMenuCollapse(true);
    history.goBack();
  };

  const handleCallbackAction = () => {
    setMenuCollapse(true);
    history.push(callback);
  };

  return (
    <div className="action-container">
      <Button
        title="Previous page"
        label={title || 'Go back'}
        icon="pi pi-arrow-left "
        className="back"
        style={{ width: isMobile ? '100%' : '160px' }}
        onClick={callback !== '' ? handleCallbackAction : handleBackAction}
      />
    </div>
  );
};

GoBackComponent.propTypes = {
  callback: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default GoBackComponent;
