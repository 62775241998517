/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { Button, Card, InputSwitch } from '../BasicComponents';

import './EngineUserCard.css';

const EngineUserCard = ({
  name,
  id,
  statistics,
  active,
  icon,
  color,
  selected,
  onSelect,
  email,
  type,
  onEditButton,
}) => {
  const { isMobile } = useContext(ResponsiveContext);
  const [statusCheck, setStatusCheck] = useState(active);
  const [existingData, setExistingData] = useState(0);

  const filterData = () => {};

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleToogle = (e) => {
    handleStopPropagation(e);
    setStatusCheck((pre) => !pre);
  };

  const addData = () => {
    setExistingData((pre) => pre + 1);
  };

  const header = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <header
      className={`card-header-engine-users ${color}`}
      onClick={() => {
        onSelect(id);
      }}
    >
      {!isMobile ? icon : null}
      <h3 className="header-title">{name}</h3>
      <InputSwitch checked={statusCheck} onClick={(e) => handleToogle(e)} />
    </header>
  );

  const calcHeight = () => {
    return `${20 + 40 * existingData}px`;
  };

  useEffect(() => {
    if (email) {
      addData();
    }
    if (statistics.users) {
      addData();
    }
    if (statistics.engines) {
      addData();
    }
    if (statistics.humanGroups) {
      addData();
    }
    if (statistics.apps) {
      addData();
    }
    if (statistics.appsInstances) {
      addData();
    }
  }, []);

  return (
    <div
      className="card-container"
      onClick={() => {
        onSelect(id);
      }}
      style={{ minHeight: calcHeight() }}
    >
      <Card
        header={header}
        className={`engine-user-card ${selected ? 'selected' : ''}`}
        style={{ minHeight: calcHeight() }}
      >
        <div className="engine-user-content-wrapper">
          {type !== 'engine' ? <h4 className="data">Email: {email || ''}</h4> : <></>}
          {statistics.users ? <h4 className="data">Users: {statistics.users || ''} </h4> : <></>}
          {type !== 'engine' ? (
            <h4 className="data">Engines: {statistics.engines || ''}</h4>
          ) : (
            <></>
          )}
          {statistics.humanGroups ? (
            <h4 className="data">Groups of Humans: {statistics.humanGroups || ''}</h4>
          ) : (
            <></>
          )}
          {statistics.apps ? <h4 className="data">Apps: {statistics.apps || ''}</h4> : <></>}
          {statistics.appsInstances ? (
            <h4 className="data">Executions: {statistics.appsInstances || ''} </h4>
          ) : (
            <></>
          )}
        </div>
      </Card>
      <div className="engine-user-edit-section">
        <Button
          icon="pi pi-pencil"
          className="button editingBtn"
          onClick={(e) => onEditButton(e, id, type)}
        />
      </div>
    </div>
  );
};

EngineUserCard.defaultProps = {
  active: true,
  icon: null,
  email: null,
};

EngineUserCard.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  statistics: PropTypes.any.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.any,
  email: PropTypes.string,
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onEditButton: PropTypes.func.isRequired,
};

export default EngineUserCard;
