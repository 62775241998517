/* eslint-disable no-undef */
const utils = {
  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'September',
    'October',
    'November',
    'December',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  COLORS: [
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
  ],
  CHART_COLORS: {
    red: 'rgba(131, 65, 79, 0.5)',
    orange: 'rgba(222, 120, 18, 0.5)',
    yellow: 'rgba(255, 205, 86, 0.5)',
    green: 'rgba(31, 167, 167, 0.5)',
    blue: 'rgba(17, 142, 225, 0.5)',
    purple: 'rgba(153, 102, 255, 0.5)',
    grey: 'rgba(201, 203, 207, 0.5)',
  },
  CHART_BORDER_COLORS: {
    red: 'rgba(246, 5, 57, 0.5)',
    orange: 'rgba(222, 120, 18, 0.5)',
    yellow: 'rgba(255, 205, 86, 0.5)',
    green: 'rgba(31, 167, 167, 0.5)',
    blue: 'rgba(17, 142, 225, 0.5)',
    purple: 'rgba(153, 102, 255, 0.5)',
    grey: 'rgba(201, 203, 207, 0.5)',
  },
  dayHours: [
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 AM',
    '13 PM',
    '14 PM',
    '15 PM',
    '16 PM',
    '17 PM',
    '18 PM',
    '19 PM',
    '20 PM',
    '21 PM',
    '22 PM',
    '23 PM',
    '24 PM',
  ],
  monthsDays: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ],
  weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      data: [8, 7, 1, 2, 3, 6, 9, 8, 5, 6, 5, 4, 7, 8, 9, 6, 5, 4, 7, 5, 3, 4, 5, 9],
      id: 'hours',
    },
    {
      data: [15, 20, 25, 26, 28, 42, 32],
      id: 'weekDays',
    },
    {
      data: [
        50,
        20,
        25,
        26,
        28,
        42,
        32,
        63,
        42,
        32,
        50,
        20,
        25,
        26,
        28,
        42,
        32,
        63,
        42,
        32,
        50,
        20,
        25,
        26,
        28,
        42,
        32,
        63,
        42,
        32,
        45,
      ],
      id: 'monthdays',
    },
  ],
  tableMock: [
    {
      key: '0',
      data: {
        name: 'Applications',
        status: 'PENDING',
        date: ' 1-4-2021',
        address: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        city: 'LA',
        state: 'Florida',
      },
      children: [
        {
          key: '0-0',
          data: {
            name: 'Applications',
            status: 'STARTED',
            date: ' 1-4-2021',
            address: 'But I must explain to you how all this mistaken idea',
            city: 'LA',
            state: 'Florida',
          },
          children: [
            {
              key: '0-0-0',
              data: {
                name: 'Applications',
                status: 'WAITING',
                date: ' 1-4-2021',
                address: 'the great explorer of the truth, the master-builder',
                city: 'LA',
                state: 'Florida',
              },
            },
          ],
        },
        {
          key: '0-1',
          data: {
            name: 'Applications',
            status: 'ERROR',
            date: ' 1-4-2021',
            address: 'At vero eos et accusamus et iusto odio',
            city: 'LA',
            state: 'Florida',
          },
        },
        {
          key: '0-2',
          data: {
            name: 'Applications',
            status: 'PENDING',
            date: ' 1-4-2021',
            address: 'Sed ut perspiciatis unde omnis iste natus error',
            city: 'LA',
            state: 'Florida',
          },
        },
      ],
    },
    {
      key: '1',
      data: {
        name: 'Applications',
        status: 'PENDING',
        date: ' 1-4-2021',
        address: 'those who fail in their duty through weakness of will',
        city: 'LA',
        state: 'Florida',
      },
      children: [
        {
          key: '1-0',
          data: {
            name: 'Applications',
            status: 'PENDING',
            date: ' 1-4-2021',
            address: 'power of choice is untrammelled and when nothing',
            city: 'LA',
            state: 'Florida',
          },
          children: [
            {
              key: '1-0-0',
              data: {
                name: 'Applications',
                status: 'PENDING',
                date: ' 1-4-2021',
                address: 'Nam libero tempore, cum soluta nobis est',
                city: 'LA',
                state: 'Florida',
              },
            },
          ],
        },
        {
          key: '1-1',
          data: {
            name: 'Applications',
            status: 'PENDING',
            date: ' 1-4-2021',
            address: 'which of us ever undertakes laborious physical exercise',
            city: 'LA',
            state: 'Florida',
          },
        },
        {
          key: '1-2',
          data: {
            name: 'Applications',
            status: 'PENDING',
            date: ' 1-4-2021',
            address: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            city: 'LA',
            state: 'Florida',
          },
        },
      ],
    },
  ],
  appDashboardColors: ['color1', 'color2', 'color3', 'color4', 'color5', 'color6'],
  appDashboardColorsHex: ['#0098ff', '#5c5c5c', '#725ca4', '#8ac7bb', '#5ace39', '#b1d3d4'],
  getColor: (index, colors) => {
    return colors[index % (colors.length - 1)];
  },
  collectionData: [
    {
      title: 'General active Cases:',
      data: ['100', '$122,110'],
      lastMonth: ['7', '$8,534', '7.2%'],
      row: true,
    },
    {
      title: '0-30',
      data: ['61', '$50,110'],
      lastMonth: ['3', '$7850', '5%'],
      row: false,
    },
    {
      title: '31-60',
      data: ['30', '$23,240'],
      lastMonth: ['2', '$1,107', '2.1%'],
      row: false,
    },
    {
      title: '61-90',
      data: ['12', '$10,810'],
      lastMonth: ['1', '$646', '1.0%'],
      row: false,
    },
    {
      title: '91-',
      data: ['3', '$16,810'],
      lastMonth: ['1', '$6462', '1.0%'],
      row: false,
    },
    {
      title: 'avg time/customer:',
      data: ['12.1 days'],
      lastMonth: ['12.9'],
      row: true,
    },
  ],
  collectionFooter: [
    {
      title: '% recovery / $',
      value: '3.54%',
    },
    {
      title: 'Dif / avg-week',
      value: '-$2,110',
    },
    {
      title: 'Dif / avg-month',
      value: '-$122,110',
    },
    {
      title: 'Fif / avg-year',
      value: '-$250,110',
    },
  ],
  allowedAttributes: {
    p: ['class'],
    input: ['id', 'class', 'name', 'value'],
    h1: ['class'],
    label: ['id', 'class'],
    div: ['class', 'align'],
  },
  allowedTags: [
    'p',
    'label',
    'input',
    'div',
    'section',
    'title',
    'header',
    'footer',
    'span',
    'img',
    'h1',
    'h2',
    'h3',
    'ul',
    'ol',
    'li',
    'table',
    'th',
    'tbody',
    'td',
    'tr',
    'strong',
    'span',
    'br',
  ],
};
utils.chartSetConf = [
  {
    id: 1,
    title: 'Daily',
    data: [],
    labels: utils.dayHours,
    color: utils.CHART_COLORS.green,
    borderColor: utils.CHART_BORDER_COLORS.green,
  },
  {
    id: 2,
    title: 'Week',
    data: [],
    labels: utils.weekdays,
    color: utils.CHART_COLORS.blue,
    borderColor: utils.CHART_BORDER_COLORS.blue,
  },
  {
    id: 3,
    title: 'Month',
    data: [],
    labels: utils.monthsDays,
    color: utils.CHART_COLORS.red,
    borderColor: utils.CHART_BORDER_COLORS.red,
  },
  {
    id: 3,
    title: 'Quarter',
    data: [],
    labels: utils.monthsDays,
    color: utils.CHART_COLORS.orange,
    borderColor: utils.CHART_BORDER_COLORS.orange,
  },
  {
    id: 4,
    title: 'Search',
    data: [],
    labels: [],
    color: utils.CHART_COLORS.purple,
    borderColor: utils.CHART_BORDER_COLORS.purple,
  },
];

export default utils;

export const handleCopyClipboard = (data) => {
  navigator.clipboard.writeText(data);
};
