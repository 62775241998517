/* eslint-disable max-len */
/* eslint-disable new-cap */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Tickets from '../../assets/nav-icons/Ticket';
import InfoTicket from '../../assets/svgs/info.svg';
import SettingDetail from '../../containers/HumanInterface/SettingDetail/SettingDetail';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { ToolsContext } from '../../contexts/ToolsContext';
// eslint-disable-next-line import/named
import tools from '../../utils/time';
import { handleCopyClipboard } from '../../utils/utils';
import { Button, DataTable, Column } from '../BasicComponents';

const DEFAULT_FILTERS = {
  id: { value: null, matchMode: 'contains' },
  'operator.name': { value: null, matchMode: 'in' },
  'group.id': { value: null, matchMode: 'in' },
  status: { value: null, matchMode: 'in' },
  light_indicator: { value: null, matchMode: 'in' },
};

const AccordionItem = ({ index, id, title, titleMobile, amount, content, legend, size, type }) => {
  const history = useHistory();
  const {
    styleGuideByGroup,
    styleGuideByGroupHex,
    assigned,
    unassigned,
    groupIsOpen,
    setGroupIsOpen,
    teamSelected,
    memberSelected,
    setMemberSelected,
    lockCase,
    unlockCase,
    setSelectedTicket,
    setTeamSelected,
    resolveCase,
    casesByUser,
    magic,
    teamTicket,
  } = useContext(TicketsContext);
  const { accountInfo } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { showModal } = useContext(ToolsContext);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  // eslint-disable-next-line no-undef

  const ConditionColumn = ({ light_indicator, created_at, data, id: CaseId, status }) => {
    const difference = tools.timeSince(created_at?.seconds);
    const collectData = () => {
      const { initialValues } = data;
      const newData = {
        initialValues,
        exportedValues: {},
      };
      return JSON.stringify(newData);
    };

    const handleApprove = async () => {
      const isNext = true;
      const inputsForm = collectData();
      await resolveCase(CaseId, inputsForm, isNext);
      await casesByUser();
    };

    return (
      <div className="flex-rows">
        <div className={`dot2 ${light_indicator}`} title="Created since">
          <p className="dot-value">{difference}</p>
        </div>
        {status !== 'unassigned' && (
          <Button
            title="Ticket Info"
            className="approver"
            style={{ outline: 'none' }}
            onClick={(e) => {
              e.stopPropagation();
              handleApprove({ light_indicator, created_at, data, id: CaseId });
            }}
          >
            <span />
          </Button>
        )}
      </div>
    );
  };

  const ContentHero = ({
    contact,
    contact_name,
    operation,
    id: id2,
    group,
    description,
    creation,
    light_indicator,
    due_date,
    created_at,
  }) => (
    <div className={`details-case-hero ${styleGuideByGroup[group.id?.slice(-1)]}`}>
      <table className="details-case-table">
        <>
          <tr className="details-case-tr">
            <th className="details-case-th">Ticket ID</th>
            <td className="details-case-td">
              {id2}
              {isMobile && (
                <Button
                  title="Copy ID"
                  className="button-action-share"
                  style={{
                    color: styleGuideByGroupHex[group.id?.slice(-1)],
                  }}
                  icon="pi pi-share-alt"
                  onClick={() => handleCopyClipboard(id)}
                />
              )}
            </td>
          </tr>
          {/* <tr className="details-case-tr">
            <th className="details-case-th">Contact</th>
            <td className="details-case-td">{contact}</td>
          </tr> */}
          <tr className="details-case-tr">
            <th className="details-case-th">Case ID</th>
            <td className="details-case-td">{operation}</td>
          </tr>
          <tr className="details-case-tr">
            <th className="details-case-th">Notify ID</th>
            <td className="details-case-td">{contact_name}</td>
          </tr>
          <tr className="details-case-tr">
            <th className="details-case-th">Team ID</th>
            <td className="details-case-td">{group.id}</td>
          </tr>
          <tr className="details-case-tr">
            <th className="details-case-th">Description</th>
            <td className="details-case-td">{description}</td>
          </tr>
          <tr className="details-case-tr">
            <th className="details-case-th">Created At</th>
            <td className="details-case-td">
              {creation}
              <div className={`dot2 ${light_indicator}`} title="Created since">
                <p className="dot-value">{tools.timeSince(created_at?.seconds)}</p>
              </div>
            </td>
          </tr>
          <tr className="details-case-tr">
            <th className="details-case-th">Due date</th>
            <td className="details-case-td">{tools.timeSince(due_date?.seconds)}</td>
          </tr>
        </>
      </table>
    </div>
  );

  const ActionsColumn = ({
    status,
    id: idCase,
    operation,
    contact_name,
    group,
    description,
    creation,
    due_date,
    created_at,
    light_indicator,
    contact,
  }) => {
    return (
      <>
        {status !== 'unassigned' ? (
          <div className="ticket-actions">
            {/* <Button
              title="Ticket Info"
              className="assigned-arrow"
              style={{ outline: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                showModal(
                  <ContentHero
                    contact={contact}
                    contact_name={contact_name}
                    operation={operation}
                    id={idCase}
                    group={group}
                    description={description}
                    creation={creation}
                    due_date={due_date}
                    created_at={created_at}
                    light_indicator={light_indicator}
                  />,
                  <HeaderForm titleForm="Ticket Info" />
                );
              }}
            >
              <img className="assigned-arrow" src={InfoTicket} alt="" />
            </Button> */}
            <Button
              title="Unassign the ticket"
              className="unassigned-arrow"
              onClick={(e) => {
                e.stopPropagation();
                unlockCase(idCase);
              }}
            >
              Unclaim
            </Button>
          </div>
        ) : (
          <div className="ticket-actions">
            {/* <Button
              title="Ticket Info"
              className="assigned-arrow"
              style={{ outline: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                showModal(
                  <ContentHero
                    contact_name={contact_name}
                    operation={operation}
                    id={idCase}
                    group={group}
                    description={description}
                  />,
                  <HeaderForm titleForm="Ticket Info" />
                );
              }}
            >
              <img className="assigned-arrow" src={InfoTicket} alt="" />
            </Button> */}
            <Button
              title="Assign the ticket"
              className="unassigned-arrow"
              onClick={(e) => {
                e.stopPropagation();
                lockCase(idCase);
              }}
            >
              Claim
            </Button>
          </div>
        )}
      </>
    );
  };

  const CaseIdColumn = ({ id: idCase, operation }) => {
    return (
      <div className={`ticket-actions ${idCase === magic ? 'bounce' : ''}`}>
        {idCase === magic ? <p className="new">New</p> : null}
        {operation}
      </div>
    );
  };

  const handleEditCase = (caseData) => {
    setTools({
      ...acTools,
      manageModal: {
        header: <HeaderForm title="Edit the Ticket" />,
        content: <></>,
      },
      showModal: true,
    });
  };

  const StatusColumn = ({ status: codeStatus }) => {
    return <div className="statusChip">{codeStatus}</div>;
  };

  const selectColorTeam = (groupName) => {
    switch (groupName) {
      case 'MB-Aprobaciones':
        return 'blue-mb';
      case 'MB-Cumplimiento':
        return 'red-mb';
      default:
        return 'gray';
    }
  };

  const selectOpacityTeam = (groupName) => {
    const data = groupName.split('-');
    switch (data[1]) {
      case 'Clientes':
        return '1';
      case 'Cumplimiento':
        return '1';
      case 'Aprobaciones':
        return '1';
      case 'FirmaAgente':
        return '0.9';
      case 'FirmaCliente':
        return '0.9';
      default:
        return '1';
    }
  };

  const GroupColumn = ({ group }) => {
    return (
      <div
        style={{ opacity: selectOpacityTeam(group.name) }}
        className={`groupChip ${selectColorTeam(group.name)}`}
      >
        {group.name}
      </div>
    );
  };

  const handleNamePrefix2 = (userName) => {
    const [first, second] = userName?.split(' ');
    // eslint-disable-next-line prefer-const
    let namesPrefixed = [];
    if (!second) {
      namesPrefixed?.push(`${first[0]}${first[1]}`.toUpperCase());
    } else {
      namesPrefixed?.push(`${first[0]}${second[0]}`.toUpperCase());
    }
    return namesPrefixed;
  };

  const AvatarColumn = ({ operator, status: caseStatus }) => {
    return caseStatus === 'unassigned' ? (
      <Button className="history-avatar avatar-align-empty" title="Case unassigned">
        -
      </Button>
    ) : (
      <Button className="history-avatar avatar-align" title={operator.name}>
        {handleNamePrefix2(operator.name)}
      </Button>
    );
  };

  const handleToggle = (ind) => {
    setGroupIsOpen((pre) => {
      if (ind === 0) {
        return [!pre[0], pre[1]];
      }
      return [pre[0], !pre[1]];
    });
  };

  const onTicketSelect = (app) => {
    setSelectedTicket(app);
    history.push(`/tickets/${app.id}`);
  };

  const handleHeightByItem = () => {
    const assignedLen = assigned?.length;
    const pendingLen = unassigned?.length;
    let tempHeight;
    if (size === 1) {
      return {
        maxHeight: '65vh',
      };
    }
    if (isMobile) {
      switch (id === 1 ? pendingLen : assignedLen) {
        case 0:
          tempHeight = 81;
          break;
        case 1:
          tempHeight = 64;
          break;
        case 2:
          tempHeight = 55;
          break;
        case 3:
          tempHeight = 44;
          break;
        default:
          tempHeight = 38;
          break;
      }
    } else if (isTablet) {
      tempHeight = 75;
    } else {
      switch (id === 1 ? pendingLen : assignedLen) {
        case 0:
          tempHeight = 67;
          break;
        case 1:
          tempHeight = 54;
          break;
        case 2:
          tempHeight = 43;
          break;
        case 3:
          tempHeight = 41;
          break;
        default:
          tempHeight = 37;
          break;
      }
    }
    return {
      maxHeight: `${tempHeight}vh`,
    };
  };

  const cols = [
    { field: 'id', header: 'TicketId' },
    { field: 'light_indicator', header: 'Time Color' },
    { field: 'operation', header: 'Ticket' },
    { field: 'description', fieldValue: 'id', header: 'Description' },
    { field: 'status', header: 'Status' },
  ];

  const exportColumns = cols.map((col) => {
    return {
      title: col.header,
      dataKey: col.field,
    };
  });

  const exportPdf = (title2) => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, content);
        doc.save(`Tickets-${accountInfo?.userData?.name}-${title2}-${new Date()}.pdf`);
      });
    });
  };

  const show =
    !teamTicket?.toLowerCase().includes('mybasket') ||
    !teamTicket?.toLowerCase().includes('my-basket');

  const CaseCard = ({ cases }) => {
    return (
      <div className="modeler-table2">
        <DataTable
          emptyMessage="No tickets found."
          value={cases}
          globalFilterFields={['id', 'light_indicator', 'operator.name', 'group.name', 'status']}
          responsiveLayout="scroll"
          resizableColumns
          filters={filters}
          scrollable
          exportCSV
          selectionMode="single"
          onSelectionChange={(e) => onTicketSelect(e.value)}
        >
          {!show && (
            <Column
              field="id"
              filterField="id"
              header=""
              headerStyle={{
                maxWidth: '0px',
                width: '0px',
                textAlign: 'center',
                backgroundColor: '#F2F2F2',
                color: 'transparent',
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible',
                backgroundColor: 'transparent',
                color: 'transparent',
              }}
            />
          )}
          <Column
            field="light_indicator"
            filterField="light_indicator"
            header="Time"
            headerStyle={{
              maxWidth: '40px',
              width: '50px',
              textAlign: 'center',
              backgroundColor: '#F2F2F2',
              color: 'black',
            }}
            body={ConditionColumn}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
          />
          {!show && (
            <Column
              field="group.id"
              filterField="group.id"
              header="Actions"
              headerStyle={{
                width: '90px',
                minWidth: '80px',
                textAlign: 'center',
                backgroundColor: '#F2F2F2',
                color: 'black',
              }}
              body={ActionsColumn}
              bodyStyle={{
                textAlign: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            />
          )}
          {
            <Column
              field="operation"
              header="Ticket ID"
              headerStyle={{
                width: '130px',
                minWidth: '130px',
                maxWidth: '130px',
                textAlign: 'center',
                backgroundColor: '#F2F2F2',
                color: 'black',
              }}
              body={CaseIdColumn}
              bodyStyle={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
              }}
            />
          }
          {/* {!show && (
            <Column
              field="contact_name"
              header="Notify ID"
              headerStyle={{
                width: '80px',
                minWidth: '80px',
                maxWidth: '80px',
                textAlign: 'center',
                backgroundColor: '#F2F2F2',
                color: 'black',
              }}
              bodyStyle={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
              }}
            />
          )} */}
          <Column
            field="description"
            header="Description"
            headerStyle={{
              width: '200px',
              minWidth: '200px',
              maxWidth: '200px',
              textAlign: 'left',
              backgroundColor: '#F2F2F2',
              color: 'black',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />
          {!show && (
            <Column
              field="group.id"
              filterField="group.id"
              header="Team"
              headerStyle={{
                width: '160px',
                minWidth: '160px',
                maxWidth: '160px',
                textAlign: 'center',
                backgroundColor: '#F2F2F2',
                color: 'black',
              }}
              body={GroupColumn}
              bodyStyle={{
                textAlign: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              sortable
            />
          )}
        </DataTable>
      </div>
    );
    // return cases?.map((c, i) => {
    //   const {
    //     id: id2,
    //     operation,
    //     contact_name,
    //     contact,
    //     created_at,
    //     group,
    //     light_indicator,
    //     status,
    //     description,
    //     due_date,
    //   } = c;
    //const creation = created_at && tools.formatDate(new Date(created_at?.seconds * 1000));
    //   const dueDate = due_date && tools.formatDate(new Date(due_date?.seconds * 1000));
    //   const createdAt = new Date(created_at?.seconds * 1000);
    //   const difference = tools.timeSince(created_at.seconds);

    //   const customColorTeam = ({ name }) => {
    //     switch (name) {
    //       case 'Mi Banco':
    //         return '#275DA5';

    //       default:
    //         return 'gray';
    //     }
    //   };

    //   const CustomNameTeam = ({ name, showIcon = false }) => {
    //     switch (name) {
    //       case 'Mi Banco':
    //         return (
    //           <div>
    //             {showIcon ? (
    //               <img className="mb-nav" src={MBTicket} alt="mI banco icon" />
    //             ) : (
    //               <img className="mb-nav2" src={MBTicket} alt="mI banco icon" />
    //             )}
    //             {`${name}`}
    //           </div>
    //         );

    //       default:
    //         return <p className="team-name">{name}</p>;
    //     }
    //   };

    //   return (
    //     <Button
    //       id={c.id}
    //       key={`${contact_name}-${c.id}-${i}`}
    //       onClick={() => onTicketSelect(c)}
    //       className="bagde-wrapper"
    //       style={{
    //         backgroundColor: caseSelected === c ? customColorTeam(group) : null,
    //       }}
    //     >
    //       <div className={status !== 'unassigned' ? 'badge-container' : 'badge-container2'}>
    //         {status !== 'unassigned' ? (
    //           <div className="badge-izq" style={{ background: customColorTeam(group) || 'gray' }}>
    //             <Tickets className="bounce" title="Ticket" size={18} color="white" />
    //             <CustomNameTeam name={group.name} showIcon />
    //           </div>
    //         ) : (
    //           <div
    //             className={`badge-izq2 `}
    //             style={{ background: customColorTeam(group) || 'gray' }}
    //           >
    //             <CustomNameTeam name={group.name} />
    //           </div>
    //         )}
    //         <div className={status !== 'unassigned' ? 'badge-der' : 'badge-der2'}>
    //           <div className="badge-der-contact-wrapper">
    //             <div className="badge-der-contact-name" title="Ticket Operation">
    //               {contact}
    //             </div>
    //             <div className="badge-der-contact-op" title="Ticket Context">
    //               {description}
    //             </div>
    //           </div>
    //           <div className="badge-der-time-wrapper">
    //             <div title="Created at" className="badge-der-time-createdAt">
    //               {`${tools.formatDate(createdAt)}`}
    //             </div>
    //             {status !== 'unassigned' ? (
    //               <div className="ticket-actions">
    //                 <Button
    //                   title="Ticket Info"
    //                   className="approver"
    //                   style={{ outline: 'none' }}
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     handleApprove(c);
    //                   }}
    //                 >
    //                   <span />
    //                 </Button>
    //                 <Button
    //                   title="Ticket Info"
    //                   className="assigned-arrow"
    //                   style={{ outline: 'none' }}
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     showModal(<ContentHero />, <HeaderForm titleForm="Ticket Info" />);
    //                   }}
    //                 >
    //                   <img className="assigned-arrow" src={InfoTicket} alt="" />
    //                 </Button>
    //                 <Button
    //                   title="Unassign the ticket"
    //                   className="assigned-arrow"
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     unlockCase(c.id);
    //                   }}
    //                 >
    //                   <img className="assigned-arrow" src={DownUnassign} alt="" />
    //                 </Button>

    //                 <div
    //                   title="Created at"
    //                   className={`badge-der-time-assigned ${light_indicator}`}
    //                 >
    //                   {difference}
    //                 </div>
    //               </div>
    //             ) : (
    //               <div className="ticket-actions">
    //                 <Button
    //                   title="Ticket Info"
    //                   className="assigned-arrow"
    //                   style={{ outline: 'none' }}
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     showModal(<ContentHero />, <HeaderForm titleForm="Ticket Info" />);
    //                   }}
    //                 >
    //                   <img className="assigned-arrow" src={InfoTicket} alt="" />
    //                 </Button>
    //                 <Button
    //                   title="Assign the ticket"
    //                   className="unassigned-arrow"
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     lockCase(c.id);
    //                   }}
    //                 >
    //                   <img className="assigned-arrow" src={UpPending} alt="" />
    //                 </Button>
    //                 <div
    //                   title="Created at"
    //                   className={`badge-der-time-assigned ${light_indicator}`}
    //                 >
    //                   {difference}
    //                 </div>
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </Button>
    //   );
    // });
  };

  const GroupDetails = () => <SettingDetail type="team" data={teamSelected} />;

  const HumanDetailsCmp = () => <SettingDetail type="operators" data={memberSelected} />;

  const GroupCard = ({ groups }) => {
    const handleSelection = (g) => {
      if (type === 'teams') {
        setTeamSelected(g);
      }
      if (type === 'operators') {
        setMemberSelected(g);
      }
    };

    return groups?.map((g, i) => {
      if (type === 'teams') {
        const { id: ticketId, name: ticketName } = g;
        return (
          <Button
            id={ticketId}
            key={`${ticketName}-${ticketId}-${i}`}
            className={`bagde-wrapper-group ${
              styleGuideByGroup[ticketId?.slice(-1)] || styleGuideByGroup[0]
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleSelection(g);
              showModal(<GroupDetails data={g} />, <HeaderForm titleForm="Team Info" />);
            }}
            style={{
              minHeight: '30px',
              padding: '2px',
              maxWidth: '87vw',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                borderRadius: '18px',
              }}
              className={`bagde-container ${
                styleGuideByGroup[ticketId?.slice(-1)] || styleGuideByGroup[0]
              }`}
            >
              <div className="badge-der-contact-name" title="Team Name">
                {ticketName || g}
              </div>
            </div>
          </Button>
        );
      }
      if (type === 'operators') {
        const { id: ticketId, name: ticketName } = g;
        return (
          <Button
            id={ticketId}
            key={`${ticketName}-${ticketId}-${i}`}
            className={`bagde-wrapper-group `}
            onClick={(e) => {
              e.stopPropagation();
              handleSelection(g);
              showModal(<HumanDetailsCmp />, null);
            }}
            style={{
              minHeight: '30px',
              padding: '2px',
              maxWidth: '87vw',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                borderRadius: '18px',
              }}
              className="avatar-container"
            >
              <div className="badge-member" title="Member Name">
                {ticketName || g}
              </div>
            </div>
          </Button>
        );
      }
      return <></>;
    });
  };

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header">{titleForm}</div>;
  };

  const findGreenSatus = (cases) => {
    const greenOnes = cases.filter((c) => c?.light_indicator === 'green').length;
    return greenOnes || '0';
  };

  const findYellowSatus = (cases) => {
    const greenOnes = cases.filter((c) => c?.light_indicator === 'yellow').length;
    return greenOnes || '0';
  };

  const findRedSatus = (cases) => {
    const greenOnes = cases.filter((c) => c?.light_indicator === 'red').length;
    return greenOnes || '0';
  };

  const SwitchAccordion = ({ title3 }) => {
    switch (type) {
      case 'ticket':
        return (
          <div className="accordion-wrapper-item">
            {!show && (
              <Button
                onClick={() => handleToggle(index)}
                className="accordion-toggler"
                title={legend}
              >
                <div className="accordion-txt">
                  <i
                    className={`pi ${
                      !groupIsOpen[index] ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                  <p className="accordion-item">{!isMobile ? title3 : titleMobile}</p>
                </div>
                <div className="flex-row">
                  {+findRedSatus(content) ? (
                    <p title="alert" className="ticket-amount-btn red">
                      {`${findRedSatus(content)}`}
                    </p>
                  ) : null}
                  {+findYellowSatus(content) ? (
                    <p title="warning" className="ticket-amount-btn yellow">
                      {`${findYellowSatus(content)}`}
                    </p>
                  ) : (
                    <></>
                  )}
                  {+findGreenSatus(content) ? (
                    <p title="recent" className="ticket-amount-btn green">
                      {`${findGreenSatus(content)}`}
                    </p>
                  ) : (
                    <></>
                  )}
                  {amount ? (
                    <p title="Total" className="ticket-amount-btn">
                      {`${amount} ticket(s)`}
                    </p>
                  ) : (
                    <></>
                  )}
                  {/* {amount !== 0 ? (
                    <Button
                      title="Download"
                      type="button"
                      icon="pi pi-file-pdf"
                      className="pdf"
                      rounded
                      onClick={(e) => {
                        e.stopPropagation();
                        exportPdf(title3);
                      }}
                    />
                  ) : (
                    <></>
                  )} */}
                </div>
              </Button>
            )}
            {groupIsOpen[index] && (
              <div className="accordion-wrapper-list">
                <CaseCard cases={content} title2={title3} />
              </div>
            )}
          </div>
        );
      case 'operators':
        return (
          <div className="accordion-wrapper-item">
            <div className="accordion-row">
              <Button
                onClick={() => handleToggle(index)}
                className="accordion-toggler"
                title={legend}
              >
                <div className="accordion-txt">
                  <i
                    className={`pi ${
                      !groupIsOpen[index] || amount === 0 ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                  <p className="ticket-amount-btn">{`${amount} member(s)`}</p>
                </div>
                <div className="flex-row">
                  <div className="plus-icon">
                    <Button
                      className="add-ticket-btn"
                      icon="pi pi-plus"
                      onClick={() => {
                        const memberForm = <></>;
                        showModal(memberForm, <HeaderForm titleForm="Add new member" />);
                      }}
                    />
                  </div>
                </div>
              </Button>
            </div>
            {groupIsOpen[index] && (
              <div className="accordion-wrapper-list" style={handleHeightByItem()}>
                <GroupCard groups={content} />
              </div>
            )}
          </div>
        );
      case 'teams':
        return (
          <div className="accordion-wrapper-item">
            <div className="accordion-row">
              <Button
                onClick={() => handleToggle(index)}
                className="accordion-toggler"
                title={legend}
              >
                <div className="accordion-txt">
                  <i
                    className={`pi ${
                      !groupIsOpen[index] || amount === 0 ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                  <p className="ticket-amount-btn">{`${amount} teams(s)`}</p>
                </div>
                <div className="flex-row">
                  <div className="plus-icon">
                    <Button
                      className="add-ticket-btn"
                      icon="pi pi-plus"
                      onClick={() => {
                        const teamForm = <></>;
                        showModal(teamForm, <HeaderForm titleForm="Add new Team" />);
                      }}
                    />
                  </div>
                </div>
              </Button>
            </div>

            {groupIsOpen[index] && (
              <div className="accordion-wrapper-list" style={handleHeightByItem()}>
                <GroupCard groups={content} />
              </div>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };
  return <SwitchAccordion title3={title} />;
};

AccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  titleMobile: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  legend: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default AccordionItem;
