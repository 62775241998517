/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';

import PropTypes from 'prop-types';

import { Button, UsersAndEngineDrag, InputText } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { enginesService } from '../../services';
import regexs from '../../utils/regexs';
import isEmpty from '../../utils/validators';
import './UserForm.css';

const UserForm = ({ data, handleSubmit }) => {
  const { accountInfo } = useContext(AccountContext);
  const { workspaceData } = accountInfo;
  const [dirty, setDirty] = useState(false);
  // console.log(data.engines);
  const [assignedList, setAssignedList] = useState(data.engines || []);
  const [availabledList, setAvailableList] = useState([]);

  // TODO props arent mutable... check and fix
  // if (Array.isArray(data.enabledModules)) {
  //   data.modules = data.enabledModules.map((x) => x.id).toString();
  // }
  // if (!data.modules) {
  //   data.modules = 'DASHBOARD,APPS,EXECUTIONS,MODELER,HUMAN INTERFACE,COLLECTIONS';
  // }

  const nameInput = useInput('name', data.name || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const usernameInput = useInput('username', data.username || '', [
    {
      validatorName: 'username-required',
      regex: regexs.required,
    },
  ]);

  const passwordInput = useInput('password', data.password || '', [
    {
      validatorName: 'password-required',
      regex: regexs.password_8charslong,
    },
  ]);

  const emailInput = useInput('email', data.email || '', [
    {
      validatorName: 'email-required',
      regex: regexs.email,
    },
  ]);

  const phoneInput = useInput('phone', data.phone || '', [
    {
      validatorName: 'phone-required',
      regex: regexs.required,
    },
  ]);

  const modulesInput = useInput('modules', data.modules || '', [
    {
      validatorName: 'modules-required',
      regex: regexs.required,
    },
  ]);

  const newData = {
    id: data.id || '',
    name: nameInput.value,
    username: usernameInput.value,
    password: passwordInput.value,
    email: emailInput.value,
    phone: phoneInput.value,
    enabledModules: modulesInput.value,
    engines: assignedList,
  };

  const inputs = [nameInput, usernameInput, passwordInput, emailInput, phoneInput, modulesInput];

  const handleAssignedClick = (id) => {
    const index = assignedList.findIndex((item) => item.id === id);

    if (index !== -1) {
      assignedList[index].selected = !assignedList[index].selected;
      setAssignedList([...assignedList]);
    }
  };

  const handleAvailableClick = (id) => {
    const index = availabledList.findIndex((item) => item.id === id);

    if (index !== -1) {
      availabledList[index].selected = !availabledList[index].selected;
      setAvailableList([...availabledList]);
    }
  };

  const onAssigned = (e) => {
    e.preventDefault();

    availabledList.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        assignedList?.push(obj);
      }
    });
    const newAvailabled = availabledList?.filter((item) => !item.selected);
    setAvailableList([...newAvailabled]);
    setAssignedList([...assignedList]);
  };

  const onAvailable = (e) => {
    e.preventDefault();

    assignedList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        availabledList?.push(obj);
      }
    });
    const newAssigned = assignedList.filter((item) => !item.selected);
    setAvailableList([...availabledList]);
    setAssignedList([...newAssigned]);
  };

  const loadAvailables = async () => {
    const result = await enginesService.getEnginesByWorkspace(workspaceData.id);
    if (result.success) {
      setAvailableList(result.data);
    } else {
      setAvailableList([]);
    }
  };

  useEffect(() => {
    let isDirty = true;

    if (isEmpty(data)) {
      isDirty = data.id
        ? !(
            usernameInput.isValid &&
            emailInput.isValid &&
            phoneInput.isValid &&
            modulesInput.isValid
          )
        : !(
            usernameInput.isValid &&
            passwordInput.isValid &&
            emailInput.isValid &&
            phoneInput.isValid &&
            modulesInput.isValid
          );
    } else {
      let valueChanged = false;

      inputs.forEach((input) => {
        input.setIsEnabled(true);

        if (input.value !== data[input.inputName]) {
          valueChanged = true;
        }
      });
      isDirty = data.id
        ? !(
            valueChanged &&
            nameInput.isValid &&
            usernameInput.isValid &&
            emailInput.isValid &&
            phoneInput.isValid &&
            modulesInput.isValid
          )
        : !(
            valueChanged &&
            nameInput.isValid &&
            usernameInput.isValid &&
            emailInput.isValid &&
            passwordInput.isValid &&
            phoneInput.isValid &&
            modulesInput.isValid
          );
    }

    setDirty(isDirty);
  }, [
    nameInput.value,
    usernameInput.value,
    passwordInput.value,
    emailInput.value,
    phoneInput.value,
    modulesInput.value,
    nameInput.isValid,
    usernameInput.isValid,
    passwordInput.isValid,
    emailInput.isValid,
    phoneInput.isValid,
    modulesInput.isValid,
  ]);

  useEffect(async () => {
    loadAvailables();
  }, []);

  return (
    <form onSubmit={(e) => handleSubmit(e, newData)}>
      <section className="user-form-section">
        <div className="flex user-formGroup">
          <label>Name</label>
          <InputText
            value={nameInput.value}
            {...nameInput.bind}
            className={
              !nameInput.isValid && nameInput.wasTouched
                ? 'user-form-inputText input-required'
                : 'user-form-inputText'
            }
          />
        </div>
        <div className="flex user-formGroup">
          <label>Username</label>
          <InputText
            value={usernameInput.value}
            {...usernameInput.bind}
            className={
              !usernameInput.isValid && usernameInput.wasTouched
                ? 'user-form-inputText input-required'
                : 'user-form-inputText'
            }
          />
        </div>
        {!usernameInput.isValid && usernameInput.wasTouched ? (
          <small className="input-required-text user-input-required-text">
            This field is required.
          </small>
        ) : null}
        {!newData.id ? (
          <div className="flex user-formGroup">
            <label>Password</label>
            <InputText
              value={passwordInput.value}
              className={
                !passwordInput.isValid && passwordInput.wasTouched
                  ? 'user-form-inputText input-required'
                  : 'user-form-inputText'
              }
              {...passwordInput.bind}
            />
          </div>
        ) : null}

        {!passwordInput.isValid && passwordInput.wasTouched ? (
          <small className="input-required-text user-input-required-text">
            It requires at least 8 chars long.
          </small>
        ) : null}
        <div className="flex-row user-formGroup">
          <label>Email</label>
          <InputText
            value={emailInput.value}
            className={
              !emailInput.isValid && emailInput.wasTouched
                ? 'user-form-inputText input-required'
                : 'user-form-inputText'
            }
            {...emailInput.bind}
          />
        </div>
        {!emailInput.isValid && emailInput.wasTouched ? (
          <small className="input-required-text user-input-required-text">
            Please input a well formatted email
          </small>
        ) : null}
        <div className="flex-row user-formGroup">
          <label>Phone</label>
          <InputText
            value={phoneInput.value}
            className={
              !phoneInput.isValid && phoneInput.wasTouched
                ? 'user-form-inputText input-required'
                : 'user-form-inputText'
            }
            {...phoneInput.bind}
          />
        </div>
        {!phoneInput.isValid && phoneInput.wasTouched ? (
          <small className="input-required-text user-input-required-text">
            This field is required
          </small>
        ) : null}
        <div className="flex-row user-formGroup">
          <label>Modules</label>
          <InputText
            value={modulesInput.value}
            className={
              !modulesInput.isValid && modulesInput.wasTouched
                ? 'user-form-inputText input-required'
                : 'user-form-inputText'
            }
            {...modulesInput.bind}
          />
        </div>
        {!modulesInput.isValid && modulesInput.wasTouched ? (
          <small className="input-required-text user-input-required-text">
            This field is required
          </small>
        ) : null}
        <UsersAndEngineDrag
          assignedList={assignedList}
          availabledList={availabledList}
          handleAssignedClick={handleAssignedClick}
          handleAvailableClick={handleAvailableClick}
          onAssigned={onAssigned}
          onAvailable={onAvailable}
        />
        <div className="flex-row">
          <Button
            className="user-form-button"
            label={data.id ? 'Update' : 'Create'}
            disabled={dirty}
          />
        </div>
      </section>
    </form>
  );
};

UserForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UserForm;
