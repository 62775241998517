/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, InputText } from '../../components';
import { useInput } from '../../hooks';
import regexs from '../../utils/regexs';
import isEmpty from '../../utils/validators';
import './CreateAppForm.css';

const CreateAppForm = ({ data, handleSubmit }) => {
  const [dirty, setDirty] = useState(false);

  const appnameInput = useInput('name', data.name || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const passwordInput = useInput('password', data.password || '', [
    {
      validatorName: 'password-required',
      regex: regexs.password_8charslong,
    },
  ]);

  const emailInput = useInput('email', data.email || '', [
    {
      validatorName: 'email-required',
      regex: regexs.email,
    },
  ]);

  const phoneInput = useInput('phone', data.phone || '', [
    {
      validatorName: 'phone-required',
      regex: regexs.required,
    },
  ]);

  const newData = {
    id: data.id || '',
    name: appnameInput.value,
  };

  const inputs = [appnameInput, passwordInput, emailInput, phoneInput];

  useEffect(() => {
    let isDirty = true;

    if (isEmpty(data)) {
      isDirty = data.id
        ? !(appnameInput.isValid && emailInput.isValid && phoneInput.isValid)
        : !appnameInput.isValid;
    } else {
      let valueChanged = false;

      inputs.forEach((input) => {
        input.setIsEnabled(true);

        if (input.value !== data[input.inputName]) {
          valueChanged = true;
        }
      });
      isDirty = data.id
        ? !(valueChanged && appnameInput.isValid && emailInput.isValid && phoneInput.isValid)
        : !(valueChanged && appnameInput.isValid);
    }

    setDirty(isDirty);
  }, [appnameInput.value, appnameInput.isValid]);

  useEffect(async () => {
    // loadAvailables();
  }, []);

  return (
    <form onSubmit={(e) => handleSubmit(e, newData)}>
      <section className="app-form-section">
        <div className="flex app-formGroup">
          <label>Name:</label>
          <InputText
            value={appnameInput.value}
            {...appnameInput.bind}
            className={
              !appnameInput.isValid && appnameInput.wasTouched
                ? 'app-form-inputText input-required'
                : 'app-form-inputText'
            }
          />
        </div>
        {!appnameInput.isValid && appnameInput.wasTouched ? (
          <small className="input-required-text app-input-required-text">
            This field is required.
          </small>
        ) : null}

        <div className="flex-row">
          <Button
            className="app-form-button"
            label={data.id ? 'Update' : 'Create'}
            disabled={dirty}
          />
        </div>
      </section>
    </form>
  );
};

CreateAppForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CreateAppForm;
