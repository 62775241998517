/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { DataTable, Column, Avatar } from '../../../../components';
import { AccountContext } from '../../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';
import useWindowSize from '../../../../hooks/useWindowSize';

import './Details.css';

const DASH = '-';

const Details = ({
  typesInfo,
  caseSelectedInfo,
  selectedType,
  setSelectedType,
  activeFilter,
  logs,
}) => {
  const info = useWindowSize();
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { ColorTemplatesHex, setTools, tools } = useContext(AccountContext);

  const getClosedCases = () => {
    const DEFAULT_FILTERS = [
      { name: 'Today', value: 1 },
      { name: 'Last 7 days', value: 2 },
      { name: 'Last 30 days', value: 3 },
      { name: 'Last 90 days', value: 4 },
      { name: 'Search', value: 5 },
    ];
    let value;
    switch (activeFilter) {
      case 1:
        value = 1;
        break;
      case 2:
        value = 7;
        break;
      case 3:
        value = 30;
        break;
      case 4:
        value = 90;
        break;
      default:
        value = 1;
        break;
    }
    return { name: DEFAULT_FILTERS[activeFilter - 1].name, value };
  };

  const closedCases = getClosedCases();

  const OverallComp = () => (
    <div className="overAll-container">
      <div className="overAll-details">
        <div className="overAll-details-row">
          <p className="overAll-details-title2">Cases Types</p>
          <div className="overAll-details-types">
            <div className="overAll-details-scroller">
              {[...typesInfo].map((c, i) => (
                <p
                  key={`${c.id + i}`}
                  className="overAll-details-chip"
                  style={{
                    backgroundColor: `${ColorTemplatesHex[i % 5]}`,
                  }}
                  onClick={() => {
                    setSelectedType(i + 1);
                  }}
                >
                  {c?.id}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="overAll-details-row">
          <p className="overAll-details-title2">Avg Time Resolution per Day (hr)</p>
          <div className="overAll-details-avg">{caseSelectedInfo?.avgResolutionTime}</div>
        </div>
        <div className="overAll-details-row">
          <p className="overAll-details-title2">Avg Cases Resolution per Day</p>
          <div className="overAll-details-avg">{caseSelectedInfo?.avgResolutionCases}</div>
        </div>
        {caseSelectedInfo?.lastCloseCases && (
          <div className="overAll-details-row">
            <p className="overAll-details-title2">{`${closedCases?.name || ''} closed cases`}</p>
            <div className="overAll-details-avg">
              {activeFilter !== 5
                ? caseSelectedInfo?.lastCloseCases[closedCases?.value || 0] || DASH
                : DASH}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const DataTableLogs = () => (
    <DataTable
      value={logs}
      resizableColumns
      emptyMessage="No logs found."
      fontSize="10pt"
      selectionMode="single"
      scrollable
      scrollHeight={`${info.height * 0.8}px`}
      rows={10}
      paginator
    >
      <Column
        field="state"
        header="State"
        headerStyle={{
          backgroundColor: '#22384D',
          color: 'white',
          textAlign: 'center',
          width: '120px',
        }}
        style={{ fontSize: '13px', textAlign: 'center' }}
      />
      <Column
        field="averageTime"
        header="Avg Time"
        headerStyle={{
          backgroundColor: '#22384D',
          color: 'white',
          textAlign: 'center',
          width: '120px',
        }}
        style={{ fontSize: '13px', textAlign: 'center' }}
      />
      <Column
        field="minimumTime"
        header="Min Time"
        headerStyle={{
          backgroundColor: '#22384D',
          color: 'white',
          textAlign: 'center',
          width: '120px',
        }}
        style={{ fontSize: '13px', textAlign: 'center', width: '100px' }}
      />
      <Column
        field="maximumTime"
        header="Max Time"
        headerStyle={{
          backgroundColor: '#22384D',
          color: 'white',
          textAlign: 'center',
          width: '120px',
        }}
        style={{ fontSize: '13px', textAlign: 'center' }}
      />
      <Column
        field="topHuman"
        header="Top Human"
        headerStyle={{
          backgroundColor: '#22384D',
          color: 'white',
          textAlign: 'center',
          width: '120px',
        }}
        style={{ fontSize: '13px', textAlign: 'center' }}
      />
      <Column
        field="lowHuman"
        header="Low Human"
        headerStyle={{
          backgroundColor: '#22384D',
          color: 'white',
          textAlign: 'center',
          width: '120px',
        }}
        style={{ fontSize: '13px', textAlign: 'center' }}
      />
    </DataTable>
  );

  const HeaderForm = ({ title, iconName = '' }) => {
    return (
      <div className="activity-header">
        <Avatar
          icon={`pi ${iconName}`}
          className="p-mr-2  subs-table-icons"
          style={{ backgroundColor: '#6fabf3', color: '#ffffff' }}
          size="small"
          shape="circle"
        >
          {title.slice(0, 1)}
        </Avatar>
        {`Case ${title}`}
      </div>
    );
  };

  const showLogsStates = () => {
    setTools({
      ...tools,
      manageModal: {
        header: <HeaderForm title={typesInfo[selectedType - 1].id} />,
        content: <DataTableLogs />,
      },
      showModal: true,
    });
  };

  const CaseComp = () => (
    <div className="case-container">
      <div className="overAll-details">
        <div className="case-wrapper">
          <div className="overAll-details-row2">
            <p className="overAll-details-title2">Case Type</p>
            <div className="overAll-details-types">
              <p
                className="overAll-details-chip"
                style={{
                  backgroundColor: `${ColorTemplatesHex[(selectedType - 1) % 5]}`,
                }}
                onClick={() => {
                  showLogsStates();
                }}
              >
                {typesInfo[selectedType - 1]?.id || DASH}
              </p>
            </div>
          </div>
          <div className="overAll-details-row2">
            <p className="overAll-details-title2">Description</p>
            <p className="case-description">{caseSelectedInfo?.description} </p>
          </div>
          <div className="overAll-details-row2">
            <p className="overAll-details-title2">Avg Case Resolution per Day</p>
            <div className="overAll-details-avg">{caseSelectedInfo?.avgResolutionCases}</div>
          </div>
          <div className="overAll-details-row2">
            <p className="overAll-details-title2">Avg Time Resolution per Day (h)</p>
            <div className="overAll-details-avg">{caseSelectedInfo?.avgResolutionTime}</div>
          </div>
          <div className="overAll-details-row2">
            <p className="overAll-details-title2">{`${closedCases.name} closed cases`}</p>
            <div className="overAll-details-avg">
              {activeFilter !== 5 ? caseSelectedInfo?.lastCloseCases[closedCases.value] : DASH}
            </div>
          </div>
        </div>
        <div className="overAll-details-row3">
          <div className="case-sub-data" title="Active cases">
            <p className="case-sub-title">Active</p>
            <div className="case-sub-value">{caseSelectedInfo?.activeCases}</div>
          </div>
          <div className="case-sub-data" title="Instances running">
            <p className="case-sub-title">Running</p>
            <div className="case-sub-value">{caseSelectedInfo?.instancesRunning}</div>
          </div>
          <div className="case-sub-data" title="Percentage active cases">
            <p className="case-sub-title"> Percentaje</p>
            <div className="case-sub-value">{caseSelectedInfo?.percentageActiveCases}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="section-container">
      <h1
        className="titles"
        style={{
          marginTop: isMobile || isTablet ? '10px' : '',
        }}
      >
        {selectedType ? 'Case Information' : 'Global Information'}
      </h1>
      {selectedType ? <CaseComp /> : <OverallComp />}
    </div>
  );
};

Details.propTypes = {
  typesInfo: PropTypes.array.isRequired,
  caseSelectedInfo: PropTypes.object.isRequired,
  selectedType: PropTypes.number.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  activeFilter: PropTypes.number.isRequired,
  logs: PropTypes.array.isRequired,
};

export default Details;
