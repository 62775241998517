/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { Button, Calendar, SelectButton } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';

const DEFAULT_FILTERS = [
  { name: 'Last 30 days', value: 3 },
  { name: 'Last 90 days', value: 4 },
  { name: 'Last 7 days', value: 2 },
  { name: 'Today', value: 1 },
  { name: 'Search', value: 5 },
];

const DashboardFilters = ({
  instances,
  activeCase,
  filter,
  setFilter,
  rangeDates,
  setRangeDates,
  selectedType,
  showHistory,
  disable,
}) => {
  const { menuCollapse, handleTask } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const [isActive, setIsActive] = useState(!isMobile);

  const filterTemplate = (option) => {
    return <p className="chipFilter">{option.name}</p>;
  };

  useEffect(() => {
    if (filter === 5) {
      document.getElementById('messages').scrollTo(1000, 0);
    }
  }, [filter]);

  const handleSearch = (value) => {
    setRangeDates(value);
    handleTask();
  };

  const handleToggle = () => {
    setIsActive((p) => !p);
  };

  return (
    <div className="wrapper-filters">
      <div
        id="global"
        style={{
          width: isMobile
            ? isTablet
              ? menuCollapse
                ? '69vw'
                : '90vw'
              : menuCollapse
              ? '69vw'
              : '90vw'
            : menuCollapse
            ? '85vw'
            : '93vw',
        }}
      >
        <div id="messages">
          <Button onClick={() => handleToggle()} className="filter-toggler" title="Toogle filters">
            <i
              className={`pi ${
                isMobile && isTablet
                  ? isActive
                    ? 'pi-angle-left'
                    : 'pi-angle-right'
                  : isActive
                  ? 'pi-angle-right'
                  : 'pi-angle-left'
              } direction`}
            />
            <p className="case-name">{activeCase}</p>
          </Button>
          {isActive ? (
            <>
              {selectedType > 0 && instances.length > 0 ? (
                <div>
                  <Button disabled={disable} label="Activity" onClick={showHistory} />
                </div>
              ) : (
                <></>
              )}
              {selectedType > 0 ? <div className="separator lined">|</div> : <></>}
              <SelectButton
                value={filter}
                options={DEFAULT_FILTERS}
                onChange={(e) => setFilter(e.value === null ? 3 : e.value)}
                optionLabel="name"
                className={selectedType > 0 ? 'filters-activity' : 'filters'}
                itemTemplate={filterTemplate}
              />
              {filter === 5 ? (
                <Calendar
                  id="range"
                  className="range-picker"
                  placeholder="Select a range"
                  value={rangeDates}
                  onChange={(e) => handleSearch(e.value)}
                  selectionMode="range"
                  readOnlyInput
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

DashboardFilters.defaultProps = {
  activeCase: 'ALL',
};

DashboardFilters.propTypes = {
  instances: PropTypes.array.isRequired,
  activeCase: PropTypes.string,
  filter: PropTypes.number.isRequired,
  setFilter: PropTypes.func.isRequired,
  rangeDates: PropTypes.array.isRequired,
  setRangeDates: PropTypes.func.isRequired,
  selectedType: PropTypes.number.isRequired,
  showHistory: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
};

export default DashboardFilters;
