import React, { useEffect } from 'react';

import './NotFound.css';
import DeniedTicket from '../../assets/svgs/arts/undraw_deniedticket.svg';
import { deleteCookie } from '../../utils/tools';

function NotFound() {
  useEffect(() => {
    deleteCookie('credentials');
    return () => {};
  }, []);

  return (
    <div className="not-found-main">
      <div className="not-found-card bounce">
        <img src={DeniedTicket} className="not-found-card-img" alt="Ticket not found!" />
        <h2 className="not-found-header bounce">
          The request you are looking for is no longer available.
        </h2>
        <h2 className="not-found-header bounce">
          La solicitud que usted busca ya no está disponible.
        </h2>
      </div>
    </div>
  );
}

export default NotFound;
