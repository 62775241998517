/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import listEmptyActiveImage from '../../assets/svgs/arts/undraw_active.svg';
import listEmptyClosedImage from '../../assets/svgs/arts/undraw_closed.svg';
import listEmptyCreatedImage from '../../assets/svgs/arts/undraw_created.svg';
import listEmptyResolutionImage from '../../assets/svgs/arts/undraw_resolution.svg';
import utils from '../../utils/utils';
import './ChartLineContainer.css';
import Loader from '../Loader/Loader';

const { chartSetConf } = utils;
const ChartLineContainer = ({ dataSets, dateFilter, idType }) => {
  const [confiChart, setConfiChart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  // const handleSummatory = (dataArray) => {
  //   return dataArray?.reduce((a, b) => a + b, 0);
  // };

  const fillDataFromAPIs = () => {
    const result = dataSets?.map((set, index) => {
      return { ...chartSetConf[index], ...set };
    });
    if (result) {
      const chartToBeRendering = result[dateFilter];
      const lastResults = result[dateFilter]?.data[result[dateFilter]?.data.length - 1]?.toFixed(0);
      const infoReady = {
        labels: chartToBeRendering?.labels,
        datasets: [
          {
            label: `${lastResults || 0} ${idType !== 4 ? 'case(s)' : 'hour(s)'}`,
            data: chartToBeRendering?.data,
            borderColor: chartToBeRendering?.color,
            backgroundColor: chartToBeRendering?.borderColor,
          },
        ],
      };
      setConfiChart(infoReady);
    }
  };

  useEffect(() => {
    if (confiChart) {
      setLoading(false);
    }
  }, [confiChart]);

  useEffect(() => {
    fillDataFromAPIs();
  }, []);

  const filterImgEmpty = () => {
    switch (idType) {
      case 1:
        return {
          image: listEmptyCreatedImage,
          alternative: 'No new cases created',
          text: 'No new cases created!',
        };
      case 2:
        return {
          image: listEmptyActiveImage,
          alternative: 'No active cases',
          text: 'No active cases!',
        };
      case 3:
        return {
          image: listEmptyClosedImage,
          alternative: 'No closed cases',
          text: 'No closed cases!',
        };
      case 4:
        return {
          image: listEmptyResolutionImage,
          alternative: 'No hours',
          text: 'No hours!',
        };
      default:
        break;
    }
  };

  const ChartEmptyState = () => {
    const { image, alternative, text } = filterImgEmpty();
    return (
      <div className="table-chart-wrapper">
        <div className="detail-container">
          <img className="chart-img" src={image} alt={alternative} />
          <p className="chart-message ">{text}</p>
        </div>
      </div>
    );
  };

  return !hasData ? (
    <div className="dashboard-charts-wrapper">
      {!loading ? <Line data={confiChart} /> : <Loader fit />}
    </div>
  ) : (
    <ChartEmptyState />
  );
};

ChartLineContainer.propTypes = {
  dataSets: PropTypes.array.isRequired,
  dateFilter: PropTypes.number.isRequired,
  idType: PropTypes.number.isRequired,
};

export default ChartLineContainer;
