/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect } from 'react';

import { ImCogs } from 'react-icons/im';
import { useHistory } from 'react-router-dom';

import listEmptyEnginesImage from '../../assets/svgs/arts/undraw_engines.svg';
import listEmptyUsersImage from '../../assets/svgs/arts/undraw_team.svg';
import {
  TabView,
  TabPanel,
  Button,
  Avatar,
  DataTable,
  Column,
  EngineUserCard,
  Loader,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { UserForm, UserToEngineForm, EngineForm } from '../../forms';
import useWindowSize from '../../hooks/useWindowSize';
import { usersService, enginesService, workSpaceService, adminInfoService } from '../../services';
import utils from '../../utils/utils';
import AccountBilling from '../AccountBilling/AccountBilling';
import './AdminInfo.css';

const styledColumn = {
  width: '170px',
  textAlign: 'left',
  backgroundColor: '#22384D',
  color: 'white',
};

const AdminInfo = ({ location }) => {
  const info = useWindowSize();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const isValidTab =
    location.hash === '#users' || location.hash === '#engines' || location.hash === '#billing';
  if (!isValidTab) {
    history.push('/404');
  }
  const tab = location.hash === '#users' ? 0 : location.hash === '#engines' ? 1 : 2;
  const [activeIndex, setActiveIndex] = useState(tab);
  const { accountInfo, tools, setTools, showSnackbar, handleTask, handleCloseModal } = useContext(
    AccountContext
  );
  const { userData, workspaceData } = accountInfo;
  const [usersByWorkspace, setUsersByWorkspace] = useState([]);
  const [enginesByWorksapace, setEnginesByWorkspace] = useState([]);
  // Selected user/engine
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEngine, setSelectedEngine] = useState(null);
  // User section
  const [appsByUser, setAppsByUser] = useState(null);
  const [enginesByUser, setEnginesByUser] = useState(null);
  // Engines section
  const [appsByEngine, setAppsByEngine] = useState(null);
  const [usersByEngine, setUsersByEngine] = useState(null);

  const getUsersByWorkspace = async () => {
    const { success, data } = await workSpaceService.getAllUsersByWorkspace(
      workspaceData?.data?.id
    );
    if (success && data) {
      setUsersByWorkspace(
        data?.data?.map((user) => ({
          ...user,
          icon: (
            <Avatar
              icon="pi pi-user"
              className="p-mr-2  subs-table-icons"
              style={{ backgroundColor: '#6fabf3', color: '#ffffff' }}
              shape="circle"
            />
          ),
        }))
      );
      setLoading(false);
    } else {
      return data;
    }
  };

  const getAppsbyUser = async (userId) => {
    const { success, data: appsbyUserData } = await adminInfoService.getAppsbyUser(userId);
    if (success) {
      setAppsByUser(appsbyUserData);
    } else {
      setAppsByUser(null);
      showSnackbar('error', 'Attention!', 'We could not get the apps. Please try again');
    }
  };

  const getEnginesByUser = async (userId) => {
    const { success, data: enginesByUserData } = await adminInfoService.getEngiesByUser(userId);
    if (success) {
      setEnginesByUser(enginesByUserData);
    } else {
      setEnginesByUser(null);
      showSnackbar('error', 'Attention!', 'We could not get the engines. Please try again');
    }
  };

  const getAppsByEngine = async (userId) => {
    const { success, data: appsByEngineData } = await adminInfoService.getAppsByEngine(userId);
    if (success) {
      setAppsByEngine(appsByEngineData);
    } else {
      setAppsByEngine(null);
      showSnackbar('error', 'Attention!', 'We could not get the apps. Please try again');
    }
  };

  const getUsersByEngine = async (userId) => {
    const { success, data: usersByEngineData } = await adminInfoService.getUsersByEngine(userId);
    if (success) {
      setUsersByEngine(usersByEngineData);
    } else {
      setUsersByEngine(null);
      showSnackbar('error', 'Attention!', 'We could not get the users. Please try again');
    }
  };

  const getEnginesByWorkspace = async () => {
    const { success, data: enginesByWorkspaceData } = await workSpaceService.getEnginesByWorkspace(
      workspaceData?.data?.id
    );
    if (success) {
      setEnginesByWorkspace(
        enginesByWorkspaceData.map((engine) => ({
          ...engine,
          icon: <ImCogs className="icon" />,
        }))
      );
      setLoading(false);
    } else {
      return enginesByWorkspaceData;
    }
  };

  const handleSelectedUser = async (id) => {
    const newSelectedUser = usersByWorkspace.find((user) => user.id === id);
    setSelectedUser(newSelectedUser);
    await getAppsbyUser(newSelectedUser?.id);
    await getEnginesByUser(newSelectedUser?.id);
  };

  const handleSelectedEngine = async (id) => {
    const newSelectedEngine = enginesByWorksapace.find((engine) => engine.id === id);
    setSelectedEngine(newSelectedEngine);
    await getAppsByEngine(newSelectedEngine?.id);
    await getUsersByEngine(newSelectedEngine?.id);
  };

  const handleSubmitAddUsersToEngine = async (e) => {
    e.preventDefault();
    const { success } = await enginesService.updateEngine(selectedEngine);
    if (success) {
      showSnackbar('success', 'Engine update!', 'Engine updated successfully');
      await getEnginesByWorkspace();
    } else {
      showSnackbar(
        'error',
        'Updating engine error!',
        'Something went wrong updating the engine! Please try again'
      );
    }
    handleCloseModal();
  };

  const handleSubmitAddUser = async (e, user) => {
    e.preventDefault();
    const newUser = { ...user, workspace: { id: workspaceData?.data?.id } };
    const { success } = await usersService.createUser(newUser);

    if (!success) {
      showSnackbar(
        'error',
        'Creating user error!',
        'Something went wrong creating the user! Please try again'
      );
    } else {
      showSnackbar('success', 'User creation', 'User created successfully');
    }
    await getUsersByWorkspace();
    handleCloseModal();
  };

  const handleUpdateUser = async (e, data) => {
    e.preventDefault();
    try {
      const { success } = await usersService.updateUser(data);
      if (success) {
        showSnackbar('success', 'User update', 'User updated successfully');
        await getUsersByWorkspace();
      } else {
        // showSnackbar(
        //   'error',
        //   'Updating user error!',
        //   'Something went wrong updating the user! Please try again'
        // );
      }
    } catch (error) {
      // showSnackbar(
      //   'error',
      //   'Updating user error!',
      //   'Something went wrong updating the user! Please try again'
      // );
    }
    handleCloseModal();
  };

  const handleSubmitAddEngine = async (e, data) => {
    e.preventDefault();
    const { success } = await enginesService.createEngine(data);
    if (!success) {
      // showSnackbar(
      //   'error',
      //   'Creating engine error!',
      //   'Something went wrong creating the engine! Please try again'
      // );
    } else {
      setTools({ ...tools, showModal: false });
      showSnackbar('success', 'Engine creation!', 'Engine created successfully');
    }
    await getEnginesByWorkspace();
  };

  const handleUpdateEngine = async (e, data) => {
    e.preventDefault();
    const { success } = await enginesService.updateEngine(data);

    if (success) {
      setTools({
        ...tools,
        showModal: false,
        selectedEditUser: null,
      });
      showSnackbar('success', 'Engine update', 'Engine updated successfully');
    } else {
      showSnackbar(
        'error',
        'Updating engine error!',
        'Something went wrong updating the engine! Please try again'
      );
    }
    await getEnginesByWorkspace();
  };

  const HeaderForm = ({ title, iconName = '' }) => {
    return (
      <div className="activity-header">
        <Avatar
          icon={`pi ${iconName}`}
          className="p-mr-2  subs-table-icons"
          style={{ backgroundColor: '#6fabf3', color: '#ffffff' }}
          size="small"
          shape="circle"
        />
        {title}
      </div>
    );
  };

  const showModal = (content, header) => {
    setTools({
      ...tools,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const onEditButton = async (e, id, type) => {
    e.stopPropagation();

    if (type !== 'engine') {
      const { success, data } = await usersService.getUser(id);
      if (success) {
        const userForm = <UserForm handleSubmit={handleUpdateUser} data={data} />;
        showModal(userForm, <HeaderForm title="Modify User" iconName="pi-user" />);
      }
    } else {
      const { success, data } = await enginesService.getEngine(id);
      if (success) {
        const engineForm = <EngineForm data={data} handleSubmit={handleUpdateEngine} />;
        showModal(engineForm, <HeaderForm title="Modify Engine" iconName="pi-user" />);
      }
    }
  };

  const handleChangeTab = (index) => {
    setActiveIndex(index);
    switch (index) {
      case 0:
        window.location.hash = '#users';
        break;
      case 1:
        window.location.hash = '#engines';
        break;
      case 2:
        window.location.hash = '#billing';
        break;
      default:
        window.location.hash = '#users';
        break;
    }
  };

  const forms = [
    <UserForm
      data={{
        workspace: workspaceData.data.id,
      }}
      handleSubmit={handleSubmitAddUser}
    />,
    <EngineForm handleSubmit={handleSubmitAddEngine} />,
    <UserToEngineForm handleSubmit={handleSubmitAddUsersToEngine} data={selectedEngine} />,
  ];

  const appsEnginesByUserList = (
    <div className="view-case-tab-view">
      <TabView>
        <TabPanel header="Apps">
          {appsByUser ? (
            <DataTable
              value={appsByUser}
              className="p-datatable-striped top-adjust"
              rows={10}
              resizableColumns
              paginator
              Height={`${info.height * 0.2}px`}
              emptyMessage="No info found."
            >
              <Column field="id" header="Id" headerStyle={styledColumn} />
              <Column field="name" header="Name" headerStyle={styledColumn} />
              <Column field="description" header="Description" headerStyle={styledColumn} />
              <Column field="status" header="Status" headerStyle={styledColumn} />
            </DataTable>
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel header="Engines">
          {enginesByUser ? (
            <DataTable
              value={enginesByUser}
              className="p-datatable-striped top-adjust"
              scrollHeight={`${info.height * 0.2}px`}
              rows={10}
              resizableColumns
              paginator
              emptyMessage="No info found."
            >
              <Column field="id" header="Id" headerStyle={styledColumn} />
              <Column field="name" header="Name" headerStyle={styledColumn} />
              <Column field="description" header="Description" headerStyle={styledColumn} />
            </DataTable>
          ) : (
            <></>
          )}
        </TabPanel>
      </TabView>
    </div>
  );

  const appsUsersByEngineList = (
    <div>
      <div className="view-case-tab-view">
        <TabView>
          <TabPanel header="Apps">
            {appsByEngine ? (
              <DataTable
                value={appsByEngine}
                className="p-datatable-striped top-adjust"
                scrollHeight={`${info.height * 0.2}px`}
                rows={12}
                resizableColumns
                paginator
                emptyMessage="No info found."
              >
                <Column field="id" header="Id" headerStyle={styledColumn} />
                <Column field="name" header="App Name" headerStyle={styledColumn} />
                <Column field="description" header="Description" headerStyle={styledColumn} />
                <Column field="status" header="Status" headerStyle={styledColumn} />
              </DataTable>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel header="Engines">
            {usersByEngine ? (
              <DataTable
                value={usersByEngine}
                className="p-datatable-striped top-adjust"
                scrollHeight={`${info.height * 0.2}px`}
                rows={10}
                resizableColumns
                paginator
                emptyMessage="No info found."
              >
                <Column field="id" header="Id" headerStyle={styledColumn} />
                <Column field="name" header="Operator" headerStyle={styledColumn} />
                <Column field="email" header="Email" headerStyle={styledColumn} />
                <Column field="phone" header="Phone" headerStyle={styledColumn} />
              </DataTable>
            ) : (
              <></>
            )}
            <div className="action-header">
              <Button
                className="button btn-add"
                label="Add Users to Engine"
                icon="pi pi-plus"
                onClick={() =>
                  showModal(forms[2], <HeaderForm title="Add Users to Engine" iconName="pi-user" />)
                }
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );

  useEffect(async () => {
    await getUsersByWorkspace();
    await getEnginesByWorkspace();
    const handleUserValidation = () => {
      const validUser = userData?.role === 'ADMIN';
      if (!validUser) {
        history.push('/401');
      }
    };
    handleUserValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appsByUser || enginesByUser) {
      showModal(
        appsEnginesByUserList,
        <HeaderForm title={`User ${selectedUser?.name}`} iconName="pi-user" />
      );
    }
  }, [appsByUser, enginesByUser]);

  useEffect(() => {
    if (appsByEngine || usersByEngine) {
      showModal(
        appsUsersByEngineList,
        <HeaderForm title={`Engine ${selectedEngine?.name}`} iconName="pi-user" />
      );
    }
  }, [appsByEngine, usersByEngine]);

  useEffect(() => {
    if (!tools.showModal && (selectedEngine || selectedUser)) {
      setSelectedUser(null);
      setSelectedEngine(null);
      setAppsByUser(null);
      setEnginesByUser(null);
    }
  }, [tools]);

  const UserSection = () => (
    <>
      <div className="engine-section">
        {handleTask()}
        {usersByWorkspace.length > 0 ? (
          <div className="engine-list-wrapper">
            {usersByWorkspace.map((user, i) => (
              <EngineUserCard
                key={'engine'.concat(i.toString())}
                selected={selectedUser ? selectedUser.id === user.id : false}
                onSelect={handleSelectedUser}
                color={utils.getColor(i, utils.appDashboardColors)}
                type="user"
                onEditButton={onEditButton}
                {...user}
              />
            ))}
          </div>
        ) : (
          <ListEmptyState />
        )}
        <div className="action-button">
          <Button
            className="button actioner"
            label="User"
            icon="pi pi-plus"
            style={{ margin: '0px' }}
            onClick={() => showModal(forms[0], 'New User')}
          />
        </div>
      </div>
    </>
  );

  const EnginesSection = () => (
    <>
      <div className="engine-section">
        {enginesByWorksapace.length > 0 ? (
          <div className="engine-list-wrapper">
            {enginesByWorksapace.map((engine, i) => (
              <EngineUserCard
                key={'engine'.concat(i.toString())}
                selected={selectedEngine ? selectedEngine.id === engine.id : false}
                onSelect={handleSelectedEngine}
                color={utils.getColor(i, utils.appDashboardColors)}
                type="engine"
                onEditButton={onEditButton}
                {...engine}
              />
            ))}
          </div>
        ) : (
          <ListEmptyState />
        )}
        <div className="action-button">
          <Button
            className="button actioner"
            label="Engine"
            icon="pi pi-plus"
            onClick={() => showModal(forms[1], 'New Engine')}
          />
        </div>
      </div>
    </>
  );

  const ListEmptyState = () => {
    return (
      <div className="list-empty-apps-wrapper">
        <div className="detail-container">
          <img
            className="detail-img"
            src={tab === 0 ? listEmptyUsersImage : listEmptyEnginesImage}
            alt="No apps found!"
          />
          <p className="details-message">{tab === 0 ? 'No users found!' : 'No engines found!'}</p>
        </div>
      </div>
    );
  };

  return !loading ? (
    <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)} scrollable>
      <TabPanel header="Users">
        <UserSection />
      </TabPanel>
      <TabPanel header="Engines">
        <EnginesSection />
      </TabPanel>
      <TabPanel header="Billing">
        <AccountBilling />
      </TabPanel>
    </TabView>
  ) : (
    <Loader fit />
  );
};

export default AdminInfo;
