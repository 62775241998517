/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button, Dropdown, InputText } from '../../components/BasicComponents';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { eventsService, appDashboardService } from '../../services';
import regexs from '../../utils/regexs';
import isEmpty from '../../utils/validators';
import './EventForm.css';
import formSchema from './schema';

export const createHeader = (
  <header className="flex-row event-form-header">
    <span>New Event</span>
  </header>
);

const EMPTY_WEBHOOK_DEFAULT = { id: '', name: '' };

const EventForm = ({ data, handleSubmit, formType }) => {
  const { accountInfo, showSnackbar, selectedApp } = useContext(AccountContext);
  const { userData } = accountInfo;
  const { id, username } = userData;
  const [webhookURL, setWebhookURL] = useState(data.webhook ? data.webhook.url : '');
  const [token, setToken] = useState(data.webhook ? data.webhook.token : '');
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const { handleChange, handleBlur, values, errors, isValid, touched } = useFormik({
    initialValues: EMPTY_WEBHOOK_DEFAULT,
    // validationSchema: formSchema,
    onSubmit: (v) => {
      showSnackbar('info', 'Attention!', 'API CALL in progresss !!');
    },
  });

  const nameInput = useInput('name', data.name || `Event-${moment().unix()}`, [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const ruleInput = useInput('rule', data.rules || '', [
    {
      validatorName: 'rule-required',
      regex: regexs.required,
    },
  ]);

  const webhookNameInput = useInput(
    'webhookName',
    data.webhook ? data.webhook.name : `Webhook-${moment().unix()}`,
    [
      {
        validatorName: 'webhookName-required',
        regex: regexs.required,
      },
    ]
  );

  const passphraseInput = useInput('passphrase', '', []);

  const inputs = [nameInput, ruleInput, webhookNameInput, passphraseInput];

  const newData = {
    apps: data.apps || [
      {
        id: selectedApp.id,
        isActive: selectedApp.isActive,
      },
    ],
    id: data.id || '',
    name: nameInput.value,
    rules: ruleInput.value,
  };

  const loadWebhook = async () => {
    const { success, data: webhookData } = await eventsService.generateWebhook();
    if (success) {
      setWebhookURL(webhookData.url);
    }
  };

  const loadCalendar = async () => {
    const userInfo = { id: '1' };
    const calendarResult = await appDashboardService.getCalendars(userInfo);
    setCalendarOptions(calendarResult.data.calendars);

    if (data.calendar) {
      const calendarSelected = calendarResult.data.calendars.find(
        (item) => item.id === data.calendar.id
      );
      setSelectedCalendar(calendarSelected);
    }
  };

  const generateToken = async () => {
    const user = {
      username,
      password: passphraseInput.value, // enviar la passphrase
    };
    const tokenResult = await eventsService.generateToken(user);

    if (tokenResult.success) setToken(tokenResult.data.token);
  };

  const onCalendarChange = (e) => {
    setSelectedCalendar(e.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const webhookObj = {
      id: data?.webhook?.id || '',
      user: {
        id,
      },
      token: token || '',
      url: webhookURL,
      name: webhookNameInput.value,
      description: '',
    };

    if (formType === 'create') {
      const { success, data: WebHook1 } = await eventsService.createWebhook(webhookObj);
      if (success) {
        const eventData = {
          ...newData,
          calendar: {
            id: selectedCalendar?.id || '',
          },
          webhook: {
            id: WebHook1?.id || '',
          },
        };

        await handleSubmit(eventData);
      } else {
        const actionWord = formType === 'create' ? 'creating' : 'updating';
        showSnackbar(
          'error',
          'Error!',
          `Something went wrong ${actionWord} the event! Please check the inputs`
        );
      }
    } else {
      const { success, data: WebHook2 } = await eventsService.updateWebhook(webhookObj);
      if (success) {
        const eventData = {
          ...newData,
          calendar: {
            id: selectedCalendar.id,
          },
          webhook: {
            id: WebHook2?.id,
          },
        };

        await handleSubmit(eventData);
      } else {
        const actionWord = formType === 'create' ? 'creating' : 'updating';
        showSnackbar(
          'error',
          'Error!',
          `Something went wrong ${actionWord} the event! Please check the inputs`
        );
      }
    }
  };

  useEffect(() => {
    if (formType === 'create') {
      loadWebhook();
    }
    loadCalendar();
  }, []);

  useEffect(() => {
    let isDirty = true;
    if (isEmpty(data)) {
      let validToken = true;
      if (passphraseInput.isValid && !token) validToken = false;
      isDirty = !(nameInput.value && Object.values(selectedCalendar).length);
    } else {
      let valueChanged = false;

      if (isEmpty(data)) valueChanged = true;
      inputs.map((input) => {
        input.setIsEnabled(true);
        if (input.value !== data[input.inputName]) {
          if (input.value || data[input.inputName]) valueChanged = true;
        }
        return input;
      });

      let validToken = true;
      if (passphraseInput.isValid && !token) validToken = false;
      isDirty = !(nameInput.value && Object.values(selectedCalendar).length);
    }
    setDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nameInput.value,
    nameInput.isValid,
    ruleInput.value,
    ruleInput.isValid,
    webhookNameInput.value,
    webhookNameInput.isValid,
    passphraseInput.value,
    passphraseInput.isValid,
    selectedCalendar,
    token,
  ]);

  return (
    <form onSubmit={onSubmit}>
      <section className="flex-row event-name-section">
        <label className="event-input-label">Name</label>
        <InputText
          className="event-input-text"
          value={nameInput.value}
          {...nameInput.bind}
          required
        />
        <Button
          className="event-btn"
          type="submit"
          label={formType === 'create' ? 'Create' : 'Update'}
          disabled={dirty}
        />
      </section>
      <section id="programmedEvents" className="events-section">
        <label htmlFor="programmed" className="event-input-title">
          Programmed
        </label>
        <section className="flex-colum">
          <div className="flex-row programmed-form-control">
            <label>Calendar</label>
            {calendarOptions && (
              <Dropdown
                className="programmed-input"
                value={selectedCalendar}
                options={calendarOptions}
                onChange={onCalendarChange}
                optionLabel="name"
                placeholder="Select one"
                required
              />
            )}
          </div>
          <div className="flex-row programmed-form-control">
            <label>Cron Rule</label>
            <InputText
              className="programmed-input-cron"
              value={ruleInput.value}
              {...ruleInput.bind}
            />
            <Button className="p-button-secondary event-btn" type="button">
              <a
                style={{ textDecoration: 'none', color: 'white' }}
                href="http://www.cronmaker.com/?0"
                target="_blank"
                rel="noreferrer"
              >
                Get Cron Format
              </a>
            </Button>
          </div>
        </section>
      </section>
      <section id="weebhookEvents" className="events-section">
        <label className="event-input-title">WebHook</label>
        <section className="flex-column">
          <div className="flex-row webhook-events-formControl">
            <label>Name</label>
            <InputText value={webhookNameInput.value} {...webhookNameInput.bind} required />
          </div>
          <div className="flex-row webhook-events-formControl">
            <label>Passphrase</label>
            <InputText value={passphraseInput.value} {...passphraseInput.bind} />
          </div>

          <div className="flex-row webhook-events-formControl">
            <label style={{ width: '70px' }}>Token</label>
            <InputText value={token} disabled />
            <Button
              label="Generate Token"
              className="p-button-secondary event-btn"
              onClick={generateToken}
              disabled={!(passphraseInput.value && passphraseInput.value.length > 3)}
              type="button"
            />
          </div>
          <div className="flex-row webhook-events-formControl">
            <label>Webhook URL</label>
            <InputText value={webhookURL} disabled required />
          </div>
        </section>
      </section>
    </form>
  );
};

EventForm.defaultProps = {
  data: {},
};

EventForm.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
};

export default EventForm;
