/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';

import { DebounceInput } from 'react-debounce-input';

import Tickets from '../../../assets/nav-icons/Ticket';
import listEmptyImage from '../../../assets/svgs/arts/undraw_collaboration.svg';
import { Button, DataTable, MultiSelect, Column } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import EditCaseForm from '../../../forms/EditCaseForm/EditCaseForm';
import useWindowSize from '../../../hooks/useWindowSize';
import tools from '../../../utils/time';

import './GroupCases.css';

const DEFAULT_FILTERS = {
  id: { value: null, matchMode: 'contains' },
  'operator.name': { value: null, matchMode: 'in' },
  'group.id': { value: null, matchMode: 'in' },
  status: { value: null, matchMode: 'in' },
  light_indicator: { value: null, matchMode: 'in' },
};

export default function GroupCases() {
  const info = useWindowSize();
  const { menuCollapse, setTools, tools: acTools } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { styleGuideByGroup, groupsRetrieved, overall, fetchOverAllCases } = useContext(
    TicketsContext
  );
  const [selectedCasesPriorities, setSelectedCasesPriorities] = useState([]);
  const [selectedRestOfPpl, setSelectedRestOfPpl] = useState([]);
  const [idFilter, setIdFilter] = useState('');
  const [selectedPpl, setSelectedPpl] = useState([]);
  const [selectedTempPpl, setSelectedTempPpl] = useState([]);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [usersRetrieved, setUsersRetrieved] = useState([]);
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([]);
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetStatusCode = () => {
    return selectedCasesStatus?.map((c) => {
      return c.code;
    });
  };

  const handleGetConditionsCode = () => {
    return selectedCasesPriorities?.map((c) => c.name);
  };

  const handleGetGroupsCode = () => {
    return selectedCasesGroups?.map((c) => c.id);
  };

  const handleLeftScrollInFilters = () => {
    document.getElementById('filter-teams').scrollTo(0, 0);
  };

  const retriveUsers = (groupList) => {
    const listUsers = groupList
      .filter((g) => !!g.operator.name)
      .map((g) => g.operator.name)
      .flat();
    setUsersRetrieved([...new Set(listUsers)]);
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchTicketInGroups-input');
    input?.focus();
  };

  useEffect(() => {
    setFilters({
      id: { value: idFilter, matchMode: 'contains' },
      'operator.name': { value: selectedPpl, matchMode: 'in' },
      'group.id': { value: handleGetGroupsCode(), matchMode: 'in' },
      status: { value: handleGetStatusCode(), matchMode: 'in' },
      light_indicator: { value: handleGetConditionsCode(), matchMode: 'in' },
    });
    setTimeout(() => {
      if (
        selectedCasesGroups?.length ||
        selectedCasesPriorities?.length ||
        selectedCasesStatus?.length ||
        selectedPpl?.length ||
        idFilter !== ''
      ) {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesGroups, selectedCasesPriorities, selectedCasesStatus, selectedPpl, idFilter]);

  useEffect(() => {
    if (filters) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, [filters]);

  useEffect(() => {
    if (usersRetrieved) {
      setSelectedRestOfPpl([...usersRetrieved.slice(5, usersRetrieved.length)]);
    }
  }, [usersRetrieved]);

  useEffect(() => {
    if (overall?.length === 0) {
      fetchOverAllCases();
    }
    setTimeout(() => {
      handleFocusInput();
    }, 1000);
  }, []);

  useEffect(() => {
    if (overall?.length > 0) {
      retriveUsers(overall);
    }
  }, [overall]);

  const handleReset = () => {
    handleLeftScrollInFilters();
    setSelectedCasesStatus([]);
    setSelectedCasesPriorities([]);
    setSelectedCasesGroups([]);
    setSelectedPpl([]);
    setSelectedTempPpl([]);
    setIdFilter('');
  };

  const handleNamePrefix = () => {
    return usersRetrieved?.reduce((namesPrefixed, player) => {
      const [first, second] = player?.split(' ');
      if (!second) {
        namesPrefixed?.push(`${first[0]}${first[1]}`.toUpperCase());
      } else {
        namesPrefixed?.push(`${first[0]}${second[0]}`.toUpperCase());
      }
      return namesPrefixed;
    }, []);
  };
  const handleNamePrefix2 = (userName) => {
    const [first, second] = userName?.split(' ');
    // eslint-disable-next-line prefer-const
    let namesPrefixed = [];
    if (!second) {
      namesPrefixed?.push(`${first[0]}${first[1]}`.toUpperCase());
    } else {
      namesPrefixed?.push(`${first[0]}${second[0]}`.toUpperCase());
    }
    return namesPrefixed;
  };

  const diffBetweenArrays = (arr1, arr2) => {
    return arr1.filter((item) => arr2.indexOf(item) === -1);
  };

  const handleTogglePpl = (pivot, isActionButton) => {
    let persona;
    if (isActionButton) {
      persona = usersRetrieved[pivot];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!(selectedTempPpl.length <= pivot.length)) {
        const leftPerson = diffBetweenArrays(selectedTempPpl, pivot);
        [persona] = leftPerson;
      } else {
        persona = pivot[pivot.length - 1];
      }
    }
    const isSelected = selectedPpl.some((p) => persona === p);
    if (!isSelected) {
      setSelectedPpl((prev) => [...prev, persona]);
      setSelectedTempPpl((prev) => [...prev, persona]);
    } else {
      setSelectedPpl((prev) => prev.filter((value) => value !== persona));
      setSelectedTempPpl((prev) =>
        prev.filter((value) => {
          return persona ? value !== persona : [];
        })
      );
    }
  };

  const Avatar = (p, i) => {
    if (i > 5) {
      return;
    }
    return i !== 5 ? (
      <Button
        key={`${p}-${i}`}
        title={usersRetrieved[i]}
        className="history-ppl-button"
        onClick={() => handleTogglePpl(i, true)}
      >
        {p}
      </Button>
    ) : (
      <MultiSelect
        key={`${p}-${i}`}
        className="history-A-users"
        placeholder={<i className="pi pi-users" />}
        value={selectedTempPpl}
        options={selectedRestOfPpl}
        onChange={(e) => handleTogglePpl(e.value, false)}
        fixedPlaceholder
      />
    );
  };

  const AvatarGroups = () => {
    const ppl = handleNamePrefix();
    return ppl?.map((p, i) => {
      return Avatar(p, i);
    });
  };

  const status_default = [
    { name: 'Unassigned', code: 'unassigned' },
    { name: 'Assigned', code: 'assigned' },
    { name: 'Closed', code: 'closed' },
    { name: 'Blocked', code: 'blocked' },
  ];

  const conditions = [
    { name: 'green', code: 'On time' },
    { name: 'yellow', code: 'Warning' },
    { name: 'red', code: 'Running late' },
  ];

  const StatusColumn = ({ status: codeStatus }) => {
    return <div className="statusChip">{codeStatus}</div>;
  };

  const GroupColumn = ({ group }) => {
    return (
      <div className={`groupChip ${styleGuideByGroup[group.id?.slice(-1)]}`}>{group.name}</div>
    );
  };

  const AvatarColumn = ({ operator, status: caseStatus }) => {
    return caseStatus === 'unassigned' ? (
      <Button className="history-avatar avatar-align-empty" title="Case unassigned">
        -
      </Button>
    ) : (
      <Button className="history-avatar avatar-align" title={operator.name}>
        {handleNamePrefix2(operator.name)}
      </Button>
    );
  };

  const ConditionColumn = ({ light_indicator, created_at }) => {
    const difference = tools.timeSince(created_at.seconds);
    return (
      <div className={`dot2 ${light_indicator}`} title="Created since">
        <p className="dot-value">{difference}</p>
      </div>
    );
  };

  const conditionTemplate = (c) => {
    return (
      <div className="wrapper-conditions">
        <span className={`dot ${c.name}`} />
        <p className="conditions">{c.code}</p>
      </div>
    );
  };

  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]}`}
      >
        <p className="groups">{c.name}</p>
      </div>
    );
  };

  const HeaderForm = ({ title, iconName = '' }) => {
    return (
      <div className="activity-header">
        <Tickets size={25} color="white" />
        {title}
      </div>
    );
  };

  const handleEditCase = (caseData) => {
    setTools({
      ...acTools,
      manageModal: {
        header: <HeaderForm title="Edit the Ticket" />,
        content: <EditCaseForm caseInfo={caseData} groupsRetrieved={groupsRetrieved} />,
      },
      showModal: true,
    });
  };

  const EditColumn = (caseData) => {
    return (
      <div className="editButton">
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-info button editionButton"
          onClick={() => {
            handleEditCase(caseData);
          }}
        />
      </div>
    );
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyImage} alt="Search a case" />
          <p className="detail-message">No group cases found!</p>
        </div>
      </div>
    );
  };

  return (
    <div className="history-wrapper">
      {overall?.length > 0 ? (
        <div className="history-container">
          <div
            id="filter-teams"
            className="history-A"
            style={{
              width:
                isMobile || isTablet
                  ? menuCollapse
                    ? `${info.width - 165}px`
                    : `${info.width - 100}px `
                  : menuCollapse
                  ? `${info.width - 157}px`
                  : `${info.width - 65}px`,
            }}
          >
            <div className="history-A-search">
              <DebounceInput
                id="searchTicketInGroups-input"
                className="buttonFilter"
                value={idFilter}
                debounceTimeout={700}
                onChange={(e) => setIdFilter(e.target.value)}
                placeholder="Search by Ticket ID"
                autoComplete="off"
              />
            </div>
            <div className="history-A-people">
              <AvatarGroups />
            </div>
            <div className="history-A-filters">
              <MultiSelect
                optionLabel=""
                placeholder="Teams"
                className="history-A-dropdown"
                value={selectedCasesGroups}
                options={groupsRetrieved}
                onChange={(e) => setSelectedCasesGroups(e.value)}
                itemTemplate={groupTemplate}
                fixedPlaceholder
              />
              <MultiSelect
                optionLabel="name"
                placeholder="Status"
                className="history-A-dropdown"
                options={status_default}
                value={selectedCasesStatus}
                onChange={(e) => setSelectedCasesStatus(e.value)}
                filter
                fixedPlaceholder
              />
              <MultiSelect
                optionLabel="name"
                placeholder="Condition"
                className="history-A-dropdown"
                value={selectedCasesPriorities}
                options={conditions}
                onChange={(e) => setSelectedCasesPriorities(e.value)}
                filter
                itemTemplate={conditionTemplate}
                fixedPlaceholder
              />
              <Button
                disabled={disableClear}
                title="Clear search"
                icon="pi pi-filter"
                className="history-A-clear"
                onClick={handleReset}
              />
            </div>
          </div>
          <div className="history-B">
            {!loading ? (
              <DataTable
                className="p-datatable-striped"
                emptyMessage="No tickets found."
                value={overall}
                globalFilterFields={[
                  'id',
                  'light_indicator',
                  'operator.name',
                  'group.name',
                  'status',
                ]}
                responsiveLayout="scroll"
                paginator
                rows={30}
                resizableColumns
                filters={filters}
                scrollable
                style={{
                  marginLeft: menuCollapse ? `10px` : `15px`,
                  width:
                    isMobile || isTablet
                      ? menuCollapse
                        ? `${info.width - 145}px`
                        : `${info.width - 60}px`
                      : menuCollapse
                      ? `${info.width - 170}px`
                      : `${info.width - 75}px`,
                }}
                scrollHeight={
                  isMobile || isTablet ? `${info.height - 265}px` : `${info.height - 290}px`
                }
              >
                <Column
                  field="id"
                  filterField="id"
                  header=""
                  headerStyle={{
                    maxWidth: '0px',
                    width: '0px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    color: 'transparent',
                  }}
                  bodyStyle={{
                    textAlign: 'center',
                    overflow: 'visible',
                    backgroundColor: 'transparent',
                    color: 'transparent',
                  }}
                />
                <Column
                  field="light_indicator"
                  filterField="light_indicator"
                  headerStyle={{
                    maxWidth: '35px',
                    width: '35px',
                    textAlign: 'center',
                    backgroundColor: '#22384D',
                    color: 'white',
                  }}
                  body={ConditionColumn}
                  bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                />
                <Column
                  field="contact_name"
                  header="Ticket Name"
                  headerStyle={{
                    width: '200px',
                    minWidth: '200px',
                    maxWidth: '200px',
                    textAlign: 'left',
                    backgroundColor: '#22384D',
                    color: 'white',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="description"
                  header="Description"
                  headerStyle={{
                    width: '200px',
                    minWidth: '200px',
                    maxWidth: '200px',
                    textAlign: 'left',
                    backgroundColor: '#22384D',
                    color: 'white',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="group.id"
                  filterField="group.id"
                  header="Team"
                  headerStyle={{
                    width: '120px',
                    minWidth: '120px',
                    maxWidth: '120px',
                    textAlign: 'center',
                    backgroundColor: '#22384D',
                    color: 'white',
                  }}
                  body={GroupColumn}
                  bodyStyle={{
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  sortable
                />
                <Column
                  field="operator.name"
                  filterField="operator.name"
                  header="Assigned to"
                  headerStyle={{
                    backgroundColor: '#22384D',
                    color: 'white',
                    width: '135px',
                    textAlign: 'center',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                  body={AvatarColumn}
                  sortable
                />
                <Column
                  field="status.id"
                  filterField="status"
                  header="Status"
                  headerStyle={{
                    width: '110px',
                    minWidth: '110px',
                    maxWidth: '110px',
                    textAlign: 'center',
                    backgroundColor: '#22384D',
                    color: 'white',
                  }}
                  body={StatusColumn}
                  bodyStyle={{ overflow: 'visible' }}
                  sortable
                />
                <Column
                  field="edit"
                  header="Edit"
                  headerStyle={{
                    width: '70px',
                    minWidth: '70px',
                    textAlign: 'center',
                    backgroundColor: '#22384D',
                    color: 'white',
                  }}
                  body={EditColumn}
                  bodyStyle={{ overflow: 'visible' }}
                />
              </DataTable>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <ListEmptyState />
      )}
    </div>
  );
}
