/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { GoBackComponent } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { environment } from '../../enviroments/enviroment';

import './ModelerIframe.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ModelerIframe = ({ location }) => {
  const iframeRef = useRef(null);
  const history = useHistory();
  const { accountInfo, selectedFlow, setMenuCollapse } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { userData } = accountInfo;
  const { enabledModules, id: userId } = userData;
  const query = useQuery();
  const [url, setUrl] = useState();
  const [name, setName] = useState('New Flow');

  useEffect(() => {
    if (!selectedFlow?.id) location.isCreating && history.goBack();
    const handleUserValidation = () => {
      const validUser = enabledModules?.some((data) => data.id === 'MODELER');
      if (!validUser || location.isCreating) {
        history.push('/401');
      }
    };
    handleUserValidation();
    if (isMobile || isTablet) {
      setMenuCollapse(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let iframeUrl = `${
      environment.modeler.host
    }/index.html?language=${'en'}&edit=true&idUser=1&apiUser=${userId}&backend=${environment.ws}`;
    const isNewFlow = query.get('isNewFlow') === 'true';

    if (!isNewFlow) {
      setName(selectedFlow?.name);
      const canEdit = query.get('canEditFlow');

      iframeUrl = `${environment.modeler.host}/index.html?language=${'en'}
        &edit=${canEdit === 'true'}&idUser=1&apiUser=${userId}&document=${
        selectedFlow?.id
      }&backend=${environment.ws}`;
    }
    setUrl(iframeUrl);
  });

  return (
    <div className="modeler-iframe-wrapper" title={name}>
      <div className="modeler-iframe-header">
        <GoBackComponent />
      </div>
      <div className="modeler-iframe-container">
        <div className="modeler-iframe-iframe">
          <iframe
            ref={iframeRef}
            id="modelerIFrame"
            src={url}
            frameBorder="0"
            className="modeler-iframe"
            title={name}
          />
        </div>
      </div>
    </div>
  );
};
export default ModelerIframe;
