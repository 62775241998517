/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components';

import './SubMenu.css';

import { TicketsContext } from '../../../contexts/TicketsContext';

function SubMenu({ batchId }) {
  const { selectedTicket, setSelectedTicket } = useContext(TicketsContext);
  const history = useHistory();
  const handleRedirection = () => {
    setSelectedTicket(null);
    history?.push('/tickets#my-tickets');
  };
  return (
    <div className="wrapper-submenu">
      <div className="wrapper-row">
        <p className="label-txt">Número de Servicio</p>
        <span className="value-txt">{selectedTicket?.operation}</span>
      </div>
      {batchId && (
        <div className="wrapper-row">
          <p className="label-txt">Batch ID</p>
          <span className="value-txt">{batchId}</span>
        </div>
      )}
      <div className="wrapper-row">
        <p className="label-txt">Descripción</p>
        <span className="value-txt">{selectedTicket?.description}</span>
      </div>
      <Button type="button" onClick={handleRedirection} className="logout-btn">
        Ir al menú
      </Button>
    </div>
  );
}

export default SubMenu;
