import React from 'react';

import PropTypes from 'prop-types';

const Treemap = ({ selectedType }) => {
  return (
    <div className="section-container">
      <h1 className="titles">{selectedType > 0 ? 'States Distribution' : 'Cases Distribution'}</h1>
      <div className="info-container">
        <section
          id="chartCasesTypes"
          style={{
            height: '100%',
            width: '100%',
            marginTop: '-20px',
          }}
        />
      </div>
    </div>
  );
};

Treemap.propTypes = {
  selectedType: PropTypes.number.isRequired,
};

export default Treemap;
