/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import listEmptyFlowsImage from '../../assets/svgs/arts/undraw_flows.svg';
import { Button, DataTable, Column, Loader, SelectButton } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import useWindowSize from '../../hooks/useWindowSize';
import { flowsService } from '../../services';

import './ModelerList.css';

const ModelerList = () => {
  const history = useHistory();
  const { accountInfo, setSelectedFlow, menuCollapse, modeles, setModeles } = useContext(
    AccountContext
  );
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const info = useWindowSize();
  const { userData } = accountInfo;
  const { enabledModules } = userData;
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState(0);
  const filterOptions = [
    { name: 'New flow', value: 1 },
    { name: 'Search', value: 2 },
  ];

  const onCreateFlow = () => {
    setSelectedFlow(null);
    history.push(`/flows/flow?canEditFlow=false&&isNewFlow=true`);
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchFlow-input');
    input?.focus();
  };

  useEffect(() => {
    if (filter === 2) {
      document.getElementById('messages').scrollTo(1000, 0);
      handleFocusInput();
    }
  }, [filter]);

  const filterTemplate = (option) => {
    if (option.value === 1) {
      return (
        <Button
          className="button create-button"
          label={option.name}
          icon="pi pi-plus"
          onClick={onCreateFlow}
        />
      );
    }
    return <p className="chipFilter">{option.name}</p>;
  };

  const onViewEdit = (flow, canEdit) => {
    setSelectedFlow(flow);
    history.push(`/flows/flow?canEditFlow=${canEdit}&&isNewFlow=false`);
  };

  const getAllFlows = async () => {
    setLoading(true);
    const flowsResult = await flowsService.getAllFlows('1');
    if (flowsResult.success) {
      setModeles(flowsResult.data.flows);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1700);
  };

  useEffect(() => {
    if (modeles.length === 0) {
      const handleUserValidation = () => {
        const validUser = enabledModules?.some((data) => data.id === 'MODELER');
        if (!validUser) {
          history.push('/401');
        }
      };
      handleUserValidation();
      getAllFlows();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const EditColumn = (flow) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-info button actionButton"
        onClick={() => {
          onViewEdit(flow, true);
        }}
      />
    );
  };
  const viewColumn = (flow) => {
    return (
      <Button
        type="button"
        icon="pi pi-eye"
        className="p-button-info button actionButton"
        onClick={() => {
          onViewEdit(flow, false);
        }}
      />
    );
  };

  const DetailEmptyState = () => {
    return (
      <div className="table-modeler-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyFlowsImage} alt="No flows found!" />
          <p className="modeler-message">No flows found!</p>
        </div>
      </div>
    );
  };

  return !loading ? (
    <div className="modeler-wrapper">
      <div className="modeler-container">
        <div className="modeler-table">
          {modeles.length > 0 ? (
            <DataTable
              className="p-datatable-striped"
              emptyMessage="No flows found."
              value={modeles}
              globalFilter={globalFilter}
              responsiveLayout="scroll"
              paginator
              rows={30}
              resizableColumns
              scrollable
              style={{
                width:
                  isMobile || isTablet
                    ? menuCollapse
                      ? `${info.width - 115}px`
                      : `${info.width - 31}px`
                    : menuCollapse
                    ? `${info.width - 145}px`
                    : `${info.width - 65}px`,
              }}
              scrollHeight={
                isMobile || isTablet ? `${info.height - 160}px` : `${info.height - 190}px`
              }
            >
              <Column
                field="name"
                header="Flow"
                headerStyle={{
                  backgroundColor: '#22384D',
                  color: 'white',
                  maxWidth: '180px',
                  width: '180px',
                }}
                sortable
              />
              <Column
                field="description"
                header="Description"
                headerStyle={{
                  backgroundColor: '#22384D',
                  color: 'white',
                  maxWidth: '400px',
                  width: '400px',
                }}
                sortable
              />
              <Column
                field="type"
                header="Type"
                headerStyle={{
                  width: '6rem',
                  textAlign: 'center',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                sortable
              />
              <Column
                header="Edit"
                body={EditColumn}
                headerStyle={{
                  width: '6rem',
                  textAlign: 'center',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
              />
              <Column
                header="View"
                body={viewColumn}
                headerStyle={{
                  width: '6rem',
                  textAlign: 'center',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
              />
            </DataTable>
          ) : (
            <DetailEmptyState />
          )}
        </div>
        <div className="info-space-A">
          <div className="wrapper-filters">
            <div
              id="global"
              style={{
                width: isMobile
                  ? isTablet
                    ? menuCollapse
                      ? '74vw'
                      : '93vw'
                    : menuCollapse
                    ? '74vw'
                    : '95vw'
                  : menuCollapse
                  ? '65vw'
                  : '93vw',
              }}
            >
              <div id="messages">
                <SelectButton
                  value={filter}
                  options={filterOptions}
                  onChange={(e) => setFilter(e.value === null ? 0 : e.value)}
                  optionLabel="name"
                  className="filters-modeler"
                  itemTemplate={filterTemplate}
                />
                {filter === 2 && modeles ? (
                  <DebounceInput
                    id="searchFlow-input"
                    className="buttonFilter"
                    value={globalFilter}
                    debounceTimeout={700}
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search flow"
                    autoComplete="off"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader fit />
  );
};

export default ModelerList;
