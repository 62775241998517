/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import deleteIcon from '../../assets/images/delete.png';
import EditIcon from '../../assets/images/edit_modify_icon-icons.com_72390.png';
import GraphIcon from '../../assets/images/stats_icon.png';
import WorkflowIcon from '../../assets/images/Workflow_4803.png';
import AppNameComponent from '../AppName/AppName';
import { Button, InputSwitch } from '../BasicComponents';

import './AppCard.css';

const AppCard = ({
  app,
  onAppEdit,
  onDelete,
  onHistorySelect,
  onFlowSelected,
  backgroundColor,
  onChangeIsActive,
}) => {
  const CardHeader = () => (
    <div className="app-card-header">
      <div className={`app-card-header-left ${backgroundColor}`}>
        <AppNameComponent appName={app.name} />
      </div>
      <div className="app-card-header-right">
        <div className="app-card-header-right-up">
          <p className="header-right-up-name">{app.name}</p>
          <button type="button" className="delete-icon-button" onClick={() => onDelete(app.id)}>
            <img src={deleteIcon} alt="Delete button" className="button-useropt-image" />
          </button>
        </div>
        <div className="app-card-header-right-down">
          <InputSwitch checked={app.isActive} onChange={(e) => onChangeIsActive(app.id, e.value)} />
          <div className="header-right-down-actions">
            <Button
              title="App Flow"
              className="p-button-outlined p-button-secondary user-opt-button"
              onClick={() => {
                onFlowSelected(app);
              }}
            >
              <img
                src={WorkflowIcon}
                alt="Menu Logo Mybasket"
                className="button-useropt-image"
                style={{ height: '25px', width: '25px' }}
              />
            </Button>
            <Button
              disabled={app?.statistics?.appsInstances === 0}
              title="App History"
              className="p-button-outlined p-button-secondary user-opt-button"
              onClick={() => {
                onHistorySelect(app);
              }}
            >
              <img src={GraphIcon} alt="Stats Logo Mybasket" className="button-useropt-image" />
            </Button>
            <Button
              title="Edit app"
              className="p-button-rounded p-button-outlined p-button-secondary user-opt-button"
              onClick={() => {
                onAppEdit(app);
              }}
            >
              <img src={EditIcon} alt="Menu Logo Mybasket" className="button-useropt-image" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const CardBody = () => (
    <div className="app-card-body">
      <div className="app-card-body-up">
        <div className="app-card-body-left">
          <p className="app-card-item app-card-subtitle">Owner</p>
          <p className="app-card-item">{app?.user?.name}</p>
          <p className="app-card-item app-card-subtitle">
            {moment(app?.creationDate).format('MM/D/YY, HH:mm')}
          </p>
        </div>
        <div className="app-card-body-right">
          <p className="app-card-item app-card-subtitle">Events</p>
          <p className="app-card-item">{app?.events?.length}</p>
        </div>
      </div>
      {app?.description && (
        <div className="app-card-body-down">
          <p className="app-card-body-down-description">{app?.description}</p>
        </div>
      )}
    </div>
  );

  const CardFooter = () => (
    <div className="app-card-footer ">
      <div className="app-card-footer-item" title="# Active Days">
        <p className="app-card-item app-card-subtitle">Active Days</p>
        <p className="app-card-item">{app?.statistics?.active}</p>
      </div>
      <div className="separator white vertical-separator" />
      <div className="app-card-footer-item" title="# App Instances">
        <p className="app-card-item app-card-subtitle">Instances</p>
        <p className="app-card-item">{app?.statistics?.appsInstances}</p>
      </div>
      <div className="separator white vertical-separator" />
      <div className="app-card-footer-item" title="# Executed Points">
        <p className="app-card-item app-card-subtitle">Executed</p>
        <p className="app-card-item">{app?.statistics?.executedPoints}</p>
      </div>
    </div>
  );

  return (
    <div className="app-card-wrapper">
      <CardHeader />
      <CardBody />
      <CardFooter />
    </div>
  );
};

AppCard.propTypes = {
  app: PropTypes.object.isRequired,
  onAppEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onHistorySelect: PropTypes.func.isRequired,
  onFlowSelected: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onChangeIsActive: PropTypes.func.isRequired,
};

export default AppCard;
