/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import editIcon from '../../assets/images/edit_modify_icon-icons.com_72390.png';
import statsIcon from '../../assets/images/stats_icon.png';
import { AccountContext } from '../../contexts/AccountContext';
import EventForm, { createHeader } from '../../forms/EventForm/EventForm';
import { eventsService } from '../../services';
import { Avatar, Button, InputSwitch } from '../BasicComponents';

import './EventsTable.css';

const EventsTable = () => {
  const history = useHistory();
  const { tools, setTools, showSnackbar, selectedApp } = useContext(AccountContext);
  const [events, setEvents] = useState([]);

  const loadEvents = async () => {
    const { success, data: eventsRetrieved } = await eventsService.getEventsByApp(selectedApp.id);
    if (success) setEvents(eventsRetrieved);
  };

  const handleActive = async (value, id) => {
    const { success } = await eventsService.updateIsActive(selectedApp.id, id, { isActive: value });
    if (success) {
      showSnackbar('success', 'Success!', 'Event status updated succesfully');
    } else {
      showSnackbar(
        'error',
        'Updating event error!',
        'Something went wrong updating the event status! Please try again'
      );
    }
    loadEvents();
  };

  const handleSubmitAdd = async (event) => {
    const { success } = await eventsService.createEvent(event);
    if (success) {
      showSnackbar('success', 'Success!', 'Event creted succesfully');
    }
    loadEvents();
    setTools({ ...tools, showModal: false });
  };

  const handleSubmitUpdate = async (event) => {
    const { success } = await eventsService.updateEvent(event);
    if (success) {
      showSnackbar('success', 'Updating event success!', 'Event updated succesfully');
    } else {
      // showSnackbar(
      //   'error',
      //   'Creating event error!',
      //   'Something went wrong updating the event! Please try again'
      // );
    }
    loadEvents();
    setTools({ ...tools, showModal: false });
  };

  const onEditEvent = async (id) => {
    const resultEvent = await eventsService.getEventById(id);

    setTools({
      ...tools,
      manageModal: {
        createHeader,
        content: (
          <EventForm
            title="Edit App"
            data={resultEvent?.data}
            handleSubmit={handleSubmitUpdate}
            formType="edit"
          />
        ),
      },
      showModal: true,
    });
  };

  const deleteApp = async (e, eventId) => {
    e.preventDefault();
    const { success } = await eventsService.deleteEventOfApp(selectedApp?.id, eventId);
    if (success) {
      showSnackbar('success', 'Deleting event success!', 'Event deleted succesfully');
      await loadEvents();
      setTools({ ...tools, showModal: false });
    } else {
      // showSnackbar(
      //   'error',
      //   'Error!',
      //   'Something went wrong deleting the event! Please try again later'
      // );
    }
    loadEvents();
  };

  const handleGoBack = (preLoad) => {
    history.push({ pathname: '/apps', goBack: preLoad });
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const HeaderForm = ({ title }) => {
    return <div className="activity-header">{title}</div>;
  };

  const handleNeEvent = () => {
    setTools({
      ...tools,
      manageModal: {
        header: <HeaderForm title="New Event" />,
        content: <EventForm handleSubmit={handleSubmitAdd} formType="create" />,
      },
      showModal: true,
    });
  };

  if (!selectedApp.id) handleGoBack(false);

  return (
    <section className="table-events-wrapper">
      <table id="events-table" className="events-scroller">
        <tbody>
          {events
            ? events.map((t) => (
                // eslint-disable-next-line react/jsx-indent
                <tr key={t.id}>
                  <td>
                    <InputSwitch
                      checked={t.isActive}
                      onChange={(e) => handleActive(e.value, t.id)}
                    />
                  </td>
                  <td className="event-name">{t.name}</td>
                  <td>
                    <section className="flex-row">
                      <div>
                        <img id="statsIcon" src={statsIcon} alt="Stats Icon" />
                      </div>
                      <span className="table-events-description">{t.description}</span>
                    </section>
                  </td>
                  <td>
                    <Button className="events-action" onClick={async () => onEditEvent(t.id)}>
                      <img src={editIcon} alt="Edit button" />
                    </Button>
                    <Button className="events-action" onClick={(e) => deleteApp(e, t.id)}>
                      <i
                        className="pi pi-times"
                        style={{
                          fontSize: '20px',
                          color: '#2C2C2D',
                        }}
                      />
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <div className="flex events-align">
        <h2 className="heading">Create Events</h2>
        <Button className="action-btns" label="Event" onClick={handleNeEvent} icon="pi pi-plus" />
      </div>
    </section>
  );
};

export default EventsTable;
