/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';

import md5 from 'md5';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';

import ArrowDown from '../../../assets/svgs/arrow-down-tickets.svg';
import { Loader, Button } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import {
  vendorsService,
  docuSignService,
  casesDashboardService,
  usersService,
  batchesService,
} from '../../../services';
import { getCookie } from '../../../utils/tools';
import Camera from '../../Tooler/Camera/Camera';
import FileDisplayer from '../../Tooler/FileDisplayer/FileDisplayer';
import Notes from '../../Tooler/Notes/Notes';
import SubMenu from '../../Tooler/SubMenu/SubMenu';
import YesNoPopup from '../../Tooler/YesNoPopup/YesNoPopup';
import CaseDetail from '../CaseDetail/CaseDetail';

// import PropTypes from 'prop-types';

import './TicketResolution.css';

const WHITE_COLOR = 'white';

const TicketResolution = () => {
  const history = useHistory();
  const [colorBgTemplate, setColorBgTemplate] = useState(WHITE_COLOR);
  const {
    selectedTicket,
    setSelectedTicket,
    resolveCase,
    magic,
    saveS3Case,
    getS3Case,
    casesById,
    lockCase2,
    fetchCasesCheck,
  } = useContext(TicketsContext);
  const claimRef = useRef(null);
  const manageTooler = useRef(null);
  const manageSubMenu = useRef(null);
  const manageNotes = useRef(null);
  const manageDisplayFileRef = useRef(null);
  const toolerRef = useRef(null);
  const notesRef = useRef(null);
  const submenuRef = useRef(null);
  const displayFileRef = useRef(null);
  const caseRef = useRef(null);
  const headerRef = useRef(false);
  const [claimed, setClaimed] = useState(null);
  const [showReturn, setShowReturn] = useState(true);
  const { setMenuCollapse, showSnackbar, accountInfo, logOut } = useContext(AccountContext);
  const [tooler, setTooler] = useState({ toolerRef, manageTooler, showTooler: false });
  const [submenu, setSubmenu] = useState({ submenuRef, manageSubMenu, showTooler: false });
  const [batchId, setBatchId] = useState(null);
  const [notes, setNotes] = useState({ notesRef, manageNotes, showNotes: false });
  const [displayerFile, setDisplayerFile] = useState({
    displayFileRef,
    manageDisplayFileRef,
    showDisplayFile: false,
  });
  const { userData } = accountInfo;

  const [isLoading, setIsLoading] = useState(true);

  const Header = () => {
    return <div className="blue-bg" />;
  };

  // if (!selectedTicket) {
  //   history.push('/tickets#my-tickets');
  // }

  const showTooler = (content, header) => {
    setTooler({
      ...tooler,
      manageTooler: { content, header },
      showTooler: true,
    });
  };

  const handleCloseTooler = () => {
    setTooler({
      ...tooler,
      showTooler: false,
    });
  };

  const showSubMenu = (content, header) => {
    setSubmenu({
      ...submenu,
      manageSubMenu: { content, header },
      showSubMenu: true,
    });
  };

  const handleCloseSubMenu = () => {
    setSubmenu({
      ...submenu,
      showSubMenu: false,
    });
  };

  const handleShoot = ({ type, color, txts, event, isFrontShoot }) => {
    const ticketForm = (
      <Camera
        type={type}
        data={[]}
        color={color}
        handleCloseTooler={handleCloseTooler}
        disclaimersTxt={txts}
        event={event}
        isFrontShoot={isFrontShoot}
      />
    );
    showTooler(ticketForm, <Header />);
  };

  const handleCloseNotes = () => {
    setNotes({
      ...notes,
      showNotes: false,
    });
  };

  const showNotes = (content, header) => {
    setNotes({
      ...notes,
      manageNotes: { content, header },
      showNotes: true,
    });
  };

  const handleUserValidation = ({ event, userName, className, msg }) => {
    const YesNoForm = (
      <YesNoPopup
        event={event}
        userName={userName}
        className={className}
        msg={msg}
        handleCloseTooler={handleCloseTooler}
      />
    );
    showTooler(YesNoForm, null);
  };

  const cleanHash = () => {
    const result = window.location.hash.split('/');
    if (result.length === 2) {
      const popHash = result[0];
      window.location.hash = popHash;
    }
  };

  const collectData = (setOfData) => {
    const { data } = selectedTicket;
    const { initialValues } = data;
    const newData = {
      initialValues,
      exportedValues: { ...setOfData },
    };
    return JSON.stringify(newData);
  };

  const handleCloseDisplayerFile = () => {
    setDisplayerFile({
      ...displayerFile,
      showDisplayFile: false,
    });
  };

  const showDisplayerFile = (content, header) => {
    setDisplayerFile({
      ...displayerFile,
      manageDisplayFileRef: { content, header },
      showDisplayFile: true,
    });
  };

  const handleLockFormMenu = async () => {
    setClaimed(true);
    setIsLoading(true);
    await lockCase2(selectedTicket.id, false, true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(async () => {
    const flag = selectedTicket?.status === 'assigned';
    setClaimed(flag);
    if (!flag) {
      // !claimed && showSnackbar('info', '', 'Attention: Ticket not assigned!', 3000);
    }
    const templateAsync = await casesById(selectedTicket?.id);
    if (templateAsync) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
      history.push('/unauthorized');
    }
    const result = await fetchCasesCheck();
    if (result.length === 1 && magic) {
      setShowReturn(false);
    }
    return () => {};
  }, []);

  const handleFile = ({ data }) => {
    const FileContent = (
      <FileDisplayer event={data} handleCloseDisplayerFile={handleCloseDisplayerFile} />
    );
    showDisplayerFile(FileContent, null);
  };

  const handleNotes = (event) => {
    const NotesContent = (
      <Notes
        event={event.source}
        isEditing={event.data.isEditing}
        note={event.data.note}
        handleCloseNotes={handleCloseNotes}
        caseId={event.data.caseId}
      />
    );
    showNotes(NotesContent, null);
  };

  const adaptData = (data, state, caseId, status, wsId) => {
    const updatedData = {
      state,
      caseId,
      status,
      wsId,
      processed: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        updatedData[`retrived_${key}`] = data[key];
      }
    }
    return updatedData;
  };

  const handleApprove = async (setOfData, event, data) => {
    const { id, operation, status } = selectedTicket;
    const { success, data: casesInfo } = await casesDashboardService.getCase(operation);
    // Manejo de errores
    if (!success) {
      showSnackbar(
        'error',
        '',
        'Failed to retrieve case information. Please try again later.',
        6000
      );
      return;
    }

    const { state, status: statusCase } = casesInfo;

    if (state === 'Denegada' && statusCase === 'COMPLETED') {
      showSnackbar('error', '', 'Your form has been denied while you were filling it out!', 6000);
      setTimeout(() => {
        logOut();
        setSelectedTicket(null);
        history?.push({
          pathname: '/',
          goBack: true,
        });
      }, 3000);
      return;
    }

    const isNext = true;

    if (status === 'assigned' || claimed) {
      let inputsForm;
      let S3Saved;
      let isS3Saved;

      if (data?.uploadFiles) {
        S3Saved = await saveS3Case({
          caseId: data?.caseId,
          files: data?.files,
        });
        isS3Saved = Array.isArray(S3Saved?.data);
      }
      if (isS3Saved || !data?.uploadFiles) {
        inputsForm = collectData(setOfData);
      } else {
        data?.uploadFiles &&
          showSnackbar(
            'error',
            '',
            '¡Hemos tenido problemas guardando los archivos! Inténlelo mas tarde.'
          );
        event.window.postMessage(
          {
            isApi: true,
            success: false,
            type: data.type,
          },
          '*'
        );
        return;
      }
      const result = await resolveCase(id, inputsForm, isNext);
      if (result) {
        const { state: stateTemp, status: statusTemp } = data?.context;
        const tempValues = {};
        if (isS3Saved) {
          tempValues.files = JSON.stringify(S3Saved?.data);
        }
        const info2 = adaptData(
          {
            ...data?.values,
            ...tempValues,
          },
          stateTemp,
          data?.caseId,
          statusTemp,
          data?.wsId
        );
        await batchesService.updateBatchCase(data?.caseId, info2);
      }
      if (result) {
        showSnackbar('success', '', 'Ticket sent!');
      } else {
        showSnackbar('error', '', 'The ticket could not be sent!');
      }
      event.window.postMessage(
        {
          isApi: true,
          success: result,
          type: data.type,
        },
        '*'
      );
    } else {
      showSnackbar('info', '', 'Please claim the ticket before resolving.', 6000);
      cleanHash();
      setMenuCollapse(true);
      history.push('/tickets#my-tickets');
    }
  };

  const getCaseInfo = async (operation) => {
    const { success: s2, data: d2 } = await casesDashboardService.getCase(operation);
    if (s2) {
      return d2;
    }
    return false;
  };

  const getCaseStates = async (type) => {
    const { success: s2, data: d2 } = await casesDashboardService.getCaseStates(type);
    if (s2) {
      return d2;
    }
    return false;
  };

  const getNotes = async (caseID) => {
    const { success: s2, data: d2 } = await casesDashboardService.getCaseNotes(caseID);
    if (s2) {
      return d2;
    }
    return false;
  };

  const handleExternalCalls = async (event) => {
    let payload;
    switch (event.data?.type) {
      case 'docMB':
        payload = await vendorsService.callExternal({
          url: event.data?.url,
          verb: event.data?.verb,
          body: {
            tipCon: event.data?.tipCon,
            dui: event.data.payload.doc,
          },
        });
        break;
      case 'docusign-create':
        payload = await docuSignService.createEnvelope(event.data.caseId, event.data?.payload);
        break;
      case 'docusign-get':
        const dataPayload = {
          caseId: event?.data?.caseId,
          docusignId: event?.data?.docuSignId,
          payload: {
            ticket: {
              id: selectedTicket.id,
            },
            signer: event?.data?.signer,
            params: {
              urlCallback: event?.data?.callbackTo,
            },
          },
        };
        payload = await docuSignService.generateUrlEnvelope(dataPayload);
        break;
      case 's3-get':
        payload = await getS3Case({
          caseId: event.data.caseId,
        });
        break;
      case 'notes-get':
        payload = await getNotes(event.data.caseId);
        break;
      case 'get-case':
        payload = await getCaseInfo(event.data.operation);
        break;
      case 'get-states':
        payload = await getCaseStates(event.data.typeCase);
        break;
      case 'userId-get':
        payload = await usersService.getUserIdByEmail(event.data.data.email);
        break;
      case 'change-pwd':
        // console.log(event.data.data.userId);
        // console.log(`${event.data.data.username}/${event.data.data.pwd}/hound`);
        const encryt = md5(`${event.data.data.username}/${event.data.data.pwd}/hound`);
        payload = await usersService.updateUser(event.data.data.userId, { password: encryt });
        break;
      default:
        payload = {};
        break;
    }
    event?.source?.window.postMessage(
      {
        isExternalCall: true,
        type: event?.data?.type,
        subtype: event?.data?.subtype || '',
        payload,
        error: !payload?.success,
      },
      '*'
    );
  };

  const getUserInfo = (event) => {
    if (userData) {
      const userInfo = {
        email: userData?.email,
        name: userData?.name,
      };
      event.window.postMessage({ userHoundInfo: true, userInfo }, '*');
    } else {
      const { identityUser } = JSON.parse(getCookie('credentials'));
      const userInfo = {
        email: identityUser,
        name: identityUser,
      };
      event.window.postMessage({ userHoundInfo: true, userInfo: userInfo || {} }, '*');
    }
  };

  window.onmessage = function (event) {
    if (event.data.isBatch) {
      setBatchId(event.data.batchId);
    }
    if (event.data.isDisplayFile) {
      handleFile({
        data: event.data,
      });
    }
    if (event.data.isNotes) {
      handleNotes(event);
    }
    if (event.data.userHoundInfo) {
      getUserInfo(event.source);
    }
    if (event.data.isYou) {
      handleUserValidation({
        event: event.source,
        userName: event.data.userName,
        className: event.data.className,
        msg: event?.data?.msg || '',
      });
    }
    if (event.data.showHeader) {
      headerRef.current = true;
    }
    // Send the form and save the S3
    if (event.data.isReady) {
      console.log('event.data', event.data);
      handleApprove(event.data.values, event.source, event.data);
    }
    // Fill the a tool state
    if (event.data.isOperation) {
      handleShoot({
        type: event.data.typeOperation,
        primaryColor: event.data.primaryColor,
        txts: event.data.txts,
        event: event.source,
        isFrontShoot: event.data.isFrontShoot || false,
      });
    }
    // Display a notification system
    if (event.data.isNotification) {
      showSnackbar(
        event.data.typeNotification || 'info',
        event.data.titleNotification || '',
        event.data.msgNotification || '',
        event.data.delay || '6000'
      );
    }
    if (event.data.isExternalCall) {
      handleExternalCalls(event);
    }
    if (event.data.isBg) {
      if (event.data.colorBg) {
        setColorBgTemplate(event.data.colorBg || WHITE_COLOR);
      } else {
        setColorBgTemplate(WHITE_COLOR);
      }
    }
  };

  const toggleSubmenu = () => {
    console.log('batchId', batchId);
    const ticketForm = <SubMenu batchId={batchId} />;
    showSubMenu(ticketForm, <></>);
  };

  const TicketHeader = () => {
    return (
      <div className="header-wrapper">
        <div className="ticket-user">
          <Button id="submenu" className="submenu-btn" type="button" onClick={toggleSubmenu}>
            <img src={ArrowDown} alt="submenu" />
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedTicket) {
      caseRef.current = <CaseDetail />;
      headerRef.current = <TicketHeader />;
    }
    return () => {
      caseRef.current = null;
    };
  }, [selectedTicket]);

  const handleBack = () => {
    setSelectedTicket(null);
    history?.push('/tickets');
  };

  return !isLoading ? (
    <div
      className={magic ? 'modeler-magic-wrapper' : 'modeler-iframe-wrapper'}
      style={{ backgroundColor: colorBgTemplate }}
    >
      <div className="header-go-back">
        <div className="header-wrap">
          {showReturn && (
            <div className="action-container">
              <Button
                title="Return to tickets"
                label=""
                icon="pi pi-list"
                className="back"
                style={{ width: '160px' }}
                onClick={handleBack}
              />
            </div>
          )}
          {!claimed ? (
            <Button
              ref={claimRef}
              className="claim-btn bounce"
              type="button"
              onClick={handleLockFormMenu}
            >
              Claim ticket
            </Button>
          ) : (
            <span> </span>
          )}
        </div>
      </div>
      <div className="modeler-iframe-container">
        <div className="modeler-iframe-iframe">
          <div className="HI-details">{caseRef.current}</div>
        </div>
      </div>
      <Dialog
        closable
        header={notes?.manageNotes?.header}
        className="notesForm"
        onHide={handleCloseNotes}
        visible={notes.showNotes}
      >
        {notes.manageNotes.content}
      </Dialog>
      <Dialog
        header={tooler?.manageTooler?.header}
        className="toolForm"
        onHide={handleCloseTooler}
        visible={tooler.showTooler}
      >
        {tooler.manageTooler.content}
      </Dialog>
      <Dialog
        header={submenu?.manageSubmenu?.header}
        className="submenu-popup"
        onHide={handleCloseSubMenu}
        visible={submenu.showSubMenu}
      >
        {submenu.manageSubMenu.content}
      </Dialog>
      <Dialog
        closable={false}
        header={displayerFile?.manageDisplayFileRef?.header}
        className="displayer-file"
        onHide={handleCloseDisplayerFile}
        visible={displayerFile?.showDisplayFile}
      >
        {displayerFile?.manageDisplayFileRef.content}
      </Dialog>
    </div>
  ) : (
    <div className="loader-container">
      <Loader fit />
    </div>
  );
};

TicketResolution.propTypes = {};

export default TicketResolution;
