/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MyBasket from '../../assets/pngs/mybasket_logo.png';
import { Button } from '../../components/BasicComponents';
import { useInput } from '../../hooks';

import './LoginForm.css';

const LoginForm = ({ onSubmit, loading }) => {
  const usernameInput = useInput('username', '', []);
  const passwordInput = useInput('password', '', []);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [isValidForm, setisValidForm] = useState(false);

  const user = {
    username: usernameInput?.value.trim(),
    password: passwordInput?.value.trim(),
  };

  useEffect(() => {
    // TODO add a minimum len to the password && passwordInput.value.length >= 6
    if (usernameInput.value && passwordInput.value) {
      setisValidForm(true);
    }
    return () => {
      setisValidForm(false);
    };
  }, [usernameInput, passwordInput]);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setHiddenPassword((val) => !val);
  };

  return (
    <form className="form-login" onSubmit={(event) => onSubmit(event, user)}>
      <img src={MyBasket} alt="Login Logo" className="lHeaderImg" />
      <h3 className="lHeaderSubtitle">Enter your credentials</h3>
      <div className="loginFormGroup">
        <span className="p-input-icon-left">
          <i className="pi pi-user" />
          <input
            placeholder="Username"
            id="username"
            disabled={loading}
            className="loginInputText"
            {...usernameInput.bind}
            required
          />
        </span>
      </div>
      <div className="loginFormGroup">
        <span className="p-input-icon-left p-input-icon-right">
          <i className="pi pi-lock" />
          <input
            placeholder="Password"
            disabled={loading}
            className="loginInputText"
            id="password"
            type={!hiddenPassword ? 'text' : 'password'}
            {...passwordInput.bind}
            required
          />
          {hiddenPassword ? (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <i onClick={(event) => handleShowPassword(event)} className="pi pi-eye" />
          ) : (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <i onClick={(event) => handleShowPassword(event)} className="pi pi-eye-slash" />
          )}
        </span>
      </div>
      <Button disabled={!isValidForm} className="button blue-gradient submit-btn" label="Login" />
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoginForm;
