/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { AccountContext } from '../../contexts/AccountContext';
import { appDashboardService } from '../../services';
import AppNameComponent from '../AppName/AppName';
import { InputSwitch } from '../BasicComponents';
import './EditAppCard.css';

const EditAppCard = () => {
  const history = useHistory();
  const { selectedApp, setSelectedApp, setApps, showSnackbar, accountInfo } = useContext(
    AccountContext
  );
  const { userData } = accountInfo;
  const { id: userId } = userData;
  const isFillSelectedApp = Object.keys(selectedApp).length;

  if (!isFillSelectedApp) {
    history.push({ pathname: '/apps', goBack: true });
  }

  const fetchAllApps = async () => {
    const { success, data: allApps } = await appDashboardService.getAllApps(userId);
    if (success && allApps.length) {
      setApps(allApps);
    }
  };

  const onChangeIsActive = async (appId, value) => {
    const { success } = await appDashboardService.updateApp(appId, {
      isActive: value,
    });
    setSelectedApp((pre) => ({ ...pre, isActive: value }));
    if (success) {
      showSnackbar('success', 'Attention!', 'App updated succesfully');
      fetchAllApps();
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong updating the app! Please try again');
    }
  };

  return (
    isFillSelectedApp && (
      <article id="editAppCard" className="flex-column">
        <section className="flex-column">
          <AppNameComponent appName={selectedApp.name} isEdit />
          <div className="edit-row">
            <label id="lblDefaulters">
              <b>{selectedApp.description}</b>
            </label>
            <InputSwitch
              checked={selectedApp.isActive}
              onChange={(e) => onChangeIsActive(selectedApp.id, e.value)}
            />
          </div>
          <div id="appMainData" className="flex-column">
            <div className="flex-row">
              <p className="tag-title">Owner</p>
              <p className="tag-value">{selectedApp.user.name}</p>
            </div>
            <div className="flex-row">
              <p className="tag-title">Created</p>
              <p className="tag-value">{selectedApp.creationDate}</p>
            </div>
          </div>
        </section>
        {/* <section id="timeData">
          <div className="flex-column">
            <h4>Today</h4>
            <label># Running</label>
            2455
            <label>Started</label>
            154
            <label>Completed</label>
            59
          </div>
          <div className="time-data-separator" />
          <div className="flex-column">
            <h4>7 days</h4>
            <label># Running</label>
            2455
            <label>Started</label>
            154
            <label>Completed</label>
            59
          </div>
          <div className="time-data-separator" />
          <div className="flex-column">
            <h4>30 days</h4>
            <label># Running</label>
            2455
            <label>Started</label>
            154
            <label>Completed</label>
            59
          </div>
        </section> */}
      </article>
    )
  );
};

export default EditAppCard;
