/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { createContext, useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { usersService, ticketsService } from '../services';
import { getCookie } from '../utils/tools';
import { AccountContext } from './AccountContext';

const defaultCaseState = {
  cases: [],
  assigned: [],
  unassigned: [],
  selected: {},
  loading: true,
};

const styleGuideByGroup = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6'];
const styleGuideByGroupHex = ['#0098ff ', '#5c5c5c', '#725ca4', '#8ac7bb', '#5ace39', '#b1d3d4'];

export const TicketsContext = createContext({});

export const TicketsContextProvider = ({ children }) => {
  const [displayInfo, setDisplayInfo] = useState(false);
  const { showSnackbar, setMenuCollapse, setTools, tools } = useContext(AccountContext);
  const [emptyPageState, setEmptyPageState] = useState(true);
  const [teamTicket, setTeamTicket] = useState(null);
  const [teamSelected, setTeamSelected] = useState(null);
  const [operatorSelected, setOperatorSelected] = useState({});
  const [cases, setCases] = useState(defaultCaseState);
  const [groupIsOpen, setGroupIsOpen] = useState([true, true]);
  const [groupsRetrieved, setGroupsRetrieved] = useState([]);
  const [overall, setOverall] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [magic, setMagic] = useState('');
  const [isExpired, setIsExpired] = useState(true);

  const handleReplaceHasg = (idCase) => {
    const currentRoute = window.location.hash.split('/');
    if (currentRoute.length === 2 || currentRoute.length === 1) {
      window.location.hash = `${currentRoute[0]}/${idCase}`;
    }
  };

  const onCreateATicket = ({ header, content }) => {
    setTools({
      ...tools,
      manageModal: {
        header,
        content,
      },
      showModal: true,
    });
  };

  const getGroups = async () => {
    const { userId } = JSON.parse(getCookie('credentials'));
    const { success: userGroupsFlag, data: userGroupsResponse } = await usersService.getGroups(
      userId
    );
    if (userGroupsFlag) {
      const groups = userGroupsResponse?.map((d) => {
        return { id: d.id, name: d.name };
      });
      setGroupsRetrieved(groups);
    }
  };

  const fetchCasesCheck = async () => {
    const { success, data: casesData } = await ticketsService.casesByUser(false);
    if (success) {
      return [...casesData.cases];
    }
    return false;
  };

  const fetchOverAllCases = async () => {
    const { success, data: casesData } = await ticketsService.casesByUser(true);
    if (success) {
      const result = [...casesData.cases];
      setOverall(result);
      result.length !== 0 && setEmptyPageState(false);
    } else {
      setEmptyPageState(true);
      // showSnackbar('success', 'Get ready!', "You're all set , go and create a ticket!");
    }
  };

  const handleError = (err) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      switch (err.response.status) {
        case 400: // Bad request
          showSnackbar('error', 'Error!', 'Something went wrong. Please try again');
          break;
        case 401: // Unauthorized
          showSnackbar('error', 'Error!', 'Unauthorized');
          break;
        case 404: // Not found
          showSnackbar('error', 'Error!', 'Case not found, please refresh');
          break;
        case 408: // Timeout
          showSnackbar('warn', 'Warning!', 'Something went wrong. Please try again');
          break;
        case 500: // Internal Server Error
          showSnackbar('warn', 'Warning!', 'Unable to refresh cases');
          break;
        default:
          showSnackbar('error', 'Error!', 'Something went wrong. Please try again');
      }
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong. Please try again');
    }
  };

  const lockCase = async (id, isMagic = false, isFromMenu = false) => {
    try {
      await ticketsService.lock(id);
      if (!isMagic) {
        showSnackbar('success', '', 'Ticket assigned!', 5000);
        setSelectedTicket(null);
      }
    } catch (err) {
      handleError(err);
    }
    casesByUser();
  };

  const lockCase2 = async (id) => {
    try {
      const { success } = await ticketsService.lock(id);
      if (success) {
        showSnackbar('success', '', '¡Ticket assigned!', 3000);
      } else {
        showSnackbar('error', '', '¡We cannot assign a ticket at this time!', 5000);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const unlockCase = async (id) => {
    try {
      await ticketsService.unlock(id);
      showSnackbar('success', '', '¡Tiquete desasignado!');
      setSelectedTicket(null);
    } catch (err) {
      handleError(err);
      return;
    }
    casesByUser();
  };

  const handleHastagAction = (id, action) => {
    switch (action) {
      case 'claim':
        lockCase(id);
        break;
      case 'unassign':
        unlockCase(id);
        break;
      // TODO Add other actions in case you need
      default:
        break;
    }
  };

  const handleCaseById = (id, action = '') => {
    if (cases?.cases?.length > 0) {
      const lenCases = cases?.cases.filter((c) => c.status === 'assigned').length;
      const [caseTemp] = cases?.cases?.filter((c) => c.id === id);
      if (caseTemp && Object.keys(caseTemp).length) {
        action && handleHastagAction(id, action);
        setSelectedTicket(caseTemp);
        return true;
      }
      setSelectedTicket(null);
      return false;
    }
  };

  const updateCases = (caseList, next = false) => {
    const assigned = caseList?.filter((item) => item.status === 'assigned').reverse() || [];
    const unassigned = caseList?.filter((item) => item.status === 'unassigned').reverse() || [];
    if (next && assigned[0]) {
      setSelectedTicket(assigned[0]);
      handleReplaceHasg(assigned[0].id);
    } else {
      setMenuCollapse(true);
      setSelectedTicket(null);
    }
    setCases(() => ({
      cases: caseList,
      assigned,
      unassigned,
      loading: false,
    }));
  };

  const casesByUser = async (next = false) => {
    setCases((pre) => ({ ...pre, loading: true }));
    let casesList = [];
    try {
      casesList = (await ticketsService.casesByUser(false)).data.cases;
      updateCases(casesList, next);
      return true;
    } catch (err) {
      handleError(err, casesList);
      return false;
    }
  };

  const updateSelectedTicket = ({ template }) => {
    if (selectedTicket) {
      setSelectedTicket((t) => {
        return { ...t, template };
      });
      return true;
    }
    return false;
  };

  const casesById = async (id) => {
    try {
      const { success, data } = await ticketsService.casesById(id);
      if (success) {
        return updateSelectedTicket(data);
      }
      return false;
    } catch (err) {
      handleError(err);
    }
  };

  const casesByUsers = async () => {
    setCases((pre) => ({ ...pre, loading: true }));
    let casesList = [];
    try {
      casesList = (await ticketsService.casesByUsers(true))?.data?.cases;
      updateCases(casesList);
    } catch (err) {
      handleError(err, casesList);
    }
  };

  const saveCaseData = async (id, newData) => {
    try {
      await ticketsService.saveData(id, newData);
      showSnackbar('success', 'Attention!', 'Ticket Saved');
    } catch (err) {
      handleError(err);
      return;
    }
    casesByUser();
  };

  const resolveExternalCallsCase = async (payload) => {
    try {
      const { success } = await ticketsService.resolveExternalCalls(payload);
      return success;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  const resolveCase = async (id, newData, isNext = false) => {
    try {
      const { success } = await ticketsService.resolve(id, newData, true);
      return success;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  const setSelectedCase = (id) => {
    setCases((prevCases) => {
      if (prevCases.selected.id === id) {
        return { ...prevCases, selected: {} };
      }
      const selectedCase = prevCases.cases.filter((item) => id === item.id);
      return { ...prevCases, selected: selectedCase[0] };
    });
  };

  const handleFilterCase = async (id, groupsList = []) => {
    const filterGroups = groupsList?.map((g) => g.id);
    if (groupsList.length > 0) {
      return cases?.cases
        ?.filter((c) => {
          return c.operation?.match(id);
        })
        ?.filter((c) => {
          return filterGroups?.includes(c.group.id);
        });
    }
    return cases?.cases?.filter((c) => {
      return c.operation?.match(id);
    });
  };

  const saveS3Case = async (payload) => {
    try {
      const result = await ticketsService.saveS3(payload);
      return result;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  const getS3Case = async (payload) => {
    try {
      const result = await ticketsService.getS3(payload);
      return result;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  useEffect(() => {
    if (cases?.cases?.length) {
      handleCaseById(magic);
    }
  }, [cases]);

  return (
    <TicketsContext.Provider
      value={{
        ...cases,
        setCases,
        styleGuideByGroup,
        styleGuideByGroupHex,
        groupIsOpen,
        setGroupIsOpen,
        updateCases,
        casesByUser,
        casesByUsers,
        fetchCasesCheck,
        lockCase,
        unlockCase,
        saveCaseData,
        casesById,
        resolveCase,
        setSelectedCase,
        handleCaseById,
        handleFilterCase,
        groupsRetrieved,
        setGroupsRetrieved,
        getGroups,
        fetchOverAllCases,
        overall,
        teamSelected,
        setTeamSelected,
        operatorSelected,
        setOperatorSelected,
        setOverall,
        resolveExternalCallsCase,
        emptyPageState,
        setEmptyPageState,
        displayInfo,
        setDisplayInfo,
        handleReplaceHasg,
        onCreateATicket,
        selectedTicket,
        setSelectedTicket,
        magic,
        setMagic,
        isExpired,
        setIsExpired,
        saveS3Case,
        getS3Case,
        teamTicket,
        setTeamTicket,
        lockCase2,
      }}
    >
      {children}
    </TicketsContext.Provider>
  );
};

TicketsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
