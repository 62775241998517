/* eslint-disable no-unused-expressions */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { FiArrowLeftCircle } from 'react-icons/fi';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import HoundIcon from '../../assets/images/logo_icon_hound_transp.png';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import 'react-pro-sidebar/dist/css/styles.css';
import './Header.css';

const Header = ({ routes }) => {
  const history = useHistory();
  const location = useLocation();
  const { isTablet } = useContext(ResponsiveContext);
  const {
    accountInfo,
    logOut,
    menuCollapse,
    setAccountInfo,
    setSelectedApp,
    setSelectedFlow,
    setApps,
    setModeles,
    setForms,
  } = useContext(AccountContext);
  const {
    setCaseSelected,
    setTeamSelected,
    setOperatorSelected,
    setGroupsRetrieved,
    setOverall,
  } = useContext(TicketsContext);
  const { enabledModules, role } = accountInfo?.userData || {};

  const activeRoute = (route) => {
    return route.children
      ? route.children.find((child) => child.path === location.pathname)
      : location.pathname === route.path;
  };

  const resetContexts = () => {
    setAccountInfo({});
    setSelectedApp({});
    setSelectedFlow({});
    setApps([]);
    setModeles([]);
    setForms([]);
    setCaseSelected({});
    setTeamSelected({});
    setOperatorSelected({});
    setGroupsRetrieved({});
    setOverall({});
  };

  const handleLogout = () => {
    resetContexts();
    logOut();
    history.push('/');
  };

  const menuBodyRoutes = routes
    .filter((route) => route.possition === 'body')
    .filter((route) =>
      enabledModules?.find((module) => route.module === module.id || route.module === 'FORMS')
    )
    .map((route) => {
      return (
        <MenuItem icon={route.icon} active={activeRoute(route)} key={route.path}>
          <NavLink
            to={route.path}
            onClick={() => route.id === 'human-interaction' && setCaseSelected({})}
            className="menu-opt"
          >
            {route.name}
          </NavLink>
        </MenuItem>
      );
    });

  const menuFooterRoutes = routes
    .filter((route) => route.possition === 'footer')
    .filter((route) => route.name === 'Admin' && role === 'ADMIN')
    .map((route) => {
      return (
        <MenuItem icon={route.icon} key={route.path} active={activeRoute(route)}>
          <NavLink to={route.path} className="menu-opt">
            {route.name}
          </NavLink>
        </MenuItem>
      );
    });

  return (
    <div id="header">
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar collapsed={menuCollapse} className={`${!menuCollapse && 'expanded'}`}>
        <SidebarHeader>
          <div
            className="navbar-icon-wrapper"
            style={{ height: isTablet ? '60px' : '75px' }}
            title="Open menu"
          >
            {menuCollapse && (
              <img src={HoundIcon} className="nav-logo" title="Mybasket" alt="Hound logo" />
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          {menuCollapse && <Menu iconShape="square">{menuBodyRoutes}</Menu>}
        </SidebarContent>
        <SidebarFooter>
          {menuCollapse && (
            <Menu iconShape="square">
              {menuFooterRoutes}
              <MenuItem
                icon={<FiArrowLeftCircle className="nav-icon-out" />}
                onClick={handleLogout}
              >
                Logout
              </MenuItem>
            </Menu>
          )}
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

Header.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Header;
