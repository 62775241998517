/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Avatar } from '../../components';
import './ViewStageForm.css';

const ViewStageForm = ({ data, title, handleSubmit }) => {
  useEffect(async () => {
    // loadAvailables();
  }, []);

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <section className="flex-row app-form-icon-section">
        <Avatar
          icon="pi pi-eye"
          className="p-mr-2  subs-table-icons"
          style={{ backgroundColor: '#6fabf3', color: '#ffffff' }}
          size="small"
          shape="circle"
        />
        <h4>{title}</h4>
      </section>
      <section className="app-form-section">
        <div className="flex app-formGroup">
          <label>Id:</label>
          {data.id}
        </div>
        <div className="flex app-formGroup">
          <label>Name: </label>
          {data.name}
        </div>
        <div className="flex app-formGroup">
          <label>Status: </label>
          {data.status}
        </div>
        <div className="flex app-formGroup">
          <label>StartDate: </label>
          {data.startDate}
        </div>
        <div className="flex app-formGroup">
          <label>EndDate:</label>
          {data?.endDate}
        </div>
        <div className="flex app-formGroup">
          <label>Executed Points: </label>
          {data?.statistics?.executedPoints}
        </div>
        <div className="flex app-formGroup">
          <label>Data:</label>
          <p className="parrafo">{JSON.stringify(data.data, null, 4)}</p>
        </div>
      </section>
    </form>
  );
};

ViewStageForm.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ViewStageForm;
