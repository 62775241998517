/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

import { MultiSelect, Button, Loader, DataTable, Column, Avatar } from '../../../../components';
import { AccountContext } from '../../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';
import { ViewCaseForm, ViewCaseStatusHistoryForm, ViewCaseInstancesForm } from '../../../../forms';
import useWindowSize from '../../../../hooks/useWindowSize';
import { casesDashboardService } from '../../../../services';
import { DEFAULT_STATUS, DEFAULT_CATEGORIES, DEFAULT_FILTERS } from './constants';

import './CaseActivity.css';

const CaseActivity = ({ instances, setCaseStatus, setCaseCategory, setCaseOperationId }) => {
  const info = useWindowSize();
  const { menuCollapse, setTools, tools, handleTask } = useContext(AccountContext);
  const { isMobile, isTablet } = useContext(ResponsiveContext);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [idFilter, setIdFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleGetStatusCode = () => {
    return selectedCasesStatus?.map((c) => c.name);
  };

  const handleGetCategoryCode = () => {
    return selectedCategory?.map((c) => c.name);
  };

  useEffect(() => {
    setCaseCategory(selectedCategory);
    setCaseStatus(selectedCasesStatus);
    setCaseOperationId(idFilter.trim());
  }, [selectedCategory, selectedCasesStatus, idFilter]);

  useEffect(() => {
    setLoading(true);
    setFilters({
      'operation.id': { value: idFilter, matchMode: 'contains' },
      'category.id': { value: handleGetCategoryCode(), matchMode: 'in' },
      status: { value: handleGetStatusCode(), matchMode: 'in' },
    });
    setTimeout(() => {
      if (selectedCasesStatus?.length || selectedCategory?.length || idFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
      setLoading(false);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesStatus, selectedCategory, idFilter]);

  const FiltersActivities = () => {
    const handleReset = () => {
      setSelectedCasesStatus(null);
      setSelectedCategory(null);
      setIdFilter('');
    };

    return (
      <div className="filter-box" style={{ width: menuCollapse ? '60vw' : '90vw' }}>
        <div className="history-A-search">
          <DebounceInput
            className="buttonFilter"
            value={idFilter}
            debounceTimeout={700}
            onChange={(e) => setIdFilter(e.target.value)}
            placeholder="Search operation ID"
            autoComplete="off"
          />
        </div>
        <MultiSelect
          optionLabel="name"
          placeholder="Category"
          className="history-A-dropdown"
          value={selectedCategory}
          options={DEFAULT_CATEGORIES}
          onChange={(e) => {
            setSelectedCategory(e.value);
            handleTask();
          }}
          fixedPlaceholder
        />
        <MultiSelect
          optionLabel="name"
          placeholder="Status"
          className="history-A-dropdown"
          value={selectedCasesStatus}
          options={DEFAULT_STATUS}
          onChange={(e) => {
            setSelectedCasesStatus(e.value);
            handleTask();
          }}
          filter
          fixedPlaceholder
        />
        <Button
          disabled={disableClear}
          title="Clear search"
          icon="pi pi-filter"
          className="history-A-clear"
          onClick={handleReset}
        />
      </div>
    );
  };

  const TableActivities = () => {
    const HeaderForm = ({ title, iconName }) => {
      return (
        <div className="activity-header">
          <Avatar
            icon={`pi ${iconName}`}
            className="p-mr-2  subs-table-icons"
            style={{ backgroundColor: '#6fabf3', color: '#ffffff' }}
            size="small"
            shape="circle"
          />
          {title}
        </div>
      );
    };

    const showCaseDetail = async (caseId) => {
      const instanceResult = await casesDashboardService.getCase(caseId);

      setTools({
        ...tools,
        manageModal: {
          header: <HeaderForm title="App Case" iconName="pi-wallet" />,
          content: (
            <ViewCaseForm
              data={instanceResult.data}
              title="Case Detail"
              handleSubmit={(e) => {
                console.log(e);
              }}
            />
          ),
        },
        showModal: true,
      });
    };

    const showCaseStatusHistory = async (caseId) => {
      const caseResult = await casesDashboardService.getCase(caseId);
      setTools({
        ...tools,
        manageModal: {
          header: <HeaderForm title="Case Status History" iconName="pi-clock" />,
          content: <ViewCaseStatusHistoryForm data={caseResult.data} />,
        },
        showModal: true,
      });
    };

    const showCaseInstances = async (caseId) => {
      const caseResult = await casesDashboardService.getCase(caseId);
      setTools({
        ...tools,
        manageModal: {
          header: <HeaderForm title="Case App Instances" iconName="pi-compass" />,
          content: <ViewCaseInstancesForm data={caseResult.data} />,
        },
        showModal: true,
      });
    };

    const actionTemplate = (node) => {
      if (node?.category) {
        return (
          <>
            <Button
              type="button"
              icon="pi pi-wallet"
              className="p-button-success action-btn"
              onClick={() => {
                showCaseDetail(node.id);
              }}
            />
            <Button
              type="button"
              icon="pi pi-clock"
              className="p-button-success action-btn"
              onClick={() => {
                showCaseStatusHistory(node.id);
              }}
            />
            <Button
              type="button"
              icon="pi pi-compass"
              className="p-button-success action-btn"
              onClick={() => {
                showCaseInstances(node.id);
              }}
            />
          </>
        );
      }
    };

    return (
      <div
        className="table-box"
        style={{
          width:
            isMobile || isTablet
              ? menuCollapse
                ? `${info.width - 113}px`
                : `${info.width - 45}px`
              : '100%',
        }}
      >
        {!loading ? (
          <div>
            <DataTable
              showFilterMenu
              className="p-datatable-striped"
              value={instances}
              globalFilterFields={['operation.id', 'status']}
              responsiveLayout="scroll"
              paginator
              rows={30}
              resizableColumns
              filters={filters}
              emptyMessage="No activities found."
              scrollable
              style={{
                width:
                  isMobile || isTablet
                    ? menuCollapse
                      ? `${info.width - 110}px`
                      : `${info.width - 32}px`
                    : menuCollapse
                    ? `${info.width - 140}px`
                    : `${info.width - 65}px`,
              }}
              scrollHeight={
                isMobile
                  ? isTablet
                    ? `${info.height - 345}px`
                    : `${info.height - 315}px`
                  : `${info.height - 310}px`
              }
            >
              <Column
                field="state"
                header="State"
                headerStyle={{
                  width: '8rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'left',
                }}
                style={{ fontSize: '13px', textAlign: 'left' }}
              />
              <Column
                field="operation.id"
                filterField="operation.id"
                header="Operation Id"
                headerStyle={{
                  width: '8rem',
                  textAlign: 'center',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
              />
              <Column
                field="creationDate"
                header="Creation Date"
                headerStyle={{
                  width: '9.5rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
                sortable
              />
              <Column
                field="category.id"
                filterField="category.id"
                header="Category Id"
                headerStyle={{
                  width: '9rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
                sortable
              />
              <Column
                field="agentName"
                header="Name"
                headerStyle={{
                  width: '16rem',
                  textAlign: 'center',
                  backgroundColor: '#22384D',
                  color: 'white',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
              />
              <Column
                field="lastUpdate"
                header="Last Update"
                headerStyle={{
                  width: '10rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
              />
              <Column
                field="amount"
                header="Amount"
                headerStyle={{
                  width: '7rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
              />
              <Column
                field="status"
                filterField="status"
                header="Status"
                headerStyle={{
                  width: '6rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{ fontSize: '13px', textAlign: 'center' }}
              />
              <Column
                header="Actions"
                body={actionTemplate}
                headerStyle={{
                  width: '19rem',
                  backgroundColor: '#22384D',
                  color: 'white',
                  textAlign: 'center',
                }}
                style={{ textAlign: 'center', fontSize: '13px', gap: '20px' }}
              />
            </DataTable>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  return (
    <div className="activities-wrapper">
      {instances.length > 0 ? (
        <>
          <FiltersActivities />
          <TableActivities />
        </>
      ) : (
        <Loader fit />
      )}
    </div>
  );
};

CaseActivity.propTypes = {
  instances: PropTypes.array.isRequired,
  setCaseStatus: PropTypes.func.isRequired,
  setCaseCategory: PropTypes.func.isRequired,
  setCaseOperationId: PropTypes.func.isRequired,
};

export default CaseActivity;
