/* eslint-disable import/prefer-default-export */
import { httpRequest, wrap } from './tools';
// TODO make urls constants

const getListHumansOfGroups = async (groupId) => {
  const fn = async () => {
    const url = `/human-groups/${groupId}/humans`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getHumanGroup = async (groupId) => {
  const fn = async () => {
    const url = `/human-groups/${groupId}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

export { getListHumansOfGroups, getHumanGroup };
