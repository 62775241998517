/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import { useFormik } from 'formik';

import {
  Button,
  InputText,
  InputTextarea,
  MultiSelect,
} from '../../components/BasicComponents/index';
import { AccountContext } from '../../contexts/AccountContext';
import { humansService } from '../../services';
// import formSchema from './schema';

import './EditCaseForm.css';

const EMPTY_OPERATOR = { id: '', name: '' };

const EditCaseForm = ({ caseInfo, groupsRetrieved }) => {
  const { showSnackbar, handleCloseModal } = useContext(AccountContext);
  const styleGuideByGroup = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6'];
  const handleSubmitTicketUpdate = () => {};
  const { handleSubmit, handleChange, handleBlur, values, errors, isValid, touched } = useFormik({
    initialValues: caseInfo,
    // validationSchema: formSchema,
    onSubmit: (v) => {
      handleSubmitTicketUpdate();
      handleCloseModal();
      showSnackbar('info', 'Attention!', 'API CALL in progresss !!');
    },
  });
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([caseInfo.group]);
  const [selectedTeamOperator, setSelectedTeamOperator] = useState([
    JSON.stringify(EMPTY_OPERATOR) !== JSON.stringify(caseInfo.operator)
      ? caseInfo.operator
      : { id: 'empty', name: 'Waiting for operator' },
  ]);
  const [operatorsRelated, setOperatorsRelated] = useState([]);

  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups2 ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]} `}
      >
        <p className="groups">{c.name}</p>
      </div>
    );
  };

  const operatorTemplate = (c) => {
    return (
      <div className="wrapper-groups2">
        <p className="operator">{c.name}</p>
      </div>
    );
  };

  const handleRetrieve = async () => {
    const {
      success: usersByGroupFlag,
      data: usersByGroupResponse,
    } = await humansService.getListHumansOfGroups(selectedCasesGroups[0]?.id);
    if (usersByGroupFlag) {
      const addEmptyOption = [
        { id: 'empty', name: 'Waiting for operator' },
        ...usersByGroupResponse.data,
      ];
      setOperatorsRelated(addEmptyOption);
    } else {
      setOperatorsRelated([{ id: 'empty', name: 'Waiting for operator' }]);
    }
  };

  useEffect(() => {
    if (selectedCasesGroups) {
      handleRetrieve();
    }
  }, [selectedCasesGroups]);

  useEffect(() => {
    setSelectedCasesGroups([caseInfo.group]);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="id">
            Ticket Id
          </label>
          <InputText
            disabled
            id="id"
            name="id"
            type="text"
            className="input-form"
            placeholder=""
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.id}
          />
          {errors?.id && touched?.id ? <p>{errors?.id}</p> : <></>}
        </div>
        <div className="edit-container-right"> </div>
      </div>

      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="group">
            Team Name
          </label>
          <MultiSelect
            optionLabel="name"
            placeholder="Teams"
            className="input-form"
            options={groupsRetrieved}
            value={selectedCasesGroups}
            onChange={(e) => {
              setSelectedTeamOperator([{ id: 'empty', name: 'Waiting for operator' }]);
              e.value[1] && setSelectedCasesGroups([e.value[1]]);
            }}
            itemTemplate={groupTemplate}
            selectionLimit={2}
          />
          {errors?.group && touched?.group ? <p>{errors?.group}</p> : <></>}
        </div>
        <div className="edit-container-right">
          <label className="text-label" htmlFor="operator">
            Assigned to
          </label>
          <MultiSelect
            optionLabel="name"
            placeholder="Operator"
            className="input-form"
            options={operatorsRelated}
            value={selectedTeamOperator}
            onChange={(e) => {
              e.value[1] && setSelectedTeamOperator([e.value[1]]);
            }}
            itemTemplate={operatorTemplate}
            selectionLimit={2}
          />
          {/* <InputText
            id="operator"
            name="operator"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.operator.name}
          />
          {errors?.operator && touched?.operator ? <p>{errors?.operator}</p> : <></>} */}
        </div>
      </div>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="operation">
            Operation
          </label>
          <InputText
            disabled
            id="operation"
            name="operation"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.operation}
          />
          {errors?.operation && touched?.operation ? <p>{errors?.operation}</p> : <></>}
        </div>
        <div className="edit-container-right">
          <label className="text-label" htmlFor="status">
            Status
          </label>
          <InputText
            disabled
            id="status"
            name="status"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.status}
          />
          {errors?.status && touched?.status ? <p>{errors?.status}</p> : <></>}
        </div>
      </div>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="description">
            Description
          </label>
          <InputTextarea
            disabled
            id="description"
            name="description"
            className="text-area-input"
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
          />
          {errors?.description && touched?.description ? <p>{errors?.description}</p> : <></>}
        </div>
      </div>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="contact_name">
            Contact Name
          </label>
          <InputText
            disabled
            id="contact_name"
            name="contact_name"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.contact_name}
          />
          {errors?.contact_name && touched?.contact_name ? <p>{errors?.contact_name}</p> : <></>}
        </div>
        <div className="edit-container-right">
          <label className="text-label" htmlFor="contact">
            Contact
          </label>
          <InputText
            disabled
            id="contact"
            name="contact"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.contact}
          />
          {errors?.contact && touched?.contact ? <p>{errors?.contact}</p> : <></>}
        </div>
      </div>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="data">
            Data
          </label>
          <InputText
            disabled
            id="data"
            name="data"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.data}
          />
          {errors?.data && touched?.data ? <p>{errors?.data}</p> : <></>}
        </div>
        <div className="edit-container-right">
          <label className="text-label" htmlFor="chat_code">
            Chat code
          </label>
          <InputText
            disabled
            id="chat_code"
            name="chat_code"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.chat_code}
          />
          {errors?.chat_code && touched?.chat_code ? <p>{errors?.chat_code}</p> : <></>}
        </div>
      </div>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="due_date">
            Due date
          </label>
          <InputText
            disabled
            id="due_date"
            name="due_date"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={new Date(values.due_date.seconds * 1000)}
          />
          {errors?.due_date && touched?.due_date ? <p>{errors?.due_date}</p> : <></>}
        </div>
        <div className="edit-container-right">
          <label className="text-label" htmlFor="created_at">
            Created date
          </label>
          <InputText
            disabled
            id="created_at"
            name="created_at"
            className="input-form"
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={new Date(values.created_at.seconds * 1000)}
          />
          {errors?.created_at && touched?.created_at ? <p>{errors?.created_at}</p> : <></>}
        </div>
      </div>
      <div className="edit-container">
        <div className="edit-container-left">
          <label className="text-label" htmlFor="template">
            Template
          </label>
          <InputTextarea
            disabled
            id="template"
            name="template"
            className="text-area-input"
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.template}
          />
          {errors?.template && touched?.template ? <p>{errors?.template}</p> : <></>}
        </div>
      </div>
      <div className="edit-ticket-footer">
        <Button className="edit-action-btn" type="submit" disabled={!isValid}>
          Update Ticket
        </Button>
      </div>
    </form>
  );
};

export default EditCaseForm;
