import { httpRequest, wrap } from './tools';

const getAllFlows = async (id) => {
  const fn = async () => {
    const url = `/flowmanager/flows/all`;
    const response = await httpRequest(url, 'POST', { creator: id, all: false });

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { getAllFlows };
