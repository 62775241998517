/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

import PropTypes from 'prop-types';

import { Button, UsersAndEngineDrag } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { workSpaceService, enginesService } from '../../services';
import './UsersToEngineForm.css';

const UsersToEngineForm = ({ data, handleSubmit }) => {
  const { accountInfo } = useContext(AccountContext);
  const { workspaceData, handleTask } = accountInfo;
  const [assignedList, setAssignedList] = useState([]);
  const [availabledList, setAvailableList] = useState([]);

  const newData = {
    id: data?.id,
    users: assignedList,
  };

  const handleAssignedClick = (id) => {
    const index = assignedList.findIndex((item) => item.id === id);

    if (index !== -1) {
      assignedList[index].selected = !assignedList[index].selected;
      setAssignedList([...assignedList]);
    }
  };

  const handleAvailableClick = (id) => {
    const index = availabledList?.findIndex((item) => item.id === id);
    if (index !== -1) {
      availabledList[index].selected = !availabledList[index]?.selected;
      setAvailableList([...availabledList]);
    }
  };

  const onAssigned = (e) => {
    e.preventDefault();

    availabledList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        assignedList?.push(obj);
      }
    });
    const newAvailabled = availabledList?.filter((item) => !item.selected);
    setAvailableList([...newAvailabled]);
    setAssignedList([...assignedList]);
  };

  const onAvailable = (e) => {
    e.preventDefault();
    assignedList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        setAvailableList((p) => (p.length !== 0 ? [...p, obj] : [obj]));
      }
    });
    const newAssigned = assignedList?.filter((item) => !item.selected);
    // eslint-disable-next-line no-unused-expressions
    availabledList?.length > 0 && setAvailableList([...availabledList]);
    setAssignedList([...newAssigned]);
  };

  const loadAvailableUsers = async () => {
    const { success, data: availableUserData } = await workSpaceService.getAllUsersByWorkspace(
      workspaceData?.data?.id
    );
    // console.log('Wooi UserToEngineForm availableUserData', availableUserData);
    if (success) {
      setAvailableList(availableUserData);
    } else {
      setAvailableList([]);
    }
  };

  const loadAssignedUsers = async () => {
    const { success, data: engineData } = await enginesService.getEngine(data.id);
    if (success) {
      setAssignedList(engineData.users);
    }
  };

  useEffect(async () => {
    await loadAvailableUsers();
    await loadAssignedUsers();
  }, []);

  return (
    <form onSubmit={(e) => handleSubmit(e, newData)}>
      <UsersAndEngineDrag
        assignedList={assignedList}
        availabledList={availabledList}
        formType="UsersToEngineForm"
        handleAssignedClick={handleAssignedClick}
        handleAvailableClick={handleAvailableClick}
        onAssigned={onAssigned}
        onAvailable={onAvailable}
      />
      <div className="flex-row">
        <Button
          type="submit"
          className="engine-form-button"
          label="Update"
          onClick={() => handleTask()}
        />
      </div>
    </form>
  );
};

UsersToEngineForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UsersToEngineForm;
