/* eslint-disable import/named */
import React from 'react';

import PropTypes from 'prop-types';

import { Header } from '../../components';
import './Dashboard.css';

const Dashboard = ({ children, routes }) => {
  return (
    <div className="root">
      <div className="app-container">
        <Header routes={routes} />
        <div className="app-content">
          <div className="body">
            <div className="app-wrapper">
              <section className="page-container">{children}</section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.array.isRequired,
};

export default Dashboard;
