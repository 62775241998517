/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';

import md5 from 'md5';
import { useHistory } from 'react-router-dom';

import expired from '../../assets/pngs/expired.png';
import { Loader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { LoginForm } from '../../forms';
import { loginService } from '../../services';
import { setCookie, deleteCookie } from '../../utils/tools';

import './IntroPage.css';

const HOME_ROUTE = '/tickets';
const NOT_FOUND = '/notFound';

const IntroPage = ({ location }) => {
  const hasher = location?.hash.split('/');
  const history = useHistory();
  const [magicFlag, setMagicFlag] = useState(false);
  const [displayLogin, setDisplayLogin] = useState(false);
  const { updateContextAccountInfo, showSnackbar, magicLoading, setMagicLoading } = useContext(
    AccountContext
  );
  const {
    magic,
    setMagic,
    isExpired,
    setTeamTicket,
    fetchOverAllCases,
    overall,
    setSelectedTicket,
    selectedTicket,
  } = useContext(TicketsContext);

  const handleErrorLoginNotification = (err) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      switch (err.response.status) {
        case 400: // Bad request
          showSnackbar('error', 'Error!', 'Username or password incorrect. Please try again');
          break;
        case 401: // Unauthorized
          showSnackbar('error', 'Error!', 'Unauthorized');
          break;
        case 404: // Not found
          showSnackbar('error', 'Error!', 'Case not found, please refresh');
          break;
        case 408: // Timeout
          showSnackbar('warn', 'Attention!', 'The system is not available. Please try later');
          break;
        case 500: // Internal Server Error
          showSnackbar('warn', 'Attention!', 'The system is not available. Please try later');
          break;
        default:
          showSnackbar('error', 'Error!', 'Something went wrong. Please try again');
      }
    } else {
      showSnackbar('error', 'Error!', 'Something went wrong. Please try again');
    }
  };

  const handleShowProject = () => {
    showSnackbar(
      'info',
      'MyBasket Beta Edition',
      'Most businesses struggle to satisfy their customers with an easy interaction with their services, generating customers to leave,We’ll help them offer an efficient, streamlined, digital interaction, so nimble customers will love, so powerful their bottom line will boost',
      10000
    );
  };

  useEffect(() => {
    if (overall?.length && magic) {
      const result = overall?.filter((element) => {
        return element.id === magic;
      });
      if (result[0]) {
        setSelectedTicket(result[0]);
      } else {
        history.push(NOT_FOUND);
      }
    }
  }, [overall, magic]);

  useEffect(() => {
    if (selectedTicket) {
      history?.push(`${HOME_ROUTE}/${magic}`);
      setMagicFlag(false);
    }
  }, [selectedTicket]);

  const handleMagicValidation = async (token) => {
    deleteCookie('credentials');
    if (token) {
      setMagicLoading(true);
      const { success, data } = await loginService?.createToken({ token });
      if (data?.data?.id) {
        const { case: caseData, target, team: teamId } = JSON.parse(data?.data?.id);
        setMagic(caseData);
        setTeamTicket(teamId);
        if (success) {
          const { human: dataUser, access_token, expires_in } = data;
          // TODO ask if we want to preserve the data or removed once closed the window (local cookies / session cookies)
          setCookie(
            'credentials',
            JSON.stringify({
              identityUser: target,
              userId: dataUser.id,
              accessToken: access_token,
              expiresIn: expires_in,
            })
          );
          await fetchOverAllCases();
          !teamId.toLowerCase().includes('mybasket') || !teamId.toLowerCase().includes('my-basket')
            ? history?.push(HOME_ROUTE)
            : null;
        }
      } else {
        setTimeout(() => {
          setMagicLoading(false);
          history.push(NOT_FOUND);
        }, 1500);
      }
    }
    setMagicLoading(false);
  };

  const onSubmit = async (event, { username, password }) => {
    event.preventDefault();
    setMagicLoading(true);
    const encryt = md5(`${username}/${password}/hound`);
    const { success, data } = await loginService?.createToken({ username, password: encryt });
    if (success) {
      const { user: dataUser, access_token, expires_in } = data;
      // TODO ask if we want to preserve the data or removed once closed the window (local cookies / session cookies)
      setCookie(
        'credentials',
        JSON.stringify({
          userId: dataUser.id,
          accessToken: access_token,
          expiresIn: expires_in,
        })
      );
      updateContextAccountInfo();
      setMagicLoading(false);
      history?.push(HOME_ROUTE);
    } else {
      handleErrorLoginNotification(data);
      setMagicLoading(false);
    }
  };

  useEffect(() => {
    if (!location.goBack) {
      setMagicFlag(true);
      // console.log('hasher', hasher[0]?.split('#'));
      // TODO cambiar todos los urls de los template para que solo manden el MAGICLINK
      let link = hasher[0]?.split('#')[2];
      if (!link) {
        link = hasher[0]?.split('#')[1];
      }
      if (link) {
        console.log('link', link);
        handleMagicValidation(link);
        window.location.hash = '';
      } else {
        setMagicFlag(false);
        history.push('/unauthorized');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExpiredTicket = () => {
    return (
      <>
        <img className="expired-img" src={expired} alt="Ticket Expired" />
        <h1 className="expired-header">
          Lo siento, parece que el formulario
          <strong> ha expirado o ha sido enviado previamente.</strong>
        </h1>
      </>
    );
  };

  return !magicLoading && !magicFlag ? (
    <>
      {displayLogin ? (
        <div className="login-container">
          {isExpired ? (
            <div className="login-wrapper">
              <div className="wrapper">
                <LoginForm onSubmit={onSubmit} loading={magicLoading} />
                {/* <div className="version-btn">
              <Button onClick={handleShowProject}>v2.0.0</Button>
            </div> */}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <div className="loader-wrapper">
      <Loader fit />
    </div>
  );
};

export default IntroPage;
