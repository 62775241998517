/* eslint-disable no-unused-expressions */
import React, { createContext, useState, useEffect, useRef } from 'react';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';

import { usersService, workSpaceService } from '../services';
import { getCookie, deleteCookie } from '../utils/tools';

export const AccountContext = createContext({});

const DEFAULT_FLOW = {
  id: '',
  description: '',
  name: '',
  creator: '',
  type: '',
};

const ColorTemplatesHex = ['#0098ff', '#5c5c5c', '#725ca4', '#8ac7bb', '#5ace39', '#b1d3d4'];

export const AccountContextProvider = ({ children }) => {
  const [accountInfo, setAccountInfo] = useState({});
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [showSystemEmptyStates] = useState(false);
  const [isAccountInfoLoading, setIsAccountInfoLoading] = useState(true);
  const [selectedApp, setSelectedApp] = useState({});
  const [selectedFlow, setSelectedFlow] = useState(DEFAULT_FLOW);
  const [apps, setApps] = useState([]);
  const [modeles, setModeles] = useState([]);
  const [forms, setForms] = useState([]);
  const toast = useRef(null);
  const manageModal = useRef(null);
  const [tools, setTools] = useState({ toast, manageModal, showModal: false });
  const [magicLoading, setMagicLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(accountInfo).length) {
      setTimeout(() => {
        setIsAccountInfoLoading(false);
      }, 1700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  const showSnackbar = (severity, summary, detail, delay = 4000) => {
    return tools?.toast?.current?.show({
      severity,
      summary,
      detail,
      life: delay,
    });
  };

  const logOut = async () => {
    setMagicLoading(false);
    setAccountInfo({});
    deleteCookie('credentials');
  };

  const handleCloseModal = () => {
    setTools({
      ...tools,
      showModal: false,
      selectedEditUser: null,
    });
  };

  const handleExit = (systemError = false) => {
    logOut();
    if (systemError) {
      setTimeout(() => {
        // showSnackbar('error', 'System unavailable!', 'Please try later');
      }, 2000);
    }
  };

  const handleResetFlow = () => {
    setSelectedFlow(DEFAULT_FLOW);
  };

  const updateContextAccountInfo = async (isMagic = false) => {
    const { userId, identityUser } = JSON.parse(getCookie('credentials'));
    if (isMagic) {
      setAccountInfo({
        userData: { email: identityUser, name: identityUser },
        workspaceData: '',
      });
      return;
    }
    handleCloseModal();
    setIsAccountInfoLoading(true);
    const { success: userFlag, data: userResponse } = await usersService.getUser(userId);
    if (userFlag) {
      const { workspace, enabledModules } = userResponse;
      const {
        success: workspaceFlag,
        data: workspaceResponse,
      } = await workSpaceService.getWorkspace(workspace?.id);
      if (workspaceFlag) {
        // WARNING - Testing purpose for removing modules by popping
        // enabledModules.pop();
        setAccountInfo({
          userData: { ...userResponse, enabledModules },
          workspaceData: workspaceResponse,
        });
      } else {
        // No workspace retrived
        handleExit(true);
      }
    } else {
      // No user retrived
      handleExit(true);
    }
  };

  useEffect(() => {
    if (getCookie('credentials')) {
      updateContextAccountInfo();
    } else {
      handleExit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTask = () => {
    // showSnackbar('info', 'TO-DO!', 'Dev-team working on this page');
    // console.log('Dev-team working on this page');
  };

  return (
    <AccountContext.Provider
      value={{
        magicLoading,
        setMagicLoading,
        accountInfo,
        setAccountInfo,
        logOut,
        updateContextAccountInfo,
        isAccountInfoLoading,
        tools,
        setTools,
        showSnackbar,
        menuCollapse,
        setMenuCollapse,
        selectedApp,
        setSelectedApp,
        apps,
        setApps,
        modeles,
        setModeles,
        selectedFlow,
        setSelectedFlow,
        handleResetFlow,
        showSystemEmptyStates,
        ColorTemplatesHex,
        handleTask,
        handleCloseModal,
        forms,
        setForms,
      }}
    >
      <Dialog
        header={tools.manageModal.header}
        visible={tools.showModal}
        className="modal"
        footer={tools.manageModal.footer}
        onHide={() => setTools({ ...tools, showModal: false })}
      >
        {tools.manageModal.content}
      </Dialog>
      <Toast ref={toast} className="toast" />
      {children}
    </AccountContext.Provider>
  );
};

AccountContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
