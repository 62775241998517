/* eslint-disable no-unused-vars */
import AppsIcom from '../assets/nav-icons/Apps';
import CollectionsIcon from '../assets/nav-icons/Coins';
import DashboardIcon from '../assets/nav-icons/Dashboard';
import FormOne from '../assets/nav-icons/FormOne';
import ModelerIcon from '../assets/nav-icons/GitMerge';
import AdminIcon from '../assets/nav-icons/Settings';
import Tickets from '../assets/nav-icons/Ticket';
import {
  EditAppPage,
  IntroPage,
  MyAppsPage,
  ModelerList,
  ModelerIframe,
  MainDashboard,
  AppsHistory,
  AdminInfo,
  CollectionsModule,
  HumanInterface,
  FormsList,
  FormsIframe,
  ExternalTicketsPage,
  TicketResolution,
  NotFound,
  RefreshedTickets,
  FormsModule,
} from '../containers';

// Auth components
const authRoutes = {
  id: 'Auth',
  path: '/',
  // eslint-disable-next-line react/react-in-jsx-scope
  children: [
    {
      path: '/',
      name: 'Intro',
      component: IntroPage,
    },
  ],
  component: null,
};

// Auth components
const NoMagicFoundRoutes = {
  id: 'notFound',
  path: '/notFound',
  // eslint-disable-next-line react/react-in-jsx-scope
  children: [
    {
      path: '/notFound',
      name: 'NotFound',
      component: NotFound,
    },
  ],
  component: null,
};

// Auth components
const FormsRoutes = {
  id: 'forms',
  path: '/:ws/forms/:formId',
  // eslint-disable-next-line react/react-in-jsx-scope
  children: [
    {
      path: '/:ws/forms/:formId',
      name: 'Forms',
      component: FormsModule,
    },
  ],
  component: null,
};

// Auth components
const RefreshedRoutes = {
  id: 'refreshed',
  path: '/unauthorized',
  // eslint-disable-next-line react/react-in-jsx-scope
  children: [
    {
      path: '/unauthorized',
      name: 'Refreshed',
      component: RefreshedTickets,
    },
  ],
  component: null,
};

const authExternalTicketsRoutes = {
  id: 'ExternalTicketModule',
  path: '/tickets',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <DashboardIcon size={30} color="white" />,
  children: [
    {
      path: '/tickets',
      name: 'External Tickets',
      component: ExternalTicketsPage,
    },
    {
      path: '/tickets/:id',
      name: 'Tickets',
      component: TicketResolution,
    },
  ],
  component: null,
};

const ticketsExternalCenter = {
  id: 'external',
  path: '/external',
  name: 'External Tickets',
  module: 'EXTERNAL INTERFACE',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <Tickets size={30} color="white" />,
  possition: 'body',
  children: [
    {
      name: 'External Tickets',
      path: '/external',
      component: HumanInterface,
    },
  ],
  component: null,
};

const ticketsCenter = {
  id: 'tickets',
  path: '/tickets#my-tickets',
  name: 'Tickets',
  module: 'HUMAN INTERFACE',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <Tickets size={30} color="white" />,
  possition: 'body',
  children: [
    {
      name: 'Tickets',
      path: '/tickets',
      component: HumanInterface,
    },
  ],
  component: null,
};

const dashboardRoutes = {
  id: 'dashboard',
  path: '/dashboard',
  name: 'Dashboard',
  possition: 'body',
  module: 'DASHBOARD',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <DashboardIcon size={30} color="white" />,
  children: [
    {
      name: 'dashboard',
      path: '/dashboard',
      component: MainDashboard,
    },
  ],
};

const adminInfoRoute = {
  id: 'admin-info',
  path: '/admin-info#users',
  possition: 'footer',
  name: 'Admin',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <AdminIcon size={30} color="white" />,
  children: [
    {
      name: 'Admin',
      path: '/admin-info',
      component: AdminInfo,
    },
  ],
};

const collectionRoute = {
  id: 'collections',
  path: '/collections',
  name: 'Collection',
  possition: 'body',
  module: 'COLLECTIONS',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <CollectionsIcon size={30} color="white" />,
  children: [
    {
      name: 'Collection Module',
      path: '/collections',
      component: CollectionsModule,
    },
  ],
};

const appDashboardRoutes = {
  id: 'app-dashboard',
  path: '/apps',
  name: 'Apps',
  possition: 'body',
  module: 'APPS',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <AppsIcom size={30} color="white" />,
  children: [
    {
      name: 'Edit App',
      path: '/apps/edit',
      component: EditAppPage,
    },
    {
      name: 'App history',
      path: '/apps/history',
      component: AppsHistory,
    },
    {
      name: 'App List',
      path: '/apps',
      component: MyAppsPage,
    },
  ],
};

const modelerRoutes = {
  id: 'modeler',
  path: '/flows',
  name: 'Flows',
  possition: 'body',
  module: 'MODELER',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <ModelerIcon size={30} color="white" />,
  children: [
    {
      name: 'Flow List',
      path: '/flows',
      component: ModelerList,
    },
    {
      name: 'Flow',
      path: '/flows/flow',
      component: ModelerIframe,
    },
  ],
};

const formsRoutes = {
  id: 'forms',
  path: '/forms',
  name: 'Forms',
  possition: 'body',
  module: 'FORMS',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <FormOne size={30} color="white" />,
  children: [
    {
      name: 'Forms List',
      path: '/forms',
      component: FormsList,
    },
    {
      name: 'Forms',
      path: '/forms/flow',
      component: FormsIframe,
    },
  ],
};

// Routes visible in the sidebar
export const sidebarRoutes = [
  {
    path: '/dashboard',
    possition: 'body',
    module: 'DASHBOARD',
  },
  {
    path: '/apps',
    possition: 'body',
    module: 'APPS',
  },
  {
    path: '/tickets#my-cases',
    possition: 'body',
    module: 'HUMAN INTERFACE',
  },
  {
    path: '/tickets#my-cases',
    possition: 'body',
    module: 'HUMAN INTERFACE',
  },
  {
    path: '/flows',
    possition: 'body',
    module: 'MODELER',
  },
  {
    path: '/forms',
    possition: 'body',
    module: 'FORMS',
  },
  {
    path: '/collections',
    possition: 'body',
    module: 'COLLECTIONS',
  },
];

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardRoutes,
  appDashboardRoutes,
  ticketsCenter,
  modelerRoutes,
  NoMagicFoundRoutes,
  RefreshedRoutes,
  FormsRoutes,
  collectionRoute,
  adminInfoRoute,
];

export const dashboardExternalLayoutRoutes = [ticketsExternalCenter];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes, NoMagicFoundRoutes, RefreshedRoutes, FormsRoutes];

// Routes using the Auth layout
export const authExternalLayoutRoutes = [authExternalTicketsRoutes];
